import { yupResolver } from '@hookform/resolvers/yup';
import {
  activate_gl_ba_album_pair,
  deactivate_gl_ba_album_pair,
  delete_image_gl_ba_album_pair,
  get_gl_ba_album_pair,
  update_gl_ba_album_pair,
  upload_image_gl_ba_album_pair,
} from 'apiServices';
import { useForm } from 'react-hook-form';
import { useAppStore } from 'store';
import { shallow } from 'zustand/shallow';

import { ToastType, notice } from 'components/ToastNotification';
import { Create_ba_album_pair_form_schema, create_ba_album_pair_validation_schema } from 'pages/BeforeAfter/components';
import { formErrorHandler } from 'utils/errorHanders';

import { useAlbumPairState } from '../useAlbumPair.state';

export const useGLBeforeAfterAlbumPhotosProfileState = () => {
  const { ba_gl_image_pair, set_ba_gl_image_pair } = useAppStore(
    store => ({
      set_ba_gl_image_pair: store.set_ba_gl_image_pair,
      ba_gl_image_pair: store.ba_gl_image_pair,
    }),
    shallow
  );

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isDirty, isSubmitting, isValid },
  } = useForm<Omit<Create_ba_album_pair_form_schema, 'practiceId' | 'albumId'>>({
    resolver: yupResolver(create_ba_album_pair_validation_schema.omit(['practiceId', 'albumId'])),
    values: {
      name: ba_gl_image_pair?.name,
      description: ba_gl_image_pair?.description || '',
    },
  });

  const isSubmitButtonDisabled = isSubmitting || !isDirty || !isValid;

  const onFormSubmitHandler = handleSubmit(async formData => {
    try {
      const { data } = await update_gl_ba_album_pair(ba_gl_image_pair?.id, formData);
      set_ba_gl_image_pair(data);

      notice(ToastType.SUCCESS, 'Album pair has been successfully edited!');
    } catch (error) {
      formErrorHandler({
        error,
        config: { formError: { setError } },
        customErrorMessage: 'Failed to edit album pair, please try again!',
      });
    }
  });

  const albumPairState = useAlbumPairState({
    activateAlbumPairApi: activate_gl_ba_album_pair,
    deactivateAlbumPairApi: deactivate_gl_ba_album_pair,
    fetchAlbumPairApi: get_gl_ba_album_pair,
    setAlbumPair: set_ba_gl_image_pair,
    uploadImageApi: upload_image_gl_ba_album_pair,
    deleteImageApi: delete_image_gl_ba_album_pair,
    beforeImageURL: ba_gl_image_pair?.imageLeftThumbUrl,
    afterImageURL: ba_gl_image_pair?.imageRightThumbUrl,
  });

  return {
    onFormSubmitHandler,
    isSubmitButtonDisabled,
    isDirty,
    register,
    ba_gl_image_pair,
    isSubmitting,
    errors,
    ...albumPairState,
  };
};
