import { useMemo } from 'react';

import { Skeleton } from '@mui/material';
import { AppStore, useAppStore } from 'store';
import { BreadcrumbComponentProps } from 'use-react-router-breadcrumbs';

import { RouterState } from 'hooks/useRouter';

type BreadcrumbData = { data?: string | null };

const BreadcrumbItem = ({ data }: BreadcrumbData) => {
  return data ? <span>{data}</span> : <Skeleton component="span" variant="text" width={100} height={20} />;
};

const createMapStateToProps = <TEntityKey extends keyof AppStore>(
  entityKey: TEntityKey,
  idPath: (entity: AppStore[TEntityKey]) => unknown,
  nameCallback: (entity: AppStore[TEntityKey]) => string | null,
  paramsId: string = 'id'
) => {
  return (state: AppStore, props: BreadcrumbComponentProps): BreadcrumbData =>
    mapEntityStateToProps(state, props, entityKey, idPath, nameCallback, paramsId);
};

const connect =
  <TProps,>(mapStateToPropsFC: (state: AppStore, props: BreadcrumbComponentProps) => TProps) =>
  (Component: React.FC<TProps & BreadcrumbComponentProps>) => {
    return (props: BreadcrumbComponentProps): JSX.Element => {
      const store = useAppStore();
      const stateProps = useMemo(() => mapStateToPropsFC(store, props), [store, props]);

      return useMemo(() => <Component {...props} {...stateProps} />, [props, stateProps]);
    };
  };

export const getBreadCrumbName = ({ location: { state } }: { location: { state: RouterState } }) => {
  return <span>{state?.breadCrumbName}</span>;
};

const mapEntityStateToProps = <TEntityKey extends keyof AppStore>(
  state: AppStore,
  props: BreadcrumbComponentProps,
  entityKey: TEntityKey,
  idPath: (entity: AppStore[TEntityKey]) => unknown,
  nameCallback: (entity: AppStore[TEntityKey]) => string | null,
  paramsId: string = 'id'
): BreadcrumbData => {
  const entity = state?.[entityKey];

  const entityId = idPath(entity);

  const isIdMatch = entityId === props?.match?.params?.[paramsId];

  return {
    data: isIdMatch && entity ? nameCallback(entity) : null,
  };
};

const createBreadcrumb = (mapStateToPropsFC: ReturnType<typeof createMapStateToProps>) =>
  connect(mapStateToPropsFC)(BreadcrumbItem);

const mapPatientStateToProps = createMapStateToProps(
  'patient',
  patient => patient?.id,
  patient => `${patient?.person?.firstName} ${patient?.person?.middleName || ''} ${patient?.person?.lastName}`
);

const mapPracticeStateToProps = createMapStateToProps(
  'practice',
  practice => practice?.id,
  practice => practice?.name
);

const mapTagCategoryStateToProps = createMapStateToProps(
  'breadcrumbData',
  breadcrumbData => breadcrumbData?.categoryId,
  breadcrumbData => breadcrumbData?.name
);

const mapUserStateToProps = createMapStateToProps(
  'expert',
  expert => expert?.id,
  expert => `${expert?.person?.firstName} ${expert?.person?.middleName || ''} ${expert?.person?.lastName}`
);

const mapGLServiceStateToProps = createMapStateToProps(
  'GLService',
  data => data?.id,
  glService => glService?.title
);

const mapPLServiceStateToProps = createMapStateToProps(
  'PLService',
  data => data?.id,
  glService => glService?.title
);

const mapGLProductStateToProps = createMapStateToProps(
  'GLProduct',
  data => data?.id,
  glService => glService?.title
);

const mapPLProductStateToProps = createMapStateToProps(
  'PLProduct',
  data => data?.id,
  glService => glService?.title
);

const mapGLalbumStateToProps = createMapStateToProps(
  'b_a_gl_album',
  data => data?.id,
  data => data?.name,
  'albumId'
);

const mapPLalbumStateToProps = createMapStateToProps(
  'b_a_pl_album',
  data => data?.id,
  data => data?.name,
  'albumId'
);

const mapGLimagePairStateToProps = createMapStateToProps(
  'ba_gl_image_pair',
  data => data?.id,
  data => data?.name
);

const mapPLimagePairStateToProps = createMapStateToProps(
  'ba_pl_image_pair',
  data => data?.id,
  data => data?.name
);

const mapGLimagePairParentRouteStateToProps = createMapStateToProps(
  'ba_gl_image_pair',
  data => data?.album?.id,
  data => data?.album?.name,
  'albumId'
);

const mapPLimagePairParentRouteStateToProps = createMapStateToProps(
  'ba_pl_image_pair',
  data => data?.album?.id,
  data => data?.album?.name,
  'albumId'
);

export const DYNAMIC_BREADCRUMBS = {
  patient: createBreadcrumb(mapPatientStateToProps),
  practice: createBreadcrumb(mapPracticeStateToProps),
  editPracticeTag: createBreadcrumb(mapTagCategoryStateToProps),
  userProfile: createBreadcrumb(mapUserStateToProps),
  glServiceProfile: createBreadcrumb(mapGLServiceStateToProps),
  plServiceProfile: createBreadcrumb(mapPLServiceStateToProps),
  glProductProfile: createBreadcrumb(mapGLProductStateToProps),
  plProductProfile: createBreadcrumb(mapPLProductStateToProps),
  glAlbumProfile: createBreadcrumb(mapGLalbumStateToProps),
  plAlbumProfile: createBreadcrumb(mapPLalbumStateToProps),
  glAlbumPhotosProfileParentRoute: createBreadcrumb(mapGLimagePairParentRouteStateToProps),
  plAlbumPhotosProfileParentRoute: createBreadcrumb(mapPLimagePairParentRouteStateToProps),
  glAlbumPhotosProfile: createBreadcrumb(mapGLimagePairStateToProps),
  plAlbumPhotosProfile: createBreadcrumb(mapPLimagePairStateToProps),
};
