import { FC } from 'react';

import { Box, Card, Typography } from '@mui/material';

import colors from 'assets/theme/base/colors';
import { Badge } from 'components/Badge/Badge';
import { ClipboardLabel } from 'components/ClipboardLabel/ClipboardLabel';
import { Note } from 'components/Widgets/Note';
import type { NoteProps } from 'components/Widgets/Note/types';

import { ActionMenu } from './';

interface Props {
  icon?: string;
  title: string;
  hardness?: number;
  id?: string;
  notes?: Pick<NoteProps, 'note' | 'noteAvatar' | 'noteDate' | 'noteAuthor'>[];
  mt?: number;
  context?: Parameters<typeof ActionMenu>[0];
  texts?: Record<string, string>;
}

export const Diagnosis: FC<Props> = props => {
  return (
    <Card sx={{ padding: '10px', gap: '10px', boxShadow: 'none', mt: 1 }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: 2,
          '&:hover .patient-diagnosis-action-menu': {
            opacity: 1,
            pointerEvents: 'auto',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            flex: 1,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: 2,
            }}
          >
            {props.icon && <img src={props.icon} alt={props.icon} style={{ maxHeight: '20px' }} />}
            <Typography sx={{ fontSize: '14px' }}>{props.title}</Typography>
            {typeof props.hardness === 'number' ? (
              <Badge type={props.hardness === 1 ? 'warning' : props.hardness === 2 ? 'error' : 'success'}>
                {props.hardness === 1 ? 'Moderate' : props.hardness === 2 ? 'Severe' : 'Mild'}
              </Badge>
            ) : null}
            {props.id && <ClipboardLabel label={props.id} />}
          </Box>
          {props.context && (
            <ActionMenu
              {...props.context}
              className="patient-diagnosis-action-menu"
              sx={{ opacity: 0, pointerEvents: 'none', transition: '.25s opacity' }}
            />
          )}
        </Box>
      </Box>
      <Box display="flex" flexDirection="row">
        {props.texts && (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
            {Object.entries(props.texts).map(([key, value]) => (
              <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center' }} key={key}>
                <Typography variant="caption" fontSize={14} color={colors.grey[600]}>
                  {key}:
                </Typography>
                <Typography variant="caption" fontSize={14}>
                  {value}
                </Typography>
              </Box>
            ))}
          </Box>
        )}
      </Box>
      {props.notes?.map(note => (
        <Note {...note} sx={{ maxWidth: 'auto' }} key={note.noteDate} />
      ))}
    </Card>
  );
};
