import { AvatarClasses, AvatarProps, Theme } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';

const avatar: {
  defaultProps?: Partial<AvatarProps>;
  styleOverrides: Partial<OverridesStyleRules<keyof AvatarClasses, 'MuiAvatar', Omit<Theme, 'components'>>>;
} = {
  styleOverrides: {
    root: {
      transition: 'all 200ms ease-in-out',
    },

    rounded: {
      borderRadius: '50%',
    },

    img: {
      height: 'auto',
    },
  },
};

export default avatar;
