import { useCallback, useEffect, useRef, useState } from 'react';

import {
  GLbeforeAfterAlbumSearchParams,
  activateGLBAalbum,
  deactivateGLBAalbum,
  deleteGLBAalbum,
  getGLBAalbums,
} from 'apiServices';
import { useAppStore } from 'store';

import { ToastType, notice } from 'components/ToastNotification';
import { ITEMS_PER_PAGE } from 'constants/layout-config';
import {
  useActionDialogManagement,
  useBoolean,
  useDebounce,
  useInfinityScroll,
  usePopover,
  useRouter,
  useUserProfile,
} from 'hooks';
import { getBACardMenuItems } from 'pages/BeforeAfter';
import { backendErrorHandler } from 'utils/errorHanders';

export type FiltersInitialState = BaseFilterState;

export const FiltersInitialStateValue: FiltersInitialState = {
  isShowDeactivated: false,
};

export const useGLBeforeAfterListState = () => {
  const { b_a_gl_data, set_b_a_gl_albums, set_more_b_a_gl_albums } = useAppStore(state => ({
    b_a_gl_data: state.b_a_gl_data,
    set_b_a_gl_albums: state.set_b_a_gl_albums,
    set_more_b_a_gl_albums: state.set_more_b_a_gl_albums,
  }));

  const { isRoleAdmin, isRolePracticeAdmin } = useUserProfile();

  const [isLoading, setIsLoading] = useState(true);

  const [searchValue, setSearchValue] = useState('');

  const isResettingPage = useRef(false);

  const resetPageHandler = () => {
    if (isResettingPage.current) {
      currentPage !== 1 && setCurrentPage(1);
      isResettingPage.current = false;
    }
  };

  const onChangeSearchInputValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);

    isResettingPage.current = true;
  };

  const {
    handleClosePopover: closeFiltersMenu,
    handleOpenPopover: openFilterMenu,
    openPopover: isFiltersMenuOpen,
  } = usePopover();

  const [appliedFilters, setAppliedFilters] = useState<FiltersInitialState>(FiltersInitialStateValue);

  const onApplyFilters = (newFilters: FiltersInitialState) => {
    setAppliedFilters(newFilters);
    isResettingPage.current = true;
    closeFiltersMenu();
  };

  const debouncedSearchValue = useDebounce(searchValue, 200);

  const fetchAlbumsHandler = async (params?: GLbeforeAfterAlbumSearchParams) => {
    try {
      const { data } = await getGLBAalbums({
        ...searchParams,
        ...params,
      });

      set_b_a_gl_albums({
        items: data.items,
        page: data.page,
        pages: data.pages,
        size: data.size,
        total: data.total,
      });
    } catch (error) {
      backendErrorHandler({
        error,
        config: { customErrorMessage: 'Failed to get before & after albums, please try again!' },
      });
    }
  };

  const onGetBeforeAfterAlbumsHandler = async (params?: GLbeforeAfterAlbumSearchParams) => {
    !isLoading && setIsLoading(true);

    await fetchAlbumsHandler();

    setIsLoading(false);
  };

  const [isOpenAddAlbumDialogWindow, openAddAlbumDialogWindow, closeAddAlbumDialogWindow] = useBoolean();

  const [currentPage, setCurrentPage] = useState(1);

  const [isFetchMoreLoading, setIsFetchMoreLoading] = useState(false);

  const searchParams: GLbeforeAfterAlbumSearchParams = {
    ...(debouncedSearchValue && { search: debouncedSearchValue }),
    size: ITEMS_PER_PAGE,
    page: currentPage,
    is_active: appliedFilters.isShowDeactivated ? false : true,
  };

  const onFetchMoreBeforeAfterAlbumsHandler = async (params?: GLbeforeAfterAlbumSearchParams) => {
    setIsFetchMoreLoading(true);

    try {
      const { data } = await getGLBAalbums({
        ...searchParams,
        ...params,
      });

      set_more_b_a_gl_albums({
        items: data.items,
        page: data.page,
        pages: data.pages,
        size: data.size,
        total: data.total,
      });
    } catch (error) {
      backendErrorHandler({
        error,
        config: { customErrorMessage: 'Failed to fetch before & after albums, please try again!' },
      });
    } finally {
      setIsFetchMoreLoading(false);
    }
  };

  const { ref: lastAlbumCardRef } = useInfinityScroll({
    totalPages: b_a_gl_data.pages,
    fetchItemsHandler: onFetchMoreBeforeAfterAlbumsHandler,
  });

  useEffect(() => {
    onGetBeforeAfterAlbumsHandler({ ...(appliedFilters && { page: 1 }) });
    resetPageHandler();
  }, [debouncedSearchValue, appliedFilters]);

  const {
    actionState,
    isActionPending,
    setIsActionPending,
    closeActivateDialog,
    closeDeactivateDialog,
    isActivateDialogOpen,
    isDeactivateDialogOpen,
    onClickActionOptionHandler,
    isOpenDeleteDialogWindow,
    closeDeleteDialogWindow,
  } = useActionDialogManagement();

  const onDeactivateAlbumHandler = async (event: React.MouseEvent<HTMLElement>) => {
    setIsActionPending(true);
    const { id } = actionState;
    try {
      await deactivateGLBAalbum(id);

      notice(ToastType.SUCCESS, 'Album has been deactivated successfully');
      closeDeactivateDialog();

      await onGetBeforeAfterAlbumsHandler({ page: 1 });
    } catch (error) {
      backendErrorHandler({
        error,
        config: {
          customErrorMessage: 'Failed to deactivate album, please try again!',
        },
      });
    } finally {
      setIsActionPending(false);
    }
  };

  const onActivateAlbumHandler = async (event: React.MouseEvent<HTMLElement>) => {
    setIsActionPending(true);
    const { id } = actionState;
    try {
      await activateGLBAalbum(id);
      notice(ToastType.SUCCESS, 'Album has been activated successfully');
      closeActivateDialog();

      await onGetBeforeAfterAlbumsHandler({ page: 1 });
    } catch (error) {
      backendErrorHandler({
        error,
        config: {
          customErrorMessage: 'Failed to activate album, please try again!',
        },
      });
    } finally {
      setIsActionPending(false);
    }
  };

  const onDeleteAlbumHandler = async (event: React.MouseEvent<HTMLElement>) => {
    setIsActionPending(true);
    const { id } = actionState;
    try {
      await deleteGLBAalbum(id);

      notice(ToastType.SUCCESS, 'Album has been deleted successfully');

      closeDeleteDialogWindow();

      onGetBeforeAfterAlbumsHandler({ page: 1 });
    } catch (error) {
      backendErrorHandler({
        error,
        config: {
          customErrorMessage: 'Failed to delete album, please try again!',
        },
      });
    } finally {
      setIsActionPending(false);
    }
  };

  const [isOpenAddAlbumImagesDialogWindow, openAddAlbumImagesDialogWindow, closeAddAlbumImagesDialogWindow] =
    useBoolean();

  const [albumId, setAlbumId] = useState(null);

  const getMenuItems = useCallback(
    ({ isActive, id, name }: { isActive: boolean; id: string; name: string }) =>
      getBACardMenuItems({
        isActive,
        id,
        name,
        handlers: {
          ...(isRoleAdmin && {
            add: () => {
              setAlbumId(id);
              openAddAlbumImagesDialogWindow();
            },
            activateToggle: onClickActionOptionHandler,
            delete: onClickActionOptionHandler,
          }),
          ...(isRolePracticeAdmin && {
            clone: () => {},
          }),
        },
      }),
    [onClickActionOptionHandler, isRoleAdmin, isRolePracticeAdmin]
  );

  const { navigate } = useRouter();

  const onCardClickHandler = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => navigate(`/before-after/gl/${e.currentTarget.id}`),
    []
  );

  return {
    b_a_gl_data,
    set_b_a_gl_albums,
    isOpenAddAlbumDialogWindow,
    openAddAlbumDialogWindow,
    closeAddAlbumDialogWindow,
    isLoading,
    onGetBeforeAfterAlbumsHandler,
    isFetchMoreLoading,
    lastAlbumCardRef,
    onChangeSearchInputValue,
    searchValue,
    closeFiltersMenu,
    openFilterMenu,
    isFiltersMenuOpen,
    appliedFilters,
    onApplyFilters,
    onDeactivateAlbumHandler,
    onActivateAlbumHandler,
    isActivateDialogOpen,
    closeActivateDialog,
    isDeactivateDialogOpen,
    closeDeactivateDialog,
    actionState,
    isActionPending,
    isRoleAdmin,
    getMenuItems,
    onCardClickHandler,
    isOpenAddAlbumImagesDialogWindow,
    closeAddAlbumImagesDialogWindow,
    albumId,
    fetchAlbumsHandler,
    isOpenDeleteDialogWindow,
    closeDeleteDialogWindow,
    onDeleteAlbumHandler,
  };
};
