import { Box, Stack, SxProps, Theme, Typography } from '@mui/material';

import { Icon, TIconType } from 'components/Icon';

import { sideNavMenuItemStyles } from './BaseSideNavMenu.styles';
import { CardWrapper } from './CardWrapper';

export type SideNavFormMenuItem = {
  label: string;
  icon?: TIconType;
  id: string;
  entry: IntersectionObserverEntry;
};

type BaseSideMenuProps = {
  sidenavItems: SideNavFormMenuItem[];
  sx?: SxProps<Theme>;
  activeSectionId: string;
  onClick: (refEntry: IntersectionObserverEntry | null) => void;
};

export const SideNavFormMenu = ({ sidenavItems, sx, activeSectionId, onClick }: BaseSideMenuProps) => {
  const renderSidenavItems = sidenavItems.map(({ id, entry, icon, label }, key) => {
    const onClickHandler = () => onClick(entry);

    return (
      <Box key={id} component="li" pt={key === 0 ? 0 : 1} onClick={onClickHandler}>
        <Stack
          direction="row"
          spacing={{ xs: 1.5, sm: 1 }}
          sx={theme => ({
            ':hover': { cursor: 'pointer' },
            ...sideNavMenuItemStyles({ theme, isActive: id === activeSectionId }),
          })}
        >
          {icon && <Icon type={icon} sx={{ svg: { width: 16, height: 16 } }} />}
          <Typography variant="button" fontWeight="regular" textTransform="capitalize" noWrap>
            {label}
          </Typography>
        </Stack>
      </Box>
    );
  });

  return <CardWrapper sx={{ border: 0, boxShadow: '0', ...sx }}>{renderSidenavItems}</CardWrapper>;
};
