import { FC } from 'react';

import { Box, IconButton, Typography, alpha } from '@mui/material';

import { Icon } from '../Icon';
import { ToastType, notice } from '../ToastNotification';

interface Props {
  label: string;
}

export const ClipboardLabel: FC<Props> = props => {
  const { label } = props;

  const onClickCopy = async () => {
    try {
      await navigator.clipboard.writeText(label || '');
      notice(ToastType.SUCCESS, 'Successfully copied!');
    } catch (error) {
      notice(ToastType.ERROR, 'Failed to copy, please try again.');
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '6px',
        p: '2px',
        bgcolor: theme => alpha(theme.palette.primary.light, 0.1),
      }}
    >
      <Typography color="primary.light" sx={{ padding: '0 5px' }} fontSize={12} fontWeight={400}>
        {label}
      </Typography>
      <IconButton onClick={onClickCopy} sx={{ color: theme => theme.palette.primary.light }}>
        <Icon sx={{ svg: { width: 14, height: 14 } }} type="copy" />
      </IconButton>
    </Box>
  );
};
