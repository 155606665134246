import { useCallback, useEffect, useState } from 'react';

import { GetPracticesParams, activatePractice, deactivatePractice, getPractices } from 'apiServices';
import { useAppStore } from 'store';
import { shallow } from 'zustand/shallow';

import { ToastType, notice } from 'components/ToastNotification';
import { useBoolean } from 'hooks/useBoolean';
import { useDebounce } from 'hooks/useDebounce';
import { usePopover } from 'hooks/usePopover';
import { useTable } from 'hooks/useTable';
import { backendErrorHandler } from 'utils/errorHanders';

import { ActivationDataInitialState, FiltersInitialState } from './types';

export const FiltersInitialStateValue: FiltersInitialState = {
  city_id: null,
  country_iso_code: null,
  state_iso_code: null,
  isShowDeactivated: false,
};

const ActivationDataInitialStateValue: ActivationDataInitialState = {
  practiceId: '',
  practiceName: '',
  activationStatus: 'deactivate',
};

export const usePracticesState = () => {
  const {
    practices: { practices, total },
    setPractices,
  } = useAppStore(
    store => ({
      practices: store.practices,
      setPractices: store.setPractices,
    }),
    shallow
  );

  const { page, rowsPerPage, onChangeRowsPerPage, setPage, isResettingPage, resetPageHandler } = useTable();

  const [isAddPracticeDialogOpen, openAddPracticeDialog, closeAddPracticeDialog] = useBoolean();

  const [isLoading, setIsLoading] = useState(true);

  const onGetPracticesHandler = async ({
    params,
    isDefaultPage,
  }: {
    params?: GetPracticesParams;
    isDefaultPage?: boolean;
  }) => {
    !isLoading && setIsLoading(true);
    try {
      if (debouncedSearchValue || isDefaultPage) setPage(1);

      const {
        data: { items, page, pages, size, total },
      } = await getPractices({ ...searchParams, ...params });

      setPractices({ page, pages, practices: items, size, total });

      if (isDefaultPage && page !== 1) setPage(1);
    } catch (error) {
      console.error(error);
      backendErrorHandler({ error, config: { customErrorMessage: 'Failed to get practices, please try again!' } });
    } finally {
      setIsLoading(false);
    }
  };

  const [searchValue, setSearchValue] = useState('');

  const debouncedSearchValue = useDebounce(searchValue, 200);

  const onChangeSearchValueHandler = (event: React.ChangeEvent<HTMLInputElement>) => setSearchValue(event.target.value);

  const {
    handleClosePopover: closeFiltersMenu,
    handleOpenPopover: openFilterMenu,
    openPopover: isFiltersMenuOpen,
  } = usePopover();

  const onChangePageHandler = async (e: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    const newPage = page + 1;
    setPage(newPage);

    await onGetPracticesHandler({ params: { page: page + 1, size: rowsPerPage } });
  };

  const [appliedFilters, setAppliedFilters] = useState<FiltersInitialState>(FiltersInitialStateValue);

  const onApplyFilters = useCallback((newFilters: FiltersInitialState) => {
    setAppliedFilters(newFilters);
    isResettingPage.current = true;
    closeFiltersMenu();
  }, []);

  const searchParams: GetPracticesParams = {
    ...(debouncedSearchValue && {
      search: debouncedSearchValue,
    }),
    page,
    size: rowsPerPage,
    ...(appliedFilters.state_iso_code && {
      state_iso_code: appliedFilters.state_iso_code.isoCode,
    }),
    ...(appliedFilters.country_iso_code && {
      country_iso_code: appliedFilters.country_iso_code.isoCode,
    }),
    ...(appliedFilters.city_id && {
      city_id: appliedFilters.city_id.id,
    }),
    is_active: appliedFilters.isShowDeactivated ? false : true,
  };

  const [isActivatingProcess, setIsActivatingProcess] = useState(false);

  const [activationState, setActivationState] = useState(ActivationDataInitialStateValue);

  const [isActivateDialogOpen, openActivateDialog, closeActivateDialog] = useBoolean();
  const [isDeactivateDialogOpen, openDeactivateDialog, closeDeactivateDialog] = useBoolean();

  const onClickActivationOptionHandler = useCallback((activationStateData: ActivationDataInitialState) => {
    activationStateData.activationStatus === 'activate' ? openActivateDialog() : openDeactivateDialog();
    setActivationState(activationStateData);
  }, []);

  const onDeactivatePracticeHandler = async (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setIsActivatingProcess(true);
    const { practiceId } = activationState;
    try {
      await deactivatePractice(practiceId);

      notice(ToastType.SUCCESS, 'Practice has been successfully inactivated!');

      await onGetPracticesHandler({ isDefaultPage: true, params: { page: 1 } });

      closeDeactivateDialog();
    } catch (error) {
      console.error(error);
      backendErrorHandler({
        error,
        config: {
          customErrorMessage: 'Failed to inactivate practice, please try again!',
        },
      });
    } finally {
      setIsActivatingProcess(false);
    }
  };

  const onActivatePracticeHandler = async (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setIsActivatingProcess(true);
    const { practiceId } = activationState;
    try {
      await activatePractice(practiceId);

      notice(ToastType.SUCCESS, 'Practice has been successfully  activated!');

      await onGetPracticesHandler({ isDefaultPage: true, params: { page: 1 } });

      closeActivateDialog();
    } catch (error) {
      console.error(error);
      backendErrorHandler({
        error,
        config: {
          customErrorMessage: 'Failed to activate practice, please try again!',
        },
      });
    } finally {
      setIsActivatingProcess(false);
    }
  };

  useEffect(() => {
    onGetPracticesHandler({ params: { ...(isResettingPage.current && { page: 1 }) } });
    resetPageHandler();
  }, [rowsPerPage, debouncedSearchValue, appliedFilters]);

  return {
    onApplyFilters,
    practices,
    isLoading,
    searchValue,
    onChangeSearchValueHandler,
    closeFiltersMenu,
    openFilterMenu,
    isFiltersMenuOpen,
    page,
    rowsPerPage,
    onChangeRowsPerPage,
    total,
    onChangePageHandler,
    appliedFilters,
    setAppliedFilters,
    isActivatingProcess,
    onDeactivatePracticeHandler,
    onActivatePracticeHandler,
    onClickActivationOptionHandler,
    isActivateDialogOpen,
    closeDeactivateDialog,
    closeActivateDialog,
    isDeactivateDialogOpen,
    activationState,
    isAddPracticeDialogOpen,
    openAddPracticeDialog,
    closeAddPracticeDialog,
    onGetPracticesHandler,
  };
};
