import { AutocompleteProps, CSSObject, ComponentsOverrides, Theme } from '@mui/material';

import borders from 'assets/theme/base/borders';
import boxShadows from 'assets/theme/base/boxShadows';
import colors from 'assets/theme/base/colors';
import typography from 'assets/theme/base/typography';
import pxToRem from 'assets/theme/functions/pxToRem';

const { xs } = boxShadows;
const { size } = typography;
const { primary, text, white, transparent, light, dark, grey } = colors;
const { borderRadius } = borders;

const rootStyle = (ownerState: AutocompleteProps<any, any, any, any, 'div'> & Record<string, unknown>) => {
  const defaultStyles: CSSObject = {
    borderRadius: 8,
    '& fieldset': {
      borderWidth: 1,
    },

    '& .MuiInputBase-input': {
      padding: '0 !important',
    },
    ...(ownerState.size === 'medium' && {
      '& .MuiInputBase-root': {
        height: 40,
        fontSize: 14,
      },
    }),
    ...(ownerState.size === 'small' && {
      '& .MuiInputBase-root': {
        height: 36,
        paddingLeft: 8,
        fontSize: 12,
      },
      '& .MuiInputBase-sizeSmall': {
        paddingLeft: '8px !important',
        paddingTop: '8px !important',
        paddingBottom: '8px !important',
      },
    }),
    ...(ownerState.multiple && {
      '& .MuiInputBase-root': {
        height: 'auto !important',
      },
    }),
  };

  return [defaultStyles];
};

const autocomplete: { styleOverrides?: ComponentsOverrides<Theme>['MuiAutocomplete'] } = {
  styleOverrides: {
    root: ({ ownerState }) => rootStyle(ownerState),

    popper: {
      boxShadow: xs,
      padding: `${pxToRem(4)} ${pxToRem(6)}`,
      fontSize: size.sm,
      color: text.main,
      textAlign: 'left',
      backgroundColor: `${white.main} !important`,
      borderRadius: borderRadius.lg,
      border: `1px solid ${grey[300]}`,
      marginTop: '3px !important',
    },

    popupIndicator: {
      transition: 'all 0.3s',
    },

    paper: {
      boxShadow: 'none',
      backgroundColor: transparent.main,
    },
    listbox: {
      paddingTop: pxToRem(4),
      paddingBottom: pxToRem(4),
    },

    option: {
      padding: `${pxToRem(8)} ${pxToRem(12)} !important`,
      borderRadius: borderRadius.md,
      fontSize: size.sm,
      color: dark.main,
      ':first-of-type': {
        marginTop: 0,
      },
      marginTop: 3,
      transition: 'background-color 300ms ease, color 300ms ease',

      '&:hover, &:focus, &.Mui-selected, &.Mui-selected:hover, &.Mui-selected:focus': {
        backgroundColor: light.main,
        color: dark.main,
      },

      '&[aria-selected="true"]': {
        backgroundColor: `${light.main} !important`,
        color: `${dark.main} !important`,
      },
    },

    noOptions: {
      fontSize: size.sm,
      color: dark.main,
      padding: pxToRem(4),
    },

    groupLabel: {
      color: dark.main,
    },

    loading: {
      fontSize: size.sm,
      color: dark.main,
    },

    tag: {
      display: 'flex',
      alignItems: 'center',
      height: 'auto',
      padding: pxToRem(4),
      backgroundColor: primary.main,
      color: white.main,
      borderRadius: borderRadius.lg,
      maxWidth: 'calc(100% - 40%)',
      border: 0,

      '& .MuiChip-label': {
        lineHeight: 1.2,
        padding: `0 ${pxToRem(10)} 0 ${pxToRem(4)}`,
      },

      '& .MuiSvgIcon-root, & .MuiSvgIcon-root:hover, & .MuiSvgIcon-root:focus': {
        color: white.main,
        marginRight: 0,
      },
    },
  },
};

export default autocomplete;
