import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mui/material';
import { GLbeforeAfterAlbumPair, PLbeforeAfterAlbumPair } from 'apiServices';
import { useForm } from 'react-hook-form';

import { FormDialogWindowContent } from 'components/BaseDialogWindow';
import { RHFAutocompleteField, RHFTextField } from 'components/HookForm';
import { Scrollbar } from 'components/ScrollBar';
import { ToastType, notice } from 'components/ToastNotification';
import { useOptions } from 'hooks';
import { formErrorHandler } from 'utils/errorHanders';

import { UploadImageCard } from '../UploadImageCard';
import {
  Create_ba_album_pair_form_schema,
  create_ba_album_pair_defaultValues,
  create_ba_album_pair_validation_schema,
} from './form.config';
import { AddAlbumImagesDialogWindowFormProps } from './types';

export const AddBeforeAfterImagesForm = <T extends PLbeforeAfterAlbumPair | GLbeforeAfterAlbumPair>({
  onFetchAlbumsHandler,
  isRoleAdmin,
  closeAddAlbumDialogWindow,
  afterImageData,
  beforeImageData,
  createBAimagePairAPIrequest,
  onCreateAfterImageHandler,
  onCreateBeforeImageHandler,
  albumId,
}: AddAlbumImagesDialogWindowFormProps<T>) => {
  const { practices, isPracticeLoading, onChangePracticeInputValueHandler } = useOptions({
    isImmediateFetchPractices: isRoleAdmin ?? false,
  });

  const {
    control,
    register,
    handleSubmit,
    setError,
    formState: { isValid, isSubmitting, errors },
  } = useForm<Create_ba_album_pair_form_schema>({
    resolver: yupResolver(create_ba_album_pair_validation_schema),
    mode: 'onTouched',
    defaultValues: { ...create_ba_album_pair_defaultValues, albumId },
    context: { isPracticeIdRequired: isRoleAdmin },
  });

  const onFormSubmitHandler = handleSubmit(async formData => {
    const { name, description, practiceId, albumId } = formData;
    try {
      const { data } = await createBAimagePairAPIrequest({
        name,
        albumId,
        ...(description && { description }),
        ...(practiceId && { practiceId }),
        isActive: true,
      });

      const promiseArray = [];

      if (beforeImageData.previewURL) promiseArray.push(onCreateBeforeImageHandler(data.id));

      if (afterImageData.previewURL) promiseArray.push(onCreateAfterImageHandler(data.id));

      await Promise.all(promiseArray);

      await onFetchAlbumsHandler({ page: 1 });

      notice(ToastType.SUCCESS, 'Before & After photos has been successfully created!');
      closeAddAlbumDialogWindow();
    } catch (error) {
      formErrorHandler({
        error,
        config: { formError: { setError } },
        customErrorMessage: 'Failed to create Before & After photos, please try again!',
      });
    }
  });

  const isSubmitButtonDisabled =
    !isValid || isSubmitting || (!beforeImageData.previewURL && !afterImageData.previewURL);

  return (
    <>
      <FormDialogWindowContent
        onFormSubmitHandler={onFormSubmitHandler}
        headerProps={{ title: 'Add Before & After', onClickCancelButtonHandler: closeAddAlbumDialogWindow }}
        actionProps={{
          approveButtonProps: { disabled: isSubmitButtonDisabled, isLoading: isSubmitting },
          cancelButtonProps: { children: 'Cancel' },
        }}
      >
        <Scrollbar sx={{ height: 1, maxHeight: 380 }}>
          <Stack
            sx={{
              '.MuiStack-root': {
                width: 1,
              },
            }}
            spacing={2}
            mb="1px"
          >
            <RHFTextField
              register={register}
              registerName="name"
              registerErrors={errors.name?.message}
              fullWidth
              required
              label="Name"
              placeholder="Enter name"
            />

            {isRoleAdmin && (
              <RHFAutocompleteField
                control={control}
                name="practiceId"
                isRequired
                placeholder="Select practice"
                label="Practice"
                options={practices}
                onInputChange={onChangePracticeInputValueHandler}
                getOptionLabel={option => {
                  if (typeof option === 'string') return option;
                  return option.name;
                }}
                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                loading={isPracticeLoading}
              />
            )}

            <Stack py={1} width={1} direction={{ xs: 'column', sm: 'row' }} gap={1.5}>
              <UploadImageCard
                previewURL={beforeImageData.previewURL}
                onClickRemoveImageHandler={beforeImageData.onRemoveFileHandler}
                onUploadAnotherFileHandler={beforeImageData.onSelectAnotherFileHandler}
                onDropFileHandler={beforeImageData.onSelectFileHandler}
                type="before"
              />

              <UploadImageCard
                previewURL={afterImageData.previewURL}
                onClickRemoveImageHandler={afterImageData.onRemoveFileHandler}
                onUploadAnotherFileHandler={afterImageData.onSelectAnotherFileHandler}
                onDropFileHandler={afterImageData.onSelectFileHandler}
                type="after"
              />
            </Stack>

            {/* TODO: uncoment when BE will be ready */}
            {/* <Stack direction="row" gap={0.5} alignItems="center">
              <FormControlLabel
                onChange={() => {}}
                checked={false}
                control={<Switch />}
                label="Published"
                labelPlacement="end"
              />
              <HoverMenuPopover
                renderItem={bindedState => (
                  <Box
                    {...bindedState}
                    sx={{
                      ':hover': {
                        cursor: 'pointer',
                      },
                    }}
                  >
                    Todo: add appropriate icon
                    <InfoOutlined />
                  </Box>
                )}
              >
                Todo: add appropriate text
                info
              </HoverMenuPopover>
            </Stack> */}
          </Stack>
        </Scrollbar>
      </FormDialogWindowContent>
    </>
  );
};
