import Drawer from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';

export default styled(Drawer, {
  shouldForwardProp: prop => prop !== 'customWidth',
})<{ customWidth: number; ownerState: { openConfigurator: boolean } }>(({ theme, ownerState, customWidth }) => {
  const { boxShadows, transitions } = theme;
  const { openConfigurator } = ownerState;

  const configuratorWidth = customWidth || 400;
  const { lg } = boxShadows;

  // drawer styles when openConfigurator={true}
  const drawerOpenStyles = () => ({
    maxWidth: configuratorWidth,
    width: '100%',
    transition: transitions.create('right', {
      easing: transitions.easing.sharp,
      duration: transitions.duration.short,
    }),
  });

  // drawer styles when openConfigurator={false}
  const drawerCloseStyles = () => ({
    transition: transitions.create('all', {
      easing: transitions.easing.sharp,
      duration: transitions.duration.short,
    }),
  });

  return {
    '& .MuiBackdrop-root': { opacity: '0.4 !important' },
    '& .MuiDrawer-paper': {
      height: '100svh',
      margin: 0,
      padding: 0,
      borderRadius: 0,
      boxShadow: lg,
      overflowY: 'auto',
      ...(openConfigurator ? drawerOpenStyles() : drawerCloseStyles()),
    },
  };
});
