import { FC, memo, useEffect, useState } from 'react';

import { Box, Divider, Grid, Stack } from '@mui/material';
import { deletePatientAllergy } from 'apiServices';

import { FormHeader } from 'components/Form';
import { Icon } from 'components/Icon';
import { PopoverMenuItem } from 'components/MenuPopover';
import { SelectorPopupMedical } from 'components/SelectorPopupMedical/SelectorPopupMedical';
import { ToastType, notice } from 'components/ToastNotification';
import { useDictionary } from 'hooks/useDictionary';
import { Diagnosis } from 'pages/Patients/pages/PatientView/TabContentComponents';
import { backendErrorHandler } from 'utils/errorHanders';

import { ALLERGIES_ID } from '../../constants';
import { AllergiesCardProps } from './types';

export const AllergiesCard: FC<AllergiesCardProps> = memo(
  ({ cardRef, allergies: patientAllergies, patientId, fetchPatient }) => {
    const { fetchAllergies } = useDictionary();

    useEffect(() => {
      fetchAllergies({ page: 1, size: 100 });
    }, []);

    const [anchor, setAnchor] = useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchor(anchor ? null : event.currentTarget);
    };

    const open = Boolean(anchor);
    const id = open ? 'simple-popup' : undefined;

    const onDeleteAllergyHandler = async (selectedItemId: string) => {
      try {
        await deletePatientAllergy(patientId, selectedItemId);
        await fetchPatient(patientId);

        notice(ToastType.SUCCESS, 'Allergy has been successfully removed!');
      } catch (error) {
        console.error(error);
        backendErrorHandler({
          error,
          config: { customErrorMessage: 'Failed to remove allergy, please try again!' },
        });
      }
    };

    return (
      <>
        <Stack ref={cardRef} id={ALLERGIES_ID} sx={{ p: { xs: 2, sm: 2.5, md: 3, lg: 3.5 } }}>
          <FormHeader isDivider={false} title="Allergies" />
          <Box mt={1.5}>
            <Grid container spacing={2} direction="column">
              <Grid container item spacing={2} gap={2}>
                <Grid container item>
                  <Grid item xs={12} lg={10} display="flex" alignItems="center">
                    <PopoverMenuItem
                      sx={{ display: 'flex', justifyContent: 'space-between' }}
                      iconEnd={<Icon type="add" />}
                      onClick={handleClick}
                      title="Add allergy"
                      color="text.secondary"
                    />
                    <SelectorPopupMedical
                      isMedicalConditions={false}
                      fetchPatient={fetchPatient}
                      patientId={patientId}
                      anchor={anchor}
                      isOpened={open}
                      onClose={() => setAnchor(null)}
                      id={id}
                    />
                  </Grid>
                  <Grid item xs={12} lg={10}>
                    {patientAllergies?.map(patientAllergy => {
                      return (
                        <Diagnosis
                          title={patientAllergy?.allergy?.name}
                          hardness={2}
                          id={patientAllergy?.allergyIcd10Code}
                          notes={[
                            {
                              noteAuthor: 'Michael Lee',
                              noteDate: 'Jul 1 at 2:37 pm',
                              note: 'For example, symptoms include sneezing and itchy nose. I avoid outdoor activities during peak pollen season',
                            },
                          ]}
                          context={{
                            onCopy: () => {},
                            onDelete: async () => {
                              await onDeleteAllergyHandler(patientAllergy?.id);
                            },
                            onEdit: () => {},
                          }}
                          mt={10}
                          key={patientAllergy?.allergy?.name}
                        />
                      );
                    })}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Stack>
        <Divider />
      </>
    );
  }
);
