import { FC } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mui/material';
import { GlobalLibrarySearchParams, createGlobalLibraryTagCategory } from 'apiServices';
import { Resolver, useForm } from 'react-hook-form';

import { FormDialogWindowContent } from 'components/BaseDialogWindow';
import { RHFTextField } from 'components/HookForm';
import { ToastType, notice } from 'components/ToastNotification';
import {
  CreateTagCategoryFormSchema,
  createTagCategoryDefaultValues,
  createTagCategoryValidationSchema,
} from 'pages/TagManagement';
import { formErrorHandler } from 'utils/errorHanders';

type CreateTagCategoryMenuContentProps = {
  closeMenuHandler: VoidFunction;
  fetchTagCategories: (params: { params: GlobalLibrarySearchParams; isResetPage: boolean }) => Promise<void>;
};

export const CreateTagCategoryMenuContent: FC<CreateTagCategoryMenuContentProps> = ({
  closeMenuHandler,
  fetchTagCategories,
}) => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isValid, isSubmitting },
  } = useForm<CreateTagCategoryFormSchema>({
    resolver: yupResolver(createTagCategoryValidationSchema) as Resolver<CreateTagCategoryFormSchema>,
    mode: 'onTouched',
    defaultValues: createTagCategoryDefaultValues,
  });

  const onSubmitFormHandler = handleSubmit(async ({ name, serviceName }) => {
    try {
      await createGlobalLibraryTagCategory({
        isActive: true,
        isCore: true,
        name,
        ...(serviceName && {
          serviceName,
        }),
      });

      closeMenuHandler();

      await fetchTagCategories({ isResetPage: true, params: { page: 1 } });

      notice(ToastType.SUCCESS, 'Tag category has been successfully created!');
    } catch (error) {
      console.error(error);
      formErrorHandler({
        error,
        customErrorMessage: 'Failed to create tag category, please try again!',
        config: { formError: { setError } },
      });
    }
  });

  return (
    <FormDialogWindowContent
      onFormSubmitHandler={onSubmitFormHandler}
      headerProps={{ title: 'Add tag category', onClickCancelButtonHandler: closeMenuHandler }}
      actionProps={{
        approveButtonProps: { disabled: isSubmitting || !isValid, isLoading: isSubmitting },
        cancelButtonProps: { children: 'Cancel' },
      }}
    >
      <Stack flex={1} spacing={2}>
        <RHFTextField
          fullWidth
          register={register}
          label="Tag category name"
          registerErrors={errors?.name?.message}
          registerName="name"
          required
          placeholder="Enter name"
        />
      </Stack>
    </FormDialogWindowContent>
  );
};
