import * as yup from 'yup';

import { PracticeIdValidationSchema } from 'utils/formValidation';

export type Create_ba_album_pair_form_schema = Partial<{
  name: string;
  description: string;
  practiceId: string;
  albumId: string;
}>;

export const create_ba_album_pair_validation_schema = yup
  .object()
  .shape({
    name: yup.string().required().label('Name'),
    albumId: yup.string().required().label('Album'),
    description: yup.string().optional().label('Description'),
    practiceId: PracticeIdValidationSchema,
  })
  .required();

export const create_ba_album_pair_defaultValues: Create_ba_album_pair_form_schema = {
  name: '',
  description: '',
  practiceId: '',
  albumId: '',
};
