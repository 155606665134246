import { BASE_DICTIONARY_API_URL } from 'apiServices/api.routes';
import { apiClient } from 'lib/api';

const BA_GL_ALBUMS_API_URL = `${BASE_DICTIONARY_API_URL}/b_a_albums`;

export type GLbeforeAfterAlbum = {
  id: string;
  name: string;
  description: string;
  isActive: boolean;
  pairsCount: number;
  coverThumbUrl: NullableField;
  coverUrl: NullableField;
  coverFileExtension: NullableField;
} & Timestamps;

export type GLbeforeAfterAlbumListSortFieldName = 'name' | 'is_active' | 'description' | 'created_at' | 'updated_at';

export type GLbeforeAfterAlbumListOrder = ColumnOrder<GLbeforeAfterAlbumListSortFieldName>[];

export type GLbeforeAfterAlbumSearchParams = Partial<
  {
    search: string;
    is_active: boolean;
    order: GLbeforeAfterAlbumListOrder;
  } & PaginationParams
>;

export type CreateGLBAalbumPayload = {
  name: string;
  description?: string | null;
  isActive?: boolean | null;
};

export type GetGLBAalbumSearchParams = {
  includeOriginal?: boolean;
};

export type UpdateGLBAalbumPayload = Partial<Omit<CreateGLBAalbumPayload, 'isActive'>>;

export const getGLBAalbums = async (params: GLbeforeAfterAlbumSearchParams) =>
  await apiClient.get<ListOfItems<GLbeforeAfterAlbum>>(BA_GL_ALBUMS_API_URL, { params });

export const createGLBAalbum = async (payload: CreateGLBAalbumPayload) =>
  await apiClient.post<GLbeforeAfterAlbum>(BA_GL_ALBUMS_API_URL, payload);

export const getGLBAalbum = async (id: string, params?: GetGLBAalbumSearchParams) =>
  await apiClient.get<GLbeforeAfterAlbum>(`${BA_GL_ALBUMS_API_URL}/${id}`, { params });

export const updateGLBAalbum = async (id: string, payload: UpdateGLBAalbumPayload) =>
  await apiClient.patch<GLbeforeAfterAlbum>(`${BA_GL_ALBUMS_API_URL}/${id}`, payload);

export const deleteGLBAalbum = async (id: string) => await apiClient.delete(`${BA_GL_ALBUMS_API_URL}/${id}`);

export const deactivateGLBAalbum = async (id: string) =>
  await apiClient.delete<GLbeforeAfterAlbum>(`${BA_GL_ALBUMS_API_URL}/${id}/deactivate`);

export const activateGLBAalbum = async (id: string) =>
  await apiClient.patch<GLbeforeAfterAlbum>(`${BA_GL_ALBUMS_API_URL}/${id}/activate`);

export const uploadGLBAalbumCover = async (albumId: string, payload: FormData) =>
  await apiClient.post<GLbeforeAfterAlbum>(`${BA_GL_ALBUMS_API_URL}/${albumId}/cover`, payload, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

export const deleteGLBAalbumCover = async (albumId: string) =>
  await apiClient.delete<GLbeforeAfterAlbum>(`${BA_GL_ALBUMS_API_URL}/${albumId}/cover`);
