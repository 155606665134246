import { memo, useEffect, useState } from 'react';

import { Dialog } from '@mui/material';
import { GLbeforeAfterAlbum, PLbeforeAfterAlbum } from 'apiServices';

import { ZoomCropDialogWindow } from 'components/BaseDialogWindow';
import getCroppedImg from 'components/UploadAvatar/helpers';
import { ACCEPT_INPUT_IMAGE_FILES } from 'constants/fileExtensions';
import { useFilePicker } from 'hooks';
import { backendErrorHandler } from 'utils/errorHanders';
import { generateImageFormData } from 'utils/helpers/utilities';

import { AddAlbumDialogWindowForm } from './AddAlbumDialogWindowForm';
import { AddAlbumDialogWindowProps } from './types';

export const AddAlbumDialogWindow = memo(
  <T extends PLbeforeAfterAlbum | GLbeforeAfterAlbum>({
    isOpenAddAllbumDialogWindow,
    closeAddAlbumDialogWindow,
    onFetchAlbumsHandler,
    uploadAlbumCoverAPIrequest,
    createAlbumAPIrequest,
    isRoleAdmin,
  }: AddAlbumDialogWindowProps<T>) => {
    const {
      file,
      previewURL,
      onRemoveFileHandler,
      onFileInputChangeHandler,
      isCropFileDialogOpen,
      closeCropFileDialog,
      openCropFileDialog,
      onCropCompleteHandler,
      croppedAreaPixels,
    } = useFilePicker({
      accept: ACCEPT_INPUT_IMAGE_FILES,
    });

    const [croppedImageSrc, setCroppedImageSrc] = useState<string | null>(null);

    const onClickSaveFileHandler = async () => {
      const croppedImageSrc = await getCroppedImg({ imageSrc: previewURL, pixelCrop: croppedAreaPixels });
      setCroppedImageSrc(croppedImageSrc);
      closeCropFileDialog();
    };

    const onClickRemoveFileHandler = () => {
      setCroppedImageSrc(null);
      onRemoveFileHandler();
    };

    useEffect(() => {
      if (file) openCropFileDialog();
    }, [file]);

    const onCreateAlbumCoverHandler = async (albumId: string) => {
      try {
        const formData = generateImageFormData({ file, croppedAreaPixels, formDataImageFileName: 'cover_image' });
        await uploadAlbumCoverAPIrequest(albumId, formData);
      } catch (error) {
        backendErrorHandler({ error, config: { customErrorMessage: 'Failed to upload album cover!' } });
      }
    };

    const onCloseDialogWindowHandler = () => {
      closeAddAlbumDialogWindow();
      setCroppedImageSrc(null);
      onRemoveFileHandler();
    };

    return (
      <>
        <Dialog
          open={isOpenAddAllbumDialogWindow}
          onClose={onCloseDialogWindowHandler}
          PaperProps={{
            sx: { width: isCropFileDialogOpen ? 0 : 1, maxWidth: 750 },
          }}
          hideBackdrop={isCropFileDialogOpen}
        >
          <AddAlbumDialogWindowForm
            closeAddAlbumDialogWindow={onCloseDialogWindowHandler}
            onFetchAlbumsHandler={onFetchAlbumsHandler}
            onSelectFileHandler={onFileInputChangeHandler}
            previewURL={croppedImageSrc}
            onRemoveFileHandler={onClickRemoveFileHandler}
            onCreateAlbumCoverHandler={onCreateAlbumCoverHandler}
            createAlbumAPIrequest={createAlbumAPIrequest}
            isRoleAdmin={isRoleAdmin}
          />
        </Dialog>

        {isCropFileDialogOpen && (
          <ZoomCropDialogWindow
            imageURL={previewURL}
            open={isCropFileDialogOpen}
            onClickCloseButtonHandler={closeCropFileDialog}
            onUploadNewFileHandler={onFileInputChangeHandler}
            onSaveFileHandler={onClickSaveFileHandler}
            onCropCompleteHandler={onCropCompleteHandler}
            cropShape="rect"
          />
        )}
      </>
    );
  }
);
