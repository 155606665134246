import React from 'react';

import { Box, Slider as MUISlider } from '@mui/material';
import {
  type Control,
  Controller,
  type ControllerRenderProps,
  type FieldValues,
  type Path,
  type PathValue,
} from 'react-hook-form';

interface SliderProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
  marks: { value: number; label: React.ReactNode }[];
  min: number;
  max: number;
  step?: number;
  defaultValue?: PathValue<T, Path<T>>;
  children: (field: ControllerRenderProps<T, Path<T>>) => React.ReactNode;
}

export const Slider = <T extends FieldValues>({
  control,
  name,
  marks,
  min,
  max,
  step = 1,
  defaultValue,
  children,
}: SliderProps<T>) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <Box display="flex" alignItems="center">
          <MUISlider
            {...field}
            value={Number(field.value)}
            valueLabelDisplay="auto"
            step={step}
            marks={marks}
            min={min}
            max={max}
            sx={{ mr: 2 }}
          />
          {children(field)}
        </Box>
      )}
    />
  );
};
