import React, { FC, memo } from 'react';

import { Add } from '@mui/icons-material';
import { Box, CardContent, Chip, Divider, Grid, Stack, Typography, styled } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { FormHeader } from 'components/Form';
import { RHFAutocompleteField, RHFTextField } from 'components/HookForm';
import { Icon, TIconType } from 'components/Icon';
import { Slider } from 'components/Slider';
import { Note } from 'components/Widgets/Note';

import { GENERAL_ID } from '../../constants';
import { EditMedicalFormSchema } from './form.config';
import { GeneralCardProps } from './types';

const sliderIconsByValue = [
  'noPain',
  'veryMildPain',
  'mildPain',
  'mildToModeratePain',
  'moderatePain',
  'moderateToSeverePain',
  'severePain',
  'verySeverePain',
  'extremelySeverePain',
  'worstPain',
  'impossiblePain',
] as TIconType[];

export const GeneralCard: FC<GeneralCardProps> = memo(
  ({
    genders,
    bloodTypes,
    painTolerances,
    cardRef,
    memoizedGetOptionLabel,
    memoizedIsOptionEqualToValue,
    isAllPatientEnumsLoading,
  }) => {
    const {
      control,
      register,
      formState: { errors },
    } = useFormContext<EditMedicalFormSchema>();

    const sliderMarks = Array.from({ length: 5 }).map((_, value) => ({ value, label: value + '' }));

    return (
      <>
        <CardContent ref={cardRef} id={GENERAL_ID} sx={{ p: { xs: 2, sm: 2.5, md: 3, lg: 3.5 } }}>
          <Stack>
            <FormHeader isDivider={false} title="General" />

            <Box mt={1.5}>
              <Grid container spacing={2} direction="column">
                <Grid container item spacing={2}>
                  <Grid item xs={12} lg={4}>
                    <RHFAutocompleteField
                      label="Biological Gender"
                      control={control}
                      name="patientCard.bioGender"
                      placeholder="Select Biological Gender"
                      options={genders}
                      getOptionLabel={memoizedGetOptionLabel}
                      isOptionEqualToValue={memoizedIsOptionEqualToValue}
                      loading={isAllPatientEnumsLoading}
                    />
                  </Grid>

                  <Grid item xs={12} lg={4}>
                    <RHFAutocompleteField
                      label="Blood Type"
                      control={control}
                      name="patientCard.bloodType"
                      placeholder="Select Blood Type"
                      options={bloodTypes}
                      getOptionLabel={memoizedGetOptionLabel}
                      isOptionEqualToValue={memoizedIsOptionEqualToValue}
                      loading={isAllPatientEnumsLoading}
                    />
                  </Grid>
                </Grid>

                <Grid container item spacing={2}>
                  <Grid item xs={12} lg={8}>
                    <Typography gutterBottom>Pain Tolerance</Typography>
                    <Slider
                      control={control}
                      name="patientCard.painTolerance"
                      marks={sliderMarks}
                      min={0}
                      max={painTolerances?.length - 1}
                      step={1}
                    >
                      {field => {
                        return <Icon type={sliderIconsByValue[+field.value || 0]} />;
                      }}
                    </Slider>
                  </Grid>
                </Grid>
                {/* TODO: BE not ready */}
                {/*<Grid container item spacing={2}>*/}
                {/*  <Grid item xs={12} lg={8}>*/}
                {/*    <RHFTextField*/}
                {/*      label="Downtime Tolerance"*/}
                {/*      register={register}*/}
                {/*      registerName="contact.website"*/}
                {/*      registerErrors={errors.contact?.website?.message}*/}
                {/*      fullWidth*/}
                {/*      placeholder="Enter your available recovery time"*/}
                {/*      multiline*/}
                {/*    />*/}
                {/*  </Grid>*/}
                {/*</Grid>*/}
                {/*<Grid container item>*/}
                {/*  <Grid item xs={12} lg={8}>*/}
                {/*    <Typography fontWeight={400} color="text.black" variant="caption">*/}
                {/*      Preferences/Considerations*/}
                {/*    </Typography>*/}
                {/*  </Grid>*/}
                {/*  <Grid item xs={12} lg={8}>*/}
                {/*    <StyledChip icon={<Add />} label="Add Specific" />*/}
                {/*  </Grid>*/}
                {/*  <Grid item xs={12} lg={8}>*/}
                {/*    {Array.from({ length: 3 }).map((_, i) => (*/}
                {/*      <Note*/}
                {/*        noteAuthor={'Michael Lee'}*/}
                {/*        noteDate={'Jul 1 at 2:37 pm'}*/}
                {/*        note={'Lorem ipsum dolor sit amet'}*/}
                {/*        sx={{ mt: 2, maxWidth: 'auto' }}*/}
                {/*        key={i}*/}
                {/*      />*/}
                {/*    ))}*/}
                {/*  </Grid>*/}
                {/*</Grid>*/}
              </Grid>
            </Box>
          </Stack>
        </CardContent>
        <Divider />
      </>
    );
  }
);

const StyledChip = styled(Chip)({
  width: 'auto',
  height: '34px',
  borderRadius: '10px',
  border: 'none',
  opacity: 1,
  backgroundColor: 'transparent',
});
