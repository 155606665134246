import { CreateGlobalLibraryTagCategoryPayload } from 'apiServices';
import * as yup from 'yup';

import { Shape } from 'types/RHF-types';
import { PracticeIdValidationSchema } from 'utils/formValidation';

export type CreateTagCategoryFormSchema = Pick<
  CreateGlobalLibraryTagCategoryPayload,
  'name' | 'serviceName' | 'practiceId'
>;

export const createTagCategoryValidationSchema = yup
  .object()
  .shape<Shape<CreateTagCategoryFormSchema>>({
    name: yup.string().required().label('Tag category name').trim(),
    serviceName: yup.string().optional().label('Tag category service name').nullable().trim(),
    practiceId: PracticeIdValidationSchema,
  })
  .required();

export const createTagCategoryDefaultValues: CreateTagCategoryFormSchema = {
  name: '',
  serviceName: '',
  practiceId: '',
};
