import { FC } from 'react';

import { TableCell, TableRow } from '@mui/material';

import { EmptyContent } from 'components/EmptyState';

import { ITableNoDataProps } from './Table.types';

export const TableNoData: FC<ITableNoDataProps> = props => {
  const { isNotFound, sx } = props;
  return (
    <TableRow
      sx={{
        ':hover': {
          boxShadow: 'none',
          cursor: 'auto',
        },
        ...sx,
      }}
    >
      {isNotFound ? (
        <TableCell colSpan={12}>
          <EmptyContent />
        </TableCell>
      ) : (
        <TableCell colSpan={12} sx={{ p: 0 }} />
      )}
    </TableRow>
  );
};
