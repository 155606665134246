import { FC, memo, useEffect } from 'react';

import { useMemoizedAutocompleteFunctions } from 'hooks/useMemoizedAutocompleteFunctions';
import { usePatientEnums } from 'hooks/usePatientEnums';

import { PatientContactCard } from './PatientContactCard';
import { PatientDemographicsCard } from './PatientDemographicsCard';
import { EditPatientFormProps } from './types';

export const EditPatientForm: FC<EditPatientFormProps> = memo(({ refs, fetchPatient, patientId }) => {
  const { isAllPatientEnumsLoading, fetchAllPatientEnums, patientEnums } = usePatientEnums();

  useEffect(() => {
    fetchAllPatientEnums();
  }, []);

  const {
    memoizedAddressGetOptionLabel,
    memoizedAddressIsOptionEqualToValue,
    memoizedCityGetOptionLabel,
    memoizedCityIsOptionEqualToValue,
    memoizedGetOptionLabel,
    memoizedIsOptionEqualToValue,
  } = useMemoizedAutocompleteFunctions();

  return (
    <>
      <PatientDemographicsCard
        cardRef={refs?.[0]}
        ethnicGroups={patientEnums?.ethnicGroups}
        genders={patientEnums?.genders}
        personTitles={patientEnums?.personTitles}
        memoizedGetOptionLabel={memoizedGetOptionLabel}
        memoizedIsOptionEqualToValue={memoizedIsOptionEqualToValue}
        isAllPatientEnumsLoading={isAllPatientEnumsLoading}
      />

      <PatientContactCard
        cardRef={refs?.[1]}
        memoizedAddressGetOptionLabel={memoizedAddressGetOptionLabel}
        memoizedCityGetOptionLabel={memoizedCityGetOptionLabel}
        memoizedAddressIsOptionEqualToValue={memoizedAddressIsOptionEqualToValue}
        memoizedCityIsOptionEqualToValue={memoizedCityIsOptionEqualToValue}
      />
      {/* TODO: Add note card when BE is ready */}
      {/* <NoteCard fetchPatient={fetchPatient} patientId={patientId} cardRef={refs?.[2]} /> */}
    </>
  );
});
