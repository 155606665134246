import { FC } from 'react';

import { Grid, Skeleton, Stack } from '@mui/material';

type BACardItemSkeletonProps = {
  cardsAmount?: number;
};

export const BACardItemSkeleton: FC<BACardItemSkeletonProps> = ({ cardsAmount = 4 }) => {
  return (
    <>
      {[...Array(cardsAmount)].map((_, index) => (
        <Grid item key={index} xs={12} sm={6} md={6} lg={3}>
          <Stack spacing={1}>
            <Skeleton variant="rounded" height={200} />

            <Stack spacing={1}>
              <Skeleton variant="rounded" width="60%" height={20} />
              <Skeleton variant="rounded" width="40%" height={18} />
            </Stack>
          </Stack>
        </Grid>
      ))}
    </>
  );
};
