import { FC, memo } from 'react';

import { Stack } from '@mui/material';

import { BACardItemFooter } from './BACardItemFooter';
import { BACardItemHeader } from './BACardItemHeader';
import { BACardItemProps } from './types';

export const BACardItem: FC<BACardItemProps> = memo(
  ({ id, lastAlbumCardRef, description, title, onCardClickHandler, ...headerProps }) => {
    return (
      <Stack
        spacing={1.5}
        sx={{
          position: 'relative',
          borderRadius: theme => theme.borders.borderRadius.lg,
          pb: 1.5,
          ':hover': {
            cursor: 'pointer',
            boxShadow: theme => theme.boxShadows.md,
            '.button-group-before-after, .popover-menu-before-after, .checkbox-before-after': {
              opacity: 1,
            },
          },
        }}
        id={id}
        onClick={onCardClickHandler}
        ref={lastAlbumCardRef}
      >
        <BACardItemHeader {...headerProps} id={id} title={title} />
        <BACardItemFooter title={title} description={description} />
      </Stack>
    );
  }
);
