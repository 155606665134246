import { StateCreator } from 'zustand';

import { sliceResetFns } from '../../Store';
import { BeforeAfterAlbumsInitialState, BeforeAfterAlbumsSliceType } from './types';

const initialState: BeforeAfterAlbumsInitialState = {
  b_a_gl_data: {
    page: null,
    items: [],
    total: null,
    size: null,
    pages: null,
  },
  b_a_pl_data: {
    page: null,
    items: [],
    total: null,
    size: null,
    pages: null,
  },
};

type ImmerSet<T> = (fn: (state: T) => void, replace?: boolean) => void;

const setData = <K extends keyof BeforeAfterAlbumsInitialState>(
  set: ImmerSet<BeforeAfterAlbumsInitialState>,
  key: K,
  data: BeforeAfterAlbumsInitialState[K]
) => {
  set(state => {
    state[key] = data;
  });
};

const appendData = <K extends keyof BeforeAfterAlbumsInitialState>(
  set: ImmerSet<BeforeAfterAlbumsInitialState>,
  key: K,
  data: StoreListOfItems<any>
) => {
  set(state => {
    state[key] = {
      ...state[key],
      items: [...state[key].items, ...data.items],
    };
  });
};

export const BeforeAfterAlbumsSlice: StateCreator<
  BeforeAfterAlbumsSliceType,
  [['zustand/devtools', never], ['zustand/immer', never]],
  [],
  BeforeAfterAlbumsSliceType
> = set => {
  sliceResetFns.add(() => set(initialState));

  return {
    ...initialState,
    set_b_a_gl_albums: data => setData(set, 'b_a_gl_data', data),
    set_more_b_a_gl_albums: data => appendData(set, 'b_a_gl_data', data),
    set_b_a_pl_albums: data => setData(set, 'b_a_pl_data', data),
    set_more_b_a_pl_albums: data => appendData(set, 'b_a_pl_data', data),
  };
};
