import React, { FC, useEffect, useMemo, useState } from 'react';

import { Autocomplete, AutocompleteInputChangeReason, Box, Slider, Stack } from '@mui/material';
import { DictionaryCityItem, DictionaryItem, PatientEnum } from 'apiServices';

import { MDInput } from 'components/MDInput';
import MDTypography from 'components/MDTypography';
import { FilterMenuPopoverButtons } from 'components/MenuPopover';
import { Scrollbar } from 'components/ScrollBar';
import { useGeoData } from 'hooks/useGeoData';
import { usePatientEnums } from 'hooks/usePatientEnums';
import { getFilterApplyButtonStatus, getFilterResetButtonStatus } from 'utils/helpers';

import { FilterInitialState, FiltersMenuContentProps } from '../types';
import { AGE_FROM_DEFAULT_VALUE, AGE_TO_DEFAULT_VALUE, FilterInitialStateValue } from '../usePatients.state';

export const FiltersMenuContent: FC<FiltersMenuContentProps> = ({
  appliedFilters,
  setAppliedFilters,
  onGetPatientsHandler,
}) => {
  const [filters, setFilters] = useState<FilterInitialState>(appliedFilters);

  const { ageValues, bioGenderValue, cityValue, countryValue, ethnicGroupValue, genderValue, stateValue } = filters;
  const {
    patientGenderEnums,
    patientEthnicGroupsEnums,
    isPatientGenderEnumsLoading,
    isPatientEthnicGroupsEnumsLoading,
    isPatientBioGenderEnumsLoading,
    patientBioGenderEnums,
    fetchPatientGenderEnums,
    fetchPatientEthnicGroupsEnums,
    fetchPatientBioGenderEnums,
  } = usePatientEnums();
  useEffect(() => {
    fetchPatientEthnicGroupsEnums();
    fetchPatientGenderEnums();
    fetchPatientBioGenderEnums();
  }, []);

  const {
    countries,
    countryStates,
    onChangeCityInputValueHandler,
    onChangeCountryInputValueHandler,
    onSelectCountryHandler,
    stateCities,
    isCountriesLoading,
    isCountryStatesLoading,
    isStateCitiesLoading,
    onSelectStateHandler,
  } = useGeoData({ currentStateISOcode: filters.stateValue?.isoCode || '' });

  const handleChange =
    (field: keyof FilterInitialState) =>
    (
      _: React.SyntheticEvent<Element, Event>,
      value: PatientEnum | DictionaryCityItem | DictionaryItem | null | number[]
    ) => {
      setFilters(prev => ({ ...prev, [field]: value }));
    };

  const onChangeAgeHandler = (_: Event, newValue: number | number[]) => {
    setFilters(prev => ({ ...prev, ageValues: newValue as number[] }));
  };

  const onChageCountryValueHandler = async (_: React.SyntheticEvent<Element, Event>, value: DictionaryItem) => {
    setFilters(prev => ({ ...prev, countryValue: value }));
    await onSelectCountryHandler(value);
  };

  const onChageStateValueHandler = async (_: React.SyntheticEvent<Element, Event>, value: DictionaryItem) => {
    setFilters(prev => ({ ...prev, stateValue: value }));
    await onSelectStateHandler(value);
  };

  const [isFiltersApplying, setIsFiltersApplying] = useState(false);

  const onClickResetFiltersButtonHandler = () => setFilters(FilterInitialStateValue);

  const onClickApplyFiltersButtonHandler = async () => {
    setIsFiltersApplying(true);
    await onGetPatientsHandler({
      params: {
        age_from: ageValues?.[0],
        age_to: ageValues?.[1],
        bio_gender: bioGenderValue?.value,
        ethnic_group: ethnicGroupValue?.value,
        gender: genderValue?.value,
        home_address_city_id: cityValue?.id,
        home_address_country_iso_code: countryValue?.isoCode,
        home_address_state_iso_code: stateValue?.isoCode,
      },
      isDefaultPage: true,
    });
    setIsFiltersApplying(false);
    setAppliedFilters(filters);
  };

  const filtersEntriesWithoutAge = Object.entries(filters).slice(1);

  const isAgeValueEqualToApplied =
    filters.ageValues[0] === appliedFilters.ageValues[0] && filters.ageValues[1] === appliedFilters.ageValues[1];

  const isAgeValueEqualToDEFAULT =
    filters.ageValues[0] === AGE_FROM_DEFAULT_VALUE && filters.ageValues[1] === AGE_TO_DEFAULT_VALUE;

  const isApplyButtonDisabled =
    useMemo(
      () => getFilterApplyButtonStatus({ appliedFilters, filterEntries: filtersEntriesWithoutAge }),
      [filtersEntriesWithoutAge]
    ) && isAgeValueEqualToApplied;

  const isResetButtonDisabled =
    useMemo(
      () => getFilterResetButtonStatus({ filterEntries: filtersEntriesWithoutAge }),
      [filtersEntriesWithoutAge]
    ) && isAgeValueEqualToDEFAULT;

  const renderAutocomplete = <TOption,>({
    getOptionLabel,
    isLoading,
    isOptionEqualToValue,
    label,
    onChange,
    onInputChange,
    options,
    placeholder,
    value,
    isDisabled,
  }: {
    value: TOption;
    onChange: (event: React.SyntheticEvent<Element, Event>, value: TOption) => void;
    options: TOption[];
    label: string;
    placeholder: string;
    isLoading: boolean;
    isOptionEqualToValue: (option: TOption, value: TOption) => boolean;
    getOptionLabel: (option: TOption) => string;
    onInputChange?: (
      _: React.SyntheticEvent<Element, Event>,
      value: string,
      reason: AutocompleteInputChangeReason
    ) => Promise<void>;
    isDisabled?: boolean;
  }) => (
    <Autocomplete
      value={value}
      onChange={onChange}
      onInputChange={onInputChange}
      getOptionLabel={getOptionLabel}
      options={options}
      freeSolo={false}
      isOptionEqualToValue={isOptionEqualToValue}
      disableClearable={false}
      disabled={isDisabled}
      renderInput={params => (
        <MDInput
          isLoading={isLoading}
          {...params}
          label={label}
          placeholder={placeholder}
          InputLabelProps={{ shrink: true }}
          fullWidth
        />
      )}
    />
  );
  return (
    <Stack position="relative">
      <Scrollbar style={{ maxHeight: 410 }}>
        <Stack
          mt={1}
          height={1}
          spacing={2}
          sx={{
            mb: '50px',
            py: '10px',
          }}
        >
          <Stack spacing={2.5} mt={2}>
            {renderAutocomplete({
              value: filters.bioGenderValue,
              getOptionLabel: option => option.value,
              isLoading: isPatientBioGenderEnumsLoading,
              isOptionEqualToValue: (option, value) => option.name === value.name,
              label: 'Bio gender',
              onChange: handleChange('bioGenderValue'),
              options: patientBioGenderEnums,
              placeholder: 'Select Bio Gender',
            })}

            {renderAutocomplete({
              value: filters.genderValue,
              getOptionLabel: option => option.value,
              isLoading: isPatientGenderEnumsLoading,
              isOptionEqualToValue: (option, value) => option.name === value.name,
              label: 'Gender',
              onChange: handleChange('genderValue'),
              options: patientGenderEnums,
              placeholder: 'Select Gender',
            })}

            {renderAutocomplete({
              value: filters.ethnicGroupValue,
              getOptionLabel: option => option.value,
              isLoading: isPatientEthnicGroupsEnumsLoading,
              isOptionEqualToValue: (option, value) => option.name === value.name,
              label: 'Ethnicity',
              onChange: handleChange('ethnicGroupValue'),
              options: patientEthnicGroupsEnums,
              placeholder: 'Select Ethnicity',
            })}

            <Stack width={1} spacing={1}>
              <Stack direction="row" justifyContent="space-between" alignItems="center" width={1}>
                <MDTypography variant="body2" fontWeight="medium">
                  Age
                </MDTypography>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <MDInput
                    size="small"
                    disabled
                    sx={{ width: 60, pr: 0, '.MuiInputBase-input': { p: 1 }, '.MuiInputBase-root': { pr: 0 } }}
                    value={`${filters.ageValues[0]} - ${filters.ageValues[1]}`}
                  />
                </Box>
              </Stack>
              <Box sx={{ pl: '15px', pr: '18px' }}>
                <Slider
                  value={filters.ageValues}
                  onChange={onChangeAgeHandler}
                  size="medium"
                  getAriaLabel={() => 'Patient Age'}
                  valueLabelDisplay="auto"
                />
              </Box>
            </Stack>

            {renderAutocomplete({
              value: filters.countryValue,
              options: countries?.items || [],
              isLoading: isCountriesLoading,
              getOptionLabel: option => option.isoName,
              isOptionEqualToValue: (option, value) => option.isoCode === value.isoCode,
              onChange: onChageCountryValueHandler,
              onInputChange: onChangeCountryInputValueHandler,
              label: 'Home address country',
              placeholder: 'Select Country',
            })}

            {renderAutocomplete({
              value: filters.stateValue,
              options: countryStates,
              isLoading: isCountryStatesLoading,
              getOptionLabel: option => option.isoName,
              isOptionEqualToValue: (option, value) => option.isoCode === value.isoCode,
              onChange: onChageStateValueHandler,
              label: 'Home address state',
              placeholder: !filters.countryValue ? 'Select Country First' : 'Select State',
              isDisabled: !filters.countryValue,
            })}

            {renderAutocomplete({
              value: filters.cityValue,
              options: stateCities,
              isLoading: isStateCitiesLoading,
              getOptionLabel: option => option.name,
              isOptionEqualToValue: (option, value) => option.id === value.id,
              onChange: handleChange('cityValue'),
              onInputChange: onChangeCityInputValueHandler,
              label: 'Home address city',
              placeholder: !filters.stateValue ? 'Select State First' : 'Select a City',
              isDisabled: !filters.stateValue,
            })}
          </Stack>
        </Stack>
      </Scrollbar>
      <FilterMenuPopoverButtons
        isClearButtonDisabled={isResetButtonDisabled}
        onClickClearButtonHandler={onClickResetFiltersButtonHandler}
        isApplyButtonDisabled={isApplyButtonDisabled || isFiltersApplying}
        onClickApplyButtonHandler={onClickApplyFiltersButtonHandler}
      />
    </Stack>
  );
};
