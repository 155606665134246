import { useCallback, useState } from 'react';

export const useCheckedIDs = () => {
  const [checkedIds, setCheckedIds] = useState<Set<string>>(new Set([]));

  const onCheckItemHandler = useCallback((id: string, checked: boolean) => {
    setCheckedIds(prevState => {
      const newCheckedIds = new Set(prevState);

      if (checked) {
        newCheckedIds.add(id);
      } else newCheckedIds.delete(id);

      return newCheckedIds;
    });
  }, []);

  const onResetChekedIds = useCallback(() => setCheckedIds(new Set([])), []);

  return {
    checkedIds,
    onCheckItemHandler,
    onResetChekedIds,
  };
};
