import { useEffect, useState } from 'react';

import { ToastType, notice } from 'components/ToastNotification';

enum Status {
  Idle = 'idle',
  Loading = 'loading',
  Error = 'error',
  Success = 'success',
}

export type UseImageStatusProps = {
  src: string;
  initialStatus?: Status;
  errorMessage?: string;
  isShowErrorNotification?: boolean;
  isShouldStartLoading?: boolean;
};

export const useImageStatus = ({
  src,
  initialStatus = Status.Idle,
  errorMessage = 'Failed to get photo!',
  isShowErrorNotification = true,
  isShouldStartLoading = false,
}: UseImageStatusProps) => {
  const [status, setStatus] = useState<Status>(src ? Status.Loading : initialStatus);

  useEffect(() => {
    if (!src || !isShouldStartLoading) return;

    if (status !== Status.Loading) setStatus(Status.Loading);

    const img = new Image();

    const handleLoad = () => setStatus(Status.Success);

    const handleError = () => {
      if (isShowErrorNotification) {
        notice(ToastType.ERROR, errorMessage);
      }
      setStatus(Status.Error);
    };

    img.onload = handleLoad;
    img.onerror = handleError;

    img.src = src;

    return () => {
      img.onload = null;
      img.onerror = null;
    };
  }, [src, isShouldStartLoading]);

  const isImageLoading = status === Status.Loading;
  const isImageLoaded = status === Status.Success;
  const isImageLoadingError = status === Status.Error;
  const isImageIdle = status === Status.Idle;

  return {
    status,
    isImageLoading,
    isImageLoaded,
    isImageLoadingError,
    isImageIdle,
  };
};
