type GenerateActivateDeactivateDialogWindowDataProps = { actionType: ActionType; title: string };

export const generateActivateDeactivateDialogWindowData = ({
  actionType,
  title,
}: GenerateActivateDeactivateDialogWindowDataProps) => {
  const action = actionType === 'activate' ? 'activate' : 'deactivate';
  const oppositeAction = actionType === 'activate' ? 'deactivate' : 'activate';

  const fullTitle = `${action.charAt(0).toUpperCase() + action.slice(1)} ${title}`;

  return { fullTitle, action, oppositeAction };
};
