import { FC } from 'react';

import { Skeleton, SkeletonProps, SxProps, Theme } from '@mui/material';

import { AVATAR_SIZES_MAP, AvatarSize } from './MDAvatar';

type MDAvatarSkeletonProps = SkeletonProps & {
  avatarSize: AvatarSize;
  sx?: SxProps<Theme>;
};

export const MDAvatarSkeleton: FC<MDAvatarSkeletonProps> = ({ sx, avatarSize, variant, ...other }) => {
  const size = AVATAR_SIZES_MAP[avatarSize || 'md'];
  return (
    <Skeleton
      variant={variant === 'circular' ? 'circular' : 'rounded'}
      sx={{
        width: size.width,
        height: size.width,
        flexShrink: 0,
        ...sx,
      }}
      {...other}
    />
  );
};
