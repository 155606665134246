import { FC } from 'react';

import { styled } from '@mui/material';

import MDButton, { MDButtonProps } from 'components/MDButton';

type UploadFileButtonProps = MDButtonProps & {
  onChangeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
};

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export const UploadFileButton: FC<UploadFileButtonProps> = ({ onChangeHandler, children, inputProps, ...props }) => {
  return (
    <MDButton component="label" role={undefined} {...props}>
      {children}
      <VisuallyHiddenInput id="uploadInput" type="file" onChange={onChangeHandler} {...inputProps} />
    </MDButton>
  );
};
