import { FC } from 'react';

import { Box, Divider, Skeleton, Stack, SxProps, Theme } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { RHFTextField } from 'components/HookForm';

import { CreateBAalbumFormSchema } from '../CreateAlbumDialogWindow/form.config';

type AlbumProfileFormSectionProps = { isLoading?: boolean; sx?: SxProps<Theme>; isViewOnly?: boolean };

export const AlbumProfileFormSection: FC<AlbumProfileFormSectionProps> = ({ isLoading, sx, isViewOnly }) => {
  const {
    register,
    formState: { errors, isDirty },
  } = useFormContext<CreateBAalbumFormSchema>();

  return (
    <Box display="flex" width={1} sx={sx}>
      <Stack width={1} p={{ xs: 2, sm: 2.5, md: 3, lg: 3.5 }}>
        <Stack spacing={3} maxWidth={700}>
          {isLoading ? (
            <>
              <Skeleton variant="rounded" width="100%" height={40} />
              <Skeleton variant="rounded" width="100%" height={100} />
            </>
          ) : (
            <>
              <RHFTextField
                register={register}
                registerErrors={errors?.name?.message}
                registerName="name"
                label="Name"
                required
                placeholder="Enter name"
                disabled={isViewOnly}
              />

              <RHFTextField
                register={register}
                registerErrors={errors?.description?.message}
                registerName="description"
                label="Description"
                placeholder="Enter description"
                multiline
                minRows={5}
                maxRows={8}
                disabled={isViewOnly}
              />
            </>
          )}
        </Stack>
      </Stack>
      <Divider
        sx={{
          ...(isDirty && {
            display: 'none',
          }),
        }}
      />
    </Box>
  );
};
