import { FC, useState } from 'react';

import { Box, Dialog, DialogContent, Divider, Skeleton, Slider, Stack, Typography } from '@mui/material';
import Cropper from 'react-easy-crop';
import { Point } from 'react-easy-crop/types';

import { BaseDialogWindow, DialogHeader, ZoomCropDialogWindowProps } from 'components/BaseDialogWindow';
import { Icon } from 'components/Icon';
import { ToastType, notice } from 'components/ToastNotification';
import { useImageStatus } from 'hooks';
import { useBoolean } from 'hooks/useBoolean';
import { backendErrorHandler } from 'utils/errorHanders';

import { Actions } from './Actions';

export const ZoomCropDialogWindow: FC<ZoomCropDialogWindowProps> = ({
  cropShape = 'round',
  imageURL,
  initialPhotoURL,
  onClickCloseButtonHandler,
  onUploadNewFileHandler,
  isLoading,
  onSaveFileHandler,
  onCropCompleteHandler,
  onDeleteFileHandler,
  isFileError,
  ...props
}) => {
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  const onChangeZoomHandler = (_: Event, value: number | number[]) => setZoom(Number(value));

  const onClickUploadNewFileButton = (event: React.ChangeEvent<HTMLInputElement>) => {
    onUploadNewFileHandler(event);
    if (zoom > 1) setZoom(1);
  };

  const { isImageLoading, isImageLoaded, isImageLoadingError } = useImageStatus({
    src: imageURL || initialPhotoURL,
    isShouldStartLoading: true,
  });

  const [isDeleteDialogOpen, openDeleteDialog, closeDeleteDialog] = useBoolean();

  const [isDeletingImage, setIsDeletingImage] = useState(false);

  const onClickDeleteImageHandler = async (e: React.MouseEvent<HTMLElement>) => {
    setIsDeletingImage(true);
    try {
      await onDeleteFileHandler();
      closeDeleteDialog();

      notice(ToastType.SUCCESS, 'Photo has been deleted successfully!');
    } catch (error) {
      backendErrorHandler({ error, config: { customErrorMessage: 'Failed to delete photo, please try again!' } });
    } finally {
      setIsDeletingImage(false);
    }
  };

  return (
    <>
      <Dialog
        onClose={onClickCloseButtonHandler}
        PaperProps={{ sx: { maxWidth: 550, width: isDeleteDialogOpen ? 0 : 1 } }}
        hideBackdrop={isDeleteDialogOpen}
        {...props}
      >
        <Stack px={{ xs: 1.5, sm: 3 }}>
          <DialogHeader
            isHideDivider
            sx={{ px: 0 }}
            onClickCancelButtonHandler={onClickCloseButtonHandler}
            title={initialPhotoURL && !imageURL ? 'View Photo' : 'Add Photo'}
          />

          <DialogContent sx={{ px: 0, pt: 0, pb: 0.5, overflow: 'visible' }}>
            {isImageLoadingError ? (
              <Stack alignItems="center" justifyContent="center" height={370} width={1}>
                <Typography variant="h6" fontWeight={500} textAlign="center" mt={1}>
                  Failed to get the photo!
                </Typography>
              </Stack>
            ) : (
              <>
                <Skeleton
                  sx={{ display: isImageLoading ? 'flex' : 'none', width: '100%', height: 380, mb: 1.5 }}
                  variant="rounded"
                />

                {initialPhotoURL && !imageURL ? (
                  <Stack
                    sx={{
                      ...(!isImageLoaded && {
                        display: 'none',
                      }),
                    }}
                    mb={2}
                    width={1}
                    height={{ xs: 250, sm: 370 }}
                    position="relative"
                  >
                    <Box
                      sx={{
                        margin: 'auto',
                        maxWidth: 1,
                        maxHeight: 1,
                        position: 'absolute',
                        left: 0,
                        top: 0,
                        bottom: 0,
                        right: 0,
                      }}
                      component="img"
                      src={initialPhotoURL}
                      alt="photo avatar"
                    />
                  </Stack>
                ) : (
                  <>
                    <Stack
                      position="relative"
                      height={{ xs: 250, sm: 370 }}
                      width={1}
                      sx={{
                        '.reactEasyCrop_CropArea': {
                          color: 'rgba(0, 0, 0, 0.3)',
                        },
                        ...(!isImageLoaded && {
                          display: 'none',
                        }),
                      }}
                    >
                      <Cropper
                        image={imageURL}
                        crop={crop}
                        zoom={zoom}
                        aspect={1}
                        onCropChange={setCrop}
                        cropShape={cropShape}
                        restrictPosition={true}
                        showGrid={false}
                        onZoomChange={setZoom}
                        onCropComplete={onCropCompleteHandler}
                      />
                      <Box
                        sx={{
                          borderRadius: theme => theme.borders.borderRadius.lg,
                          position: 'absolute',
                          bottom: 10,
                          left: '50%',
                          transform: 'translateX(-50%)',
                          p: 1,
                          bgcolor: 'rgba(24, 34, 48, 0.3)',
                        }}
                      >
                        <Stack
                          direction="row"
                          alignItems="center"
                          spacing={1}
                          color={theme => theme.palette.common.white}
                        >
                          <Icon
                            type="moveIcon"
                            sx={{
                              svg: { width: 20, height: 20 },
                            }}
                          />
                          <Typography variant="caption" fontSize={14} fontWeight={500}>
                            Move
                          </Typography>
                        </Stack>
                      </Box>
                    </Stack>

                    <Stack
                      sx={{
                        ...(!isImageLoaded && {
                          display: 'none',
                        }),
                      }}
                      mt={2}
                      spacing={1}
                      position="relative"
                    >
                      <Typography variant="body2" fontWeight={400}>
                        Zoom
                      </Typography>
                      <Box px={0.5}>
                        <Slider
                          value={zoom}
                          min={1}
                          max={3}
                          step={0.1}
                          aria-labelledby="Zoom"
                          valueLabelDisplay="off"
                          onChange={onChangeZoomHandler}
                        />
                      </Box>
                    </Stack>
                  </>
                )}
              </>
            )}
          </DialogContent>

          <Divider orientation="horizontal" />
          <Actions
            onClickUploadNewFileButton={onClickUploadNewFileButton}
            onSaveFileHandler={onSaveFileHandler}
            isDeleteMode={!!initialPhotoURL && !imageURL}
            openDeleteDialog={openDeleteDialog}
            isLoading={isLoading}
            isImageLoading={isImageLoading}
            isFileError={isFileError}
          />
        </Stack>
      </Dialog>

      <BaseDialogWindow
        onClickCancelButtonHandler={closeDeleteDialog}
        open={isDeleteDialogOpen}
        description="Are you sure you want to delete photo?"
        title="Deleting photo"
        isApproveButtonDisabled={isDeletingImage}
        isApproveButtonLoading={isDeletingImage}
        approveButtonTitle="Delete photo"
        onClickApproveButtonHandler={onClickDeleteImageHandler}
        PaperProps={{ sx: { maxWidth: 400 } }}
        fullWidth
      />
    </>
  );
};
