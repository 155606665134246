import { BASE_DICTIONARY_API_URL } from 'apiServices/api.routes';
import { apiClient } from 'lib/api';

import {
  CreateGLBAalbumPayload,
  GLbeforeAfterAlbum,
  GLbeforeAfterAlbumSearchParams,
} from './beforeAfterAlbumsGlobalLibrary.api';

const BA_GL_ALBUM_PAIRS_API_URL = `${BASE_DICTIONARY_API_URL}/b_a_pairs`;

export type GLbeforeAfterAlbumPair = {
  id: string;
  name: string;
  description: string;
  isActive: boolean;
  imageLeftUrl: string;
  imageRightUrl: string;
  imageLeftExtension: NullableField;
  imageLeftThumbUrl: NullableField;
  imageRightExtension: NullableField;
  imageRightThumbUrl: NullableField;
  album: Pick<GLbeforeAfterAlbum, 'id' | 'name' | 'pairsCount' | 'createdAt' | 'updatedAt' | 'isActive'>;
} & Timestamps;

export type GLbeforeAfterAlbumPairsSearchParams = GLbeforeAfterAlbumSearchParams & {
  album_id: string;
};

export type CreateGLBAalbumPairPayload = CreateGLBAalbumPayload & {
  albumId: string;
};

export type UpdateGLBAalbumPairPayload = Partial<Omit<CreateGLBAalbumPairPayload, 'isActive' | 'albumId'>>;

export type MoveGLBAalbumPairPayload = {
  albumId: string;
};

export const get_gl_ba_album_pairs = async (params: GLbeforeAfterAlbumPairsSearchParams) =>
  await apiClient.get<ListOfItems<GLbeforeAfterAlbumPair>>(BA_GL_ALBUM_PAIRS_API_URL, { params });

export const create_gl_ba_album_pair = async (payload: CreateGLBAalbumPairPayload) =>
  await apiClient.post<GLbeforeAfterAlbumPair>(BA_GL_ALBUM_PAIRS_API_URL, payload);

export const get_gl_ba_album_pair = async (pairId: string) =>
  await apiClient.get<GLbeforeAfterAlbumPair>(`${BA_GL_ALBUM_PAIRS_API_URL}/${pairId}`);

export const update_gl_ba_album_pair = async (pairId: string, payload: UpdateGLBAalbumPairPayload) =>
  await apiClient.patch<GLbeforeAfterAlbumPair>(`${BA_GL_ALBUM_PAIRS_API_URL}/${pairId}`, payload);

export const delete_gl_ba_album_pair = async (pairId: string) =>
  await apiClient.delete(`${BA_GL_ALBUM_PAIRS_API_URL}/${pairId}`);

export const deactivate_gl_ba_album_pair = async (pairId: string) =>
  await apiClient.delete<GLbeforeAfterAlbumPair>(`${BA_GL_ALBUM_PAIRS_API_URL}/${pairId}/deactivate`);

export const activate_gl_ba_album_pair = async (pairId: string) =>
  await apiClient.patch<GLbeforeAfterAlbumPair>(`${BA_GL_ALBUM_PAIRS_API_URL}/${pairId}/activate`);

export const move_gl_ba_album_pair = async (pairId: string, payload: MoveGLBAalbumPairPayload) =>
  await apiClient.patch<GLbeforeAfterAlbumPair>(`${BA_GL_ALBUM_PAIRS_API_URL}/${pairId}/move`, payload);

export const upload_image_gl_ba_album_pair = async (pairId: string, position: UploadImagePosition, payload: FormData) =>
  await apiClient.post<GLbeforeAfterAlbumPair>(`${BA_GL_ALBUM_PAIRS_API_URL}/${pairId}/image/${position}`, payload, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

export const delete_image_gl_ba_album_pair = async (pairId: string, position: UploadImagePosition) =>
  await apiClient.delete(`${BA_GL_ALBUM_PAIRS_API_URL}/${pairId}/image/${position}`);
