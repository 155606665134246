import { FC, memo } from 'react';

import { Stack, TableCell, TableRow, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { Icon } from 'components/Icon';
import { ThreeDotsTableCell } from 'components/Table';
import { UserRoleChip } from 'components/UserRoleChip';
import { CREATED_AT_DATE_FORMAT } from 'constants/dateFormats';
import { ActionDataInitialState } from 'hooks/useActionDialogManagement';
import { usePopover } from 'hooks/usePopover';
import { UserRoles } from 'types/enums';
import { dateToCustomFormat } from 'utils/helpers';

type UserTableRowProps = {
  email?: string;
  middleName?: string;
  firstName: string;
  lastName: string;
  userRole: UserRoles;
  createdAt: string;
  id: string;
  isActive: boolean;
  onClickDeleteMenuItemHandler: (data: { name: string; id: string }) => void;
  onClickActivationOptionHandler: (activationStateData: ActionDataInitialState) => void;
  isDotsButton: boolean;
};

export const UserTableRow: FC<UserTableRowProps> = memo(
  ({
    firstName,
    lastName,
    userRole,
    email,
    middleName,
    createdAt,
    id,
    isActive,
    isDotsButton,
    onClickDeleteMenuItemHandler,
    onClickActivationOptionHandler,
  }) => {
    const name = `${firstName} ${middleName || ''} ${lastName}`;
    const navigate = useNavigate();

    const onClickRowHandler = () => navigate(`/users/${id}`);

    const { handleClosePopover, handleOpenPopover, openPopover } = usePopover();

    const onCloseThreeDotsMenuHandler = (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      handleClosePopover();
    };

    const onClickThreeDotsMenuButtonHandler = (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      handleOpenPopover(event);
    };

    const onClickDeleteButtonHandler = (event: React.MouseEvent<HTMLLIElement>) => {
      event.stopPropagation();

      handleClosePopover();
      onClickDeleteMenuItemHandler({ name, id });
    };

    const onClickActivityButtonHandler = (event: React.MouseEvent<HTMLLIElement>) => {
      event.stopPropagation();

      handleClosePopover();

      onClickActivationOptionHandler({
        id,
        name: `${firstName} ${lastName}`,
        actionType: isActive ? 'deactivate' : 'activate',
      });
    };

    const formattedCreatedAt = dateToCustomFormat(createdAt, CREATED_AT_DATE_FORMAT);

    return (
      <TableRow
        onClick={onClickRowHandler}
        sx={{
          ...(!isActive && { opacity: '40%' }),
        }}
      >
        <TableCell>
          <Stack maxWidth={300}>
            <Typography variant="body2" fontWeight={500}>
              {name}
            </Typography>
            <Typography variant="body2">{email}</Typography>
          </Stack>
        </TableCell>

        <TableCell>
          <UserRoleChip userRole={userRole} />
        </TableCell>

        <TableCell>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Icon type="calendar" />

            <Typography variant="body2">{formattedCreatedAt}</Typography>
          </Stack>
        </TableCell>

        {isDotsButton && (
          <ThreeDotsTableCell
            open={openPopover}
            onClickButtonHandler={onClickThreeDotsMenuButtonHandler}
            onClose={onCloseThreeDotsMenuHandler}
            onClickDeleteMenuItemHandler={onClickDeleteButtonHandler}
            menuItems={[
              {
                title: isActive ? 'Deactivate' : 'Activate',
                onClick: onClickActivityButtonHandler,
              },
            ]}
          />
        )}
      </TableRow>
    );
  }
);
