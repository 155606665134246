import { FC, memo, useCallback } from 'react';

import { Box, Divider, Grid, Stack } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { FormHeader } from 'components/Form';
import { RHFAutocompleteField, RHFPhoneTextField, RHFTextField } from 'components/HookForm';

import { DERMATOLOGICAL_ASSESSMENT_ID } from '../../constants';
import { EditMedicalFormSchema } from './form.config';
import { ProfileContactCardProps } from './types';

export const DermatologicalAssessmentCard: FC<ProfileContactCardProps> = memo(({ cardRef }) => {
  const {
    register,
    formState: { errors },
    control,
    watch,
    setValue,
  } = useFormContext<EditMedicalFormSchema>();

  return (
    <>
      {/* TODO: BE not ready */}
      {/*<Stack ref={cardRef} id={DERMATOLOGICAL_ASSESSMENT_ID} sx={{ p: { xs: 2, sm: 2.5, md: 3, lg: 3.5 } }}>*/}
      {/*  <FormHeader isDivider={false} title="Dermatological Assessment" />*/}
      {/*  <Box mt={1.5}>*/}
      {/*    <Grid container spacing={2} direction="column">*/}
      {/*      <Grid container item spacing={2}>*/}
      {/*        <Grid item xs={12} lg={4}>*/}
      {/*          <RHFAutocompleteField*/}
      {/*            // multiple*/}
      {/*            label="Skin Type (Fitzpatrick scale)"*/}
      {/*            control={control}*/}
      {/*            name="patientCard.bioGender"*/}
      {/*            placeholder="Select Skin Type"*/}
      {/*            options={[*/}
      {/*              {*/}
      {/*                name: 'name1',*/}
      {/*                value: '1',*/}
      {/*              },*/}
      {/*              {*/}
      {/*                name: 'name2',*/}
      {/*                value: '2',*/}
      {/*              },*/}
      {/*            ]}*/}
      {/*            // @ts-ignore*/}
      {/*            getOptionLabel={option => (*/}
      {/*              <span*/}
      {/*                style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '6px' }}*/}
      {/*                key={typeof option === 'string' ? option : option.name}*/}
      {/*              >*/}
      {/*                <span*/}
      {/*                  style={{*/}
      {/*                    width: '16px',*/}
      {/*                    height: '16px',*/}
      {/*                    borderRadius: '50%',*/}
      {/*                    border: '2px solid #EAECF0',*/}
      {/*                    background: '#F1D6B4',*/}
      {/*                  }}*/}
      {/*                />*/}
      {/*                {typeof option === 'string' ? option : option.name}*/}
      {/*              </span>*/}
      {/*            )}*/}
      {/*            // getOptionLabel={option => (typeof option === 'string' ? option : option.name)}*/}
      {/*            isOptionEqualToValue={(a, b) => {*/}
      {/*              return a.name === (b as unknown as HTMLSpanElement).textContent;*/}
      {/*            }}*/}
      {/*            loading={false}*/}
      {/*          />*/}
      {/*        </Grid>*/}

      {/*        <Grid item xs={12} lg={4}>*/}
      {/*          <RHFAutocompleteField*/}
      {/*            multiple*/}
      {/*            label="Sun Exposure"*/}
      {/*            control={control}*/}
      {/*            name="patientCard.bioGender"*/}
      {/*            placeholder="Select Sun Exposure"*/}
      {/*            options={[]}*/}
      {/*            getOptionLabel={() => ''}*/}
      {/*            isOptionEqualToValue={() => false}*/}
      {/*            loading={false}*/}
      {/*          />*/}
      {/*        </Grid>*/}
      {/*      </Grid>*/}
      {/*    </Grid>*/}
      {/*  </Box>*/}
      {/*</Stack>*/}
      {/*<Divider />*/}
    </>
  );
});
