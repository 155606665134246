import { FC } from 'react';

import { CardContent, CardContentProps, Skeleton, Stack } from '@mui/material';

type SideNavCardSkeletonProps = CardContentProps;

export const SideNavCardSkeleton: FC<SideNavCardSkeletonProps> = props => {
  return (
    <CardContent
      sx={({ borders, breakpoints }) => ({
        pt: 2,
        height: 1,
        borderRight: `${borders.borderWidth[1]} solid ${borders.borderColor}`,
        borderBottom: 0,
        [breakpoints.down('md')]: {
          borderRight: 0,
          borderBottom: `${borders.borderWidth[1]} solid ${borders.borderColor} `,
        },
      })}
      {...props}
    >
      <Stack spacing={1}>
        <Skeleton variant="rounded" sx={{ minHeight: 20, width: 1 }} />

        <Skeleton variant="rounded" sx={{ minHeight: 20, width: '70%' }} />

        <Skeleton variant="rounded" sx={{ minHeight: 20, width: '50%' }} />
      </Stack>
    </CardContent>
  );
};
