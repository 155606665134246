import { BASE_EXPERTS_URL, BASE_PRACTICE_OFFICE_URL } from 'apiServices/api.routes';
import { apiClient } from 'lib/api';

import {
  CreateExpertPayload,
  ExpertListItem,
  GetExpertByIdParams,
  GetExpertsParams,
  UpdateExpertPayload,
} from './types';

export const getExperts = async (params: GetExpertsParams) =>
  await apiClient.get<ListOfItems<ExpertListItem>>(BASE_EXPERTS_URL, { params });

export const createExpert = async (payload: CreateExpertPayload) =>
  await apiClient.post<ExpertListItem>(BASE_EXPERTS_URL, payload);

export const getExpert = async (expertId: string, params?: GetExpertByIdParams) =>
  await apiClient.get<ExpertListItem>(`${BASE_EXPERTS_URL}/${expertId}`, { params });

export const updateExpert = async (expertId: string, payload: UpdateExpertPayload) =>
  await apiClient.patch<ExpertListItem>(`${BASE_EXPERTS_URL}/${expertId}`, payload);

export const deleteExpert = async (expertId: string) => await apiClient.delete(`${BASE_EXPERTS_URL}/${expertId}`);

export const addExpertToOffice = async (expertId: string, officeId: string) =>
  await apiClient.put<ExpertListItem>(`${BASE_PRACTICE_OFFICE_URL}/${officeId}/experts/${expertId}`);

export const activateExpert = async (expertId: string) =>
  await apiClient.patch<ExpertListItem>(`${BASE_EXPERTS_URL}/${expertId}/activate`);

export const deactivateExpert = async (expertId: string) =>
  await apiClient.patch<ExpertListItem>(`${BASE_EXPERTS_URL}/${expertId}/deactivate`);

export const deleteExpertFromOffice = async (expertId: string, officeId: string) =>
  await apiClient.delete(`${BASE_PRACTICE_OFFICE_URL}/${officeId}/experts/${expertId}`);

export const uploadExpertAvatar = async (expertId: string, payload: FormData) =>
  await apiClient.post<ExpertListItem>(`${BASE_EXPERTS_URL}/${expertId}/avatar`, payload, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

export const deleteExpertAvatar = async (expertId: string) =>
  await apiClient.delete<ExpertListItem>(`${BASE_EXPERTS_URL}/${expertId}/avatar`);
