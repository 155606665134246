import { FC } from 'react';

import { Box, PopoverOrigin } from '@mui/material';
import PopupState, { bindHover, bindPopover } from 'material-ui-popup-state';
import HoverPopover from 'material-ui-popup-state/HoverPopover';

import { StyledArrow } from './MenuPopover.styles';
import { getPosition } from './getPosition';
import { HoverMenuPopoverProps } from './types';

export const HoverMenuPopover: FC<HoverMenuPopoverProps> = ({
  children,
  renderItem,
  arrow = 'top-right',
  disabledArrow,
}) => {
  const { style, anchorOrigin, transformOrigin } = getPosition(arrow, disabledArrow);

  return (
    <PopupState variant="popover" popupId="demoPopover">
      {popupState => (
        <Box display="flex">
          {renderItem(bindHover(popupState))}
          <HoverPopover
            {...bindPopover(popupState)}
            onClick={e => e.stopPropagation()}
            anchorOrigin={anchorOrigin as PopoverOrigin}
            transformOrigin={transformOrigin as PopoverOrigin}
            slotProps={{
              paper: {
                sx: {
                  pointerEvents: 'none',
                  overflow: 'inherit',
                  background: 'white',
                  border: theme => `1px solid ${theme.borders.borderColor}`,
                  ...style,
                },
              },
            }}
          >
            {!disabledArrow && <StyledArrow arrow={arrow} />}
            {children}
          </HoverPopover>
        </Box>
      )}
    </PopupState>
  );
};
