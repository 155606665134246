import { useCallback, useMemo } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  Patient,
  UpdatePatientCardPayload,
  putPatientHomeAddress,
  updatePatient,
  updatePatientCard,
  updatePatientContacts,
} from 'apiServices';
import { AxiosResponse } from 'axios';
import { Resolver, useForm } from 'react-hook-form';
import { useInView } from 'react-intersection-observer';
import { useAppStore } from 'store';
import { shallow } from 'zustand/shallow';

import { ToastType, notice } from 'components/ToastNotification';
import { useSideNavScrollTracker } from 'hooks';
import useResponsive from 'hooks/useResponsive';
import { useRouter } from 'hooks/useRouter';
import { formErrorHandler } from 'utils/errorHanders';
import { dateToCustomFormat } from 'utils/helpers';

import { EditMedicalFormSchema, editPatientValidationSchema } from './components';
import { GENERAL_ID } from './constants';

type UpdateMedicalPayload = Pick<Patient, 'birthDate' | 'gender' | 'ssn' | 'ethnicGroup'> & {
  person: Omit<Person, 'id'>;
};

type UpdatePatientAddressPayload = Pick<
  Patient['contact']['billingAddress'],
  'cityId' | 'countryIsoCode' | 'stateIsoCode'
>;

type UpdatePatientContactPayload = Pick<Patient['contact'], 'email' | 'phone' | 'website'>;

export const useMedicalState = () => {
  const { params } = useRouter();

  const patientId = params?.id;

  const { patient, fetchPatient } = useAppStore(
    store => ({
      patient: store.patient,
      // eslint-disable-next-line react-hooks/rules-of-hooks
      fetchPatient: useCallback(store.fetchPatient, []),
    }),
    shallow
  );

  const { contact, patientCard } = patient || {};

  const formMethods = useForm<EditMedicalFormSchema>({
    resolver: yupResolver(editPatientValidationSchema) as Resolver<EditMedicalFormSchema>,
    mode: 'onTouched',
    values: {
      birthDate: patient?.birthDate,
      ethnicGroup: patient?.ethnicGroup,
      gender: patient?.gender,
      person: patient?.person,
      ssn: patient?.ssn,
      contact: {
        email: contact?.email,
        website: contact?.website,
        phone: contact?.phone,
        billingAddress: contact?.billingAddress,
        homeAddress: contact?.homeAddress,
      },
      patientCard: {
        painTolerance: patientCard?.painTolerance,
        bloodType: patientCard?.bloodType,
        bioGender: patientCard?.bioGender,
        rag: patientCard?.rag?.data,
        smoking: patientCard?.smoking,
        alcoholConsumption: patientCard?.alcoholConsumption,
        customConsultationWorkflow: patientCard?.customConsultationWorkflow,
      },
    },
  });

  const {
    formState: { isDirty, isSubmitting, isValid, dirtyFields },
    setError,
    handleSubmit,
    reset,
  } = formMethods;

  const isSubmitButtonDisabled = isSubmitting || !isDirty || !isValid;

  const onFormSubmitHandler = handleSubmit(
    async ({
      birthDate,
      contact,
      ethnicGroup,
      gender,
      patientCard: { bioGender, painTolerance, bloodType, rag, smoking, alcoholConsumption },
      person,
      ssn,
    }) => {
      const promise = [
        onUpdatePatientProfileInfoHandler({
          birthDate: dateToCustomFormat(birthDate),
          gender,
          person,
          ssn,
          ethnicGroup,
        }),
        onUpdatePatientHomeAddressHandler(contact.homeAddress),
        onUpdatePatientContactHandler({ email: contact.email, phone: contact.phone, website: contact.website }),
        onUpdatePatientCardHandler({
          bioGender,
          bloodType,
          painTolerance,
          rag: rag ? { data: rag } : {},
          smoking,
          alcoholConsumption,
        }),
      ];

      try {
        await Promise.all(promise);
        await fetchPatient(patientId);
        reset();
        notice(ToastType.SUCCESS, 'Patient profile has been successfully updated!');
      } catch (error) {}
    }
  );

  const createUpdateHandler = async <TPayload>({
    errorMessage,
    isDirtyFields,
    payload,
    updateFunction,
  }: {
    isDirtyFields: boolean;
    updateFunction: (id: string, payload: TPayload) => Promise<AxiosResponse<Patient>>;
    payload: TPayload;
    errorMessage: string;
  }) => {
    if (isDirtyFields) {
      try {
        await updateFunction(patientId, payload);
      } catch (error) {
        console.error(error);
        formErrorHandler({
          error,
          customErrorMessage: errorMessage,
          config: { formError: { setError } },
        });
        throw error;
      }
    }
  };

  const onUpdatePatientProfileInfoHandler = async (payload: UpdateMedicalPayload) => {
    const isDirty =
      dirtyFields.person || dirtyFields.birthDate || dirtyFields.ethnicGroup || dirtyFields.gender || dirtyFields.ssn;

    await createUpdateHandler({
      errorMessage: 'Failed to update patient profile, please try again!',
      isDirtyFields: !!isDirty,
      payload,
      updateFunction: updatePatient,
    });
  };

  const onUpdatePatientContactHandler = async (payload: UpdatePatientContactPayload) => {
    const isDirtyFields = dirtyFields.contact?.email || dirtyFields.contact?.phone || dirtyFields.contact?.website;

    createUpdateHandler({
      errorMessage: 'Failed to update patient contacts, please try again!',
      isDirtyFields: !!isDirtyFields,
      payload,
      updateFunction: updatePatientContacts,
    });
  };

  const onUpdatePatientHomeAddressHandler = async (payload: UpdatePatientAddressPayload) => {
    createUpdateHandler({
      errorMessage: 'Failed to update patient home address, please try again!',
      isDirtyFields: !!dirtyFields.contact?.homeAddress,
      payload,
      updateFunction: putPatientHomeAddress,
    });
  };

  const onUpdatePatientCardHandler = async (payload: UpdatePatientCardPayload) => {
    const isDirty =
      dirtyFields.patientCard?.bloodType ||
      dirtyFields.patientCard?.painTolerance ||
      dirtyFields.patientCard?.bioGender ||
      dirtyFields.patientCard?.smoking ||
      dirtyFields.patientCard?.alcoholConsumption ||
      dirtyFields?.patientCard?.rag;

    createUpdateHandler({
      errorMessage: 'Failed to update patient card, please try again!',
      isDirtyFields: !!isDirty,
      payload,
      updateFunction: updatePatientCard,
    });
  };

  const isSmallScreen = useResponsive('down', 'sm');

  const { activeSectionId, executeScroll, onChangeInViewHandler } = useSideNavScrollTracker({
    defaultActiveSectionId: GENERAL_ID,
  });

  const { ref: generalRef, entry: generalEntry } = useInView({
    threshold: isSmallScreen ? 0.5 : 1,
    initialInView: true,
    onChange: onChangeInViewHandler,
  });

  const { ref: dermatologicalAssessmentRef, entry: dermatologicalAssessmentEntry } = useInView({
    threshold: 1,
    onChange: onChangeInViewHandler,
  });
  const { ref: bodyMeasurementsRef, entry: bodyMeasurementsEntry } = useInView({
    threshold: 1,
    onChange: onChangeInViewHandler,
  });

  const { ref: lifestyleRef, entry: lifestyleEntry } = useInView({
    threshold: 1,
    onChange: onChangeInViewHandler,
  });

  const { ref: allergiesRef, entry: allergiesEntry } = useInView({
    threshold: 1,
    onChange: onChangeInViewHandler,
  });

  const { ref: medicalConditionsRef, entry: medicalConditionsEntry } = useInView({
    threshold: 1,
    onChange: onChangeInViewHandler,
  });

  const { ref: medicinesRef, entry: medicinesEntry } = useInView({
    threshold: 1,
    onChange: onChangeInViewHandler,
  });

  const refs = useMemo(
    () => [
      generalRef,
      dermatologicalAssessmentRef,
      bodyMeasurementsRef,
      lifestyleRef,
      allergiesRef,
      medicalConditionsRef,
      medicinesRef,
    ],
    []
  );

  return {
    formMethods,
    executeScroll,
    bodyMeasurementsEntry,
    medicalConditionsEntry,
    medicinesEntry,
    dermatologicalAssessmentEntry,
    lifestyleEntry,
    allergiesEntry,
    activeSectionId,
    generalEntry,
    refs,
    isSubmitButtonDisabled,
    onFormSubmitHandler,
    isSubmitting,
    patientId,
    fetchPatient,
    patientCard,
    isDirty,
  };
};
