import { FC, memo, useState } from 'react';

import { Box, Divider, Grid, Stack } from '@mui/material';
import { deletePatientMedicalCondition } from 'apiServices';
import { useFormContext } from 'react-hook-form';

import { FormHeader } from 'components/Form';
import { Icon } from 'components/Icon';
import { PopoverMenuItem } from 'components/MenuPopover';
import { SelectorPopupMedical } from 'components/SelectorPopupMedical/SelectorPopupMedical';
import { ToastType, notice } from 'components/ToastNotification';
import { backendErrorHandler } from 'utils/errorHanders';

import { Diagnosis } from '../../../../TabContentComponents';
import { MEDICAL_CONDITIONS_ID } from '../../constants';
import { EditMedicalFormSchema } from './form.config';
import { MedicalConditionsCardProps } from './types';

export const MedicalConditionsCard: FC<MedicalConditionsCardProps> = memo(
  ({ cardRef, medicalConditions, patientId, fetchPatient }) => {
    // TODO: BE not ready
    const {
      register,
      formState: { errors },
      control,
    } = useFormContext<EditMedicalFormSchema>();

    const [anchor, setAnchor] = useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchor(anchor ? null : event.currentTarget);
    };

    const open = Boolean(anchor);

    const onDeleteMedicalConditionHandler = async (selectedItemId: string) => {
      try {
        await deletePatientMedicalCondition(patientId, selectedItemId);
        await fetchPatient(patientId);

        notice(ToastType.SUCCESS, 'Medical conditions have been successfully removed!');
      } catch (error) {
        console.error(error);
        backendErrorHandler({
          error,
          config: { customErrorMessage: 'Failed to remove medical conditions, please try again!' },
        });
      }
    };

    return (
      <>
        <Stack ref={cardRef} id={MEDICAL_CONDITIONS_ID} sx={{ p: { xs: 2, sm: 2.5, md: 3, lg: 3.5 } }}>
          <FormHeader isDivider={false} title="Medical Conditions" />
          <Box mt={1.5}>
            <Grid container spacing={2} direction="column">
              <Grid container item spacing={2}>
                <Grid container item>
                  <Grid item xs={12} lg={10} display="flex" justifyContent="space-between" alignItems="center">
                    <PopoverMenuItem
                      sx={{ display: 'flex', justifyContent: 'space-between' }}
                      iconEnd={<Icon type="add" />}
                      onClick={handleClick}
                      title="Add condition"
                      color="text.secondary"
                    />
                    <SelectorPopupMedical
                      isMedicalConditions={true}
                      fetchPatient={fetchPatient}
                      patientId={patientId}
                      anchor={anchor}
                      isOpened={open}
                      onClose={() => setAnchor(null)}
                    />
                  </Grid>
                  <Grid item xs={12} lg={10}>
                    {medicalConditions?.map(medicalCondition => {
                      return (
                        <Diagnosis
                          title={medicalCondition?.medicalCondition?.description}
                          hardness={2}
                          id={medicalCondition?.medicalConditionIcd10Code}
                          context={{
                            onCopy: () => {},
                            onDelete: async () => {
                              await onDeleteMedicalConditionHandler(medicalCondition?.id);
                            },
                            onEdit: () => {},
                          }}
                          notes={[
                            {
                              noteAuthor: 'Michael Lee',
                              noteDate: 'Jul 1 at 2:37 pm',
                              note: 'For example, symptoms include sneezing and itchy nose. I avoid outdoor activities during peak pollen season',
                            },
                          ]}
                          mt={10}
                        />
                      );
                    })}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Stack>
        <Divider />
      </>
    );
  }
);
