import { useEffect, useMemo, useState } from 'react';

import {
  GlobalLibrarySearchParams,
  TagListSortFieldName,
  deletePracticeLibraryTagCategory,
  getPracticeLibraryTagCategories,
  updatePracticeLibraryTagCategory,
} from 'apiServices';
import { useAppStore } from 'store';
import { shallow } from 'zustand/shallow';

import { ToastType, notice } from 'components/ToastNotification';
import { useUserProfile } from 'hooks';
import { useActionDialogManagement } from 'hooks/useActionDialogManagement';
import { useBoolean } from 'hooks/useBoolean';
import { useDebounce } from 'hooks/useDebounce';
import { usePopover } from 'hooks/usePopover';
import { useTable } from 'hooks/useTable';
import { FiltersInitialState } from 'pages/TagManagement';
import { FiltersInitialStateValue } from 'pages/TagManagement/config';
import { backendErrorHandler } from 'utils/errorHanders';
import { checkIsFilterApplied } from 'utils/helpers';

export const usePracticeLibraryTagListState = () => {
  const {
    practiceLibraryTagCategories: { data, total },
    setPracticeLibraryTagCategories,
  } = useAppStore(
    store => ({
      practiceLibraryTagCategories: store.practiceLibraryTagCategories,
      setPracticeLibraryTagCategories: store.setPracticeLibraryTagCategories,
    }),
    shallow
  );

  const { page, rowsPerPage, onChangeRowsPerPage, setPage, isResettingPage, resetPageHandler, onSort, order, orderBy } =
    useTable<TagListSortFieldName>({ defaultOrderBy: 'name', defaultOrder: 'asc' });

  const [searchValue, setSearchValue] = useState('');

  const onChangeSearchInputValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (page !== 1) setPage(1);

    setSearchValue(event.target.value);
  };

  const [appliedFilters, setAppliedFilters] = useState<FiltersInitialState>(FiltersInitialStateValue);

  const debouncedSearchValue = useDebounce(searchValue, 200);

  const {
    handleClosePopover: closeFiltersMenu,
    handleOpenPopover: openFilterMenu,
    openPopover: isFiltersMenuOpen,
  } = usePopover();

  const [isAddTagCategoryDialogOpen, openAddTagCategoryDialog, closeAddTagCategoryDialog] = useBoolean();

  const [isLoading, setIsLoading] = useState(true);

  const onGetTagCategoriesHandler = async ({
    params,
    isResetPage,
  }: {
    params?: GlobalLibrarySearchParams;
    isResetPage?: boolean;
  }) => {
    !isLoading && setIsLoading(true);
    try {
      const { data } = await getPracticeLibraryTagCategories({
        ...(debouncedSearchValue && { search: debouncedSearchValue }),
        size: rowsPerPage,
        order: [`${orderBy},${order}`],
        page,
        is_active: appliedFilters.isShowDeactivated ? false : true,
        ...params,
      });

      setPracticeLibraryTagCategories({
        data: data.items,
        page: data.page,
        pages: data.pages,
        size: data.size,
        total: data.total,
      });

      if (isResetPage && page !== 1) setPage(1);
    } catch (error) {
      console.error(error);

      backendErrorHandler({ error, config: { customErrorMessage: 'Failed to get tag categories, please try again!' } });
    } finally {
      setIsLoading(false);
    }
  };

  const onChangePageHandler = async (e: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    const newPage = page + 1;
    setPage(newPage);
    await onGetTagCategoriesHandler({ params: { page: newPage } });
  };

  const {
    setIsActionPending,
    isActionPending,
    isActivateDialogOpen,
    isDeactivateDialogOpen,
    closeActivateDialog,
    closeDeactivateDialog,
    onClickActionOptionHandler,
    actionState,
    isOpenDeleteDialogWindow,
    closeDeleteDialogWindow,
  } = useActionDialogManagement();

  const onDeactivateTagCategoryHandler = async (event: React.MouseEvent<HTMLElement>) => {
    setIsActionPending(true);
    const { id } = actionState;
    try {
      await deletePracticeLibraryTagCategory(id);

      notice(ToastType.SUCCESS, 'Tag category has been successfully inactivated!');

      await onGetTagCategoriesHandler({ isResetPage: true, params: { page: 1 } });

      closeDeactivateDialog();
    } catch (error) {
      backendErrorHandler({
        error,
        config: {
          customErrorMessage: 'Failed to inactivate tag category, please try again!',
        },
      });
    } finally {
      setIsActionPending(false);
    }
  };

  const onActivateTagCategoryHandler = async (event: React.MouseEvent<HTMLElement>) => {
    setIsActionPending(true);
    const { id } = actionState;
    try {
      await updatePracticeLibraryTagCategory(id, { isActive: true });

      notice(ToastType.SUCCESS, 'Tag category has been successfully activated!');

      await onGetTagCategoriesHandler({ isResetPage: true, params: { page: 1 } });

      closeActivateDialog();
    } catch (error) {
      backendErrorHandler({
        error,
        config: {
          customErrorMessage: 'Failed to activate tag category, please try again!',
        },
      });
    } finally {
      setIsActionPending(false);
    }
  };

  useEffect(() => {
    onGetTagCategoriesHandler({ params: { ...(isResettingPage.current && { page: 1 }) } });

    resetPageHandler();
  }, [debouncedSearchValue, appliedFilters, rowsPerPage, order, orderBy]);

  const onApplyFilters = (newFilters: FiltersInitialState) => {
    setAppliedFilters(newFilters);
    isResettingPage.current = true;
    closeFiltersMenu();
  };

  const isFiltersApplied = useMemo(
    () => checkIsFilterApplied(FiltersInitialStateValue, appliedFilters),
    [appliedFilters]
  );

  const { isRoleAdmin } = useUserProfile();

  return {
    searchValue,
    onChangeSearchInputValue,
    isAddTagCategoryDialogOpen,
    openAddTagCategoryDialog,
    closeAddTagCategoryDialog,
    isOpenDeleteDialogWindow,
    closeDeleteDialogWindow,
    closeFiltersMenu,
    openFilterMenu,
    isFiltersMenuOpen,
    data,
    isLoading,
    total,
    onChangeRowsPerPage,
    rowsPerPage,
    onGetTagCategoriesHandler,
    onChangePageHandler,
    page,
    appliedFilters,
    onActivateTagCategoryHandler,
    isActionPending,
    onDeactivateTagCategoryHandler,
    onClickActionOptionHandler,
    isDeactivateDialogOpen,
    isActivateDialogOpen,
    closeDeactivateDialog,
    closeActivateDialog,
    actionState,
    onApplyFilters,
    onSort,
    order,
    orderBy,
    isFiltersApplied,
    isRoleAdmin,
  };
};
