import { FC, useState } from 'react';

import { Box, Card, CardContent, CardHeader, Divider, Grid } from '@mui/material';
import { Patient } from 'apiServices/patient/types';
import { Practice } from 'apiServices/practice/types';

import { MDTabs } from 'components/MDTabs';
import { useRouter } from 'hooks/useRouter';
import { CreateOrUpdatePrompt } from 'pages/PromptLibrary/CreatePrompt/CreatePrompt';

import { EvaluationHistoryTabContent } from './EvaluationHistoryTabContent';
import { TestingTabContent } from './TestingTabContent';
import { TABS } from './config';
import { usePromptViewState } from './usePromptView.state';

export const PromptView: FC = () => {
  const { params, state } = useRouter();
  const { handleSetTabValue, tabValue } = usePromptViewState();
  const [patient, setPatient] = useState<Patient | null>(null);
  const [practice, setPractice] = useState<Practice | null>(null);

  return (
    <Box component="section" flex={1} display="flex">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card sx={{ height: 1 }}>
            <CardHeader
              title={
                <Box sx={{ maxWidth: { xs: 1, sm: 400 }, width: 1, mt: 1 }}>
                  <MDTabs value={tabValue} tabsData={TABS} handleSetTabValue={handleSetTabValue} />
                </Box>
              }
              sx={{ py: 0 }}
            />
            {tabValue === 0 ? (
              <CardContent sx={{ pt: 0, height: 1, display: 'flex', flexDirection: 'column' }}>
                <Divider sx={{ mb: 0 }} />
                <TestingTabContent
                  //   aiModels={''}
                  setPatient={setPatient}
                  setPractice={setPractice}
                  selectedPatient={patient}
                  selectedPractice={practice}
                />
              </CardContent>
            ) : tabValue === 1 ? (
              <EvaluationHistoryTabContent promptId={params.id} />
            ) : (
              <CreateOrUpdatePrompt
                mode="update"
                promptId={params.id}
                initialData={{
                  name: state.breadCrumbName,
                  text: state?.text,
                  comment: state.comment,
                }}
              />
            )}
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};
