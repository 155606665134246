import { useCallback } from 'react';

import { Allergy, DictionaryCityItem, DictionaryItem } from 'apiServices';

export const useMemoizedAutocompleteFunctions = () => {
  const memoizedGetOptionLabel = useCallback((option: BaseEnum | string) => {
    if (typeof option === 'string') return option;
    return String(option.value);
  }, []);

  const memoizedIsOptionEqualToValue = useCallback(
    (option: BaseEnum, value: BaseEnum) => String(option?.value) === String(value?.value),
    []
  );

  const memoizedAddressGetOptionLabel = useCallback((option: string | DictionaryItem) => {
    if (typeof option === 'string') return option;
    return option.isoName;
  }, []);

  const memoizedAddressIsOptionEqualToValue = useCallback(
    (option: DictionaryItem, value: DictionaryItem) => option?.isoCode === value?.isoCode,
    []
  );

  const memoizedCityGetOptionLabel = useCallback((option: string | DictionaryCityItem) => {
    if (typeof option === 'string') return option;
    return option.name;
  }, []);

  const memoizedCityIsOptionEqualToValue = useCallback(
    (option: DictionaryCityItem, value: DictionaryCityItem) => option?.name === value?.name,
    []
  );

  const memoizedAllegryEqualToValue = useCallback((option: Allergy, value: Allergy) => option?.id === value?.id, []);

  return {
    memoizedGetOptionLabel,
    memoizedIsOptionEqualToValue,
    memoizedAddressGetOptionLabel,
    memoizedAddressIsOptionEqualToValue,
    memoizedCityGetOptionLabel,
    memoizedCityIsOptionEqualToValue,
    memoizedAllegryEqualToValue,
  };
};
