import { FC, memo, useCallback } from 'react';

import { Stack } from '@mui/material';
import { DictionaryItem } from 'apiServices';
import { useFormContext } from 'react-hook-form';

import { FormHeader } from 'components/Form';
import { RHFAutocompleteField, RHFPhoneTextField, RHFTextField } from 'components/HookForm';
import { useGeoData } from 'hooks/useGeoData';

import { CONTACT_ID } from '../../constants';
import { EditPatientFormSchema } from './form.config';
import { AutocompleteOption, ProfileContactCardProps } from './types';

export const PatientContactCard: FC<ProfileContactCardProps> = memo(
  ({
    cardRef,
    memoizedCityGetOptionLabel,
    memoizedCityIsOptionEqualToValue,
    memoizedAddressGetOptionLabel,
    memoizedAddressIsOptionEqualToValue,
  }) => {
    const {
      register,
      formState: { errors },
      control,
      watch,
      setValue,
    } = useFormContext<EditPatientFormSchema>();

    const currentHomeAddressCountryValue = watch('contact.homeAddress.countryIsoCode');
    const currentHomeAddressStateValue = watch('contact.homeAddress.stateIsoCode');

    const currentBillingAddressCountryValue = watch('contact.billingAddress.countryIsoCode');
    const currentBillingAddressStateValue = watch('contact.billingAddress.stateIsoCode');

    const {
      countries,
      countryStates,
      onChangeCityInputValueHandler,
      onChangeCountryInputValueHandler,
      onSelectCountryHandler,
      stateCities,
      isCountriesLoading,
      isCountryStatesLoading,
      isStateCitiesLoading,
      onSelectStateHandler,
      country,
    } = useGeoData({
      currentStateISOcode: currentHomeAddressStateValue,
      currentCountryISOcode: currentHomeAddressCountryValue,
    });

    const {
      countries: billingAddressCountries,
      countryStates: billingAddressStates,
      onChangeCityInputValueHandler: onChangeBillingAddressCityInputValueHandler,
      onChangeCountryInputValueHandler: onChangeBillingAddressCountryInputValueHandler,
      onSelectCountryHandler: onSelectBillingAddressCountryHandler,
      stateCities: billingAddressCities,
      isCountriesLoading: isBillingAddressCountriesLoading,
      isCountryStatesLoading: isBillingAddressStatesLoading,
      isStateCitiesLoading: isBillingAddressCitiesLoading,
      onSelectStateHandler: onSelectBillingAddressStateHandler,
      country: billingAddressCountry,
    } = useGeoData({
      currentStateISOcode: currentBillingAddressStateValue,
      currentCountryISOcode: currentBillingAddressCountryValue,
    });

    const onChangeCountryHomeAddressAutocompleteHandler = useCallback(
      (option: AutocompleteOption<DictionaryItem>) => {
        onSelectCountryHandler(option);
        currentHomeAddressStateValue && setValue('contact.homeAddress.stateIsoCode', '');
      },
      [currentHomeAddressStateValue, setValue]
    );

    const onChangeCityHomeAddressAutocompleteHandler = useCallback(
      (option: AutocompleteOption<DictionaryItem>) => {
        onSelectStateHandler(option);
        watch('contact.homeAddress.cityId') && setValue('contact.homeAddress.cityId', '');
      },
      [watch, setValue]
    );

    const onChangeBillingAddressCountryAutocompleteHandler = useCallback(
      (option: AutocompleteOption<DictionaryItem>) => {
        onSelectBillingAddressCountryHandler(option);
        currentBillingAddressStateValue && setValue('contact.billingAddress.stateIsoCode', '');
      },
      [currentBillingAddressStateValue, onSelectBillingAddressCountryHandler, setValue]
    );

    const billingAddressCountriesOptions =
      currentBillingAddressCountryValue && billingAddressCountry && !countries?.items.length
        ? [billingAddressCountry]
        : billingAddressCountries?.items;

    const countriesOptions =
      currentHomeAddressCountryValue && country && !countries?.items.length ? [country] : countries?.items;

    return (
      <Stack ref={cardRef} id={CONTACT_ID} sx={{ p: { xs: 2, sm: 2.5, md: 3, lg: 3.5 } }}>
        <FormHeader isDivider={false} title="Contact" />

        <Stack sx={{ width: { xs: 1, lg: '66%' } }} mt={1.5} spacing={2}>
          <RHFPhoneTextField
            label="Phone number"
            control={control}
            registerErrors={errors.contact}
            registerName="contact.phone"
            placeholder="Enter phone number"
            country="us"
            MUITextFieldProps={{ fullWidth: true, sx: { width: 1 } }}
          />

          <RHFTextField
            label="Email"
            register={register}
            registerName="contact.email"
            registerErrors={errors.contact?.email?.message}
            fullWidth
            placeholder="Enter Email"
          />

          <RHFTextField
            label="Website"
            register={register}
            registerName="contact.website"
            registerErrors={errors.contact?.website?.message}
            fullWidth
            placeholder="Enter Website"
          />

          <FormHeader isDivider={false} title="Home Address" />
          <Stack spacing={2} width={1}>
            <RHFAutocompleteField
              control={control}
              name="contact.homeAddress.countryIsoCode"
              placeholder="Select Country"
              onChangeHandler={onChangeCountryHomeAddressAutocompleteHandler}
              onInputChange={onChangeCountryInputValueHandler}
              getOptionLabel={memoizedAddressGetOptionLabel}
              isOptionEqualToValue={memoizedAddressIsOptionEqualToValue}
              loading={isCountriesLoading}
              options={countriesOptions}
            />

            <RHFAutocompleteField
              control={control}
              name="contact.homeAddress.stateIsoCode"
              placeholder={!currentHomeAddressCountryValue ? 'Select Country First' : 'State'}
              onChangeHandler={onChangeCityHomeAddressAutocompleteHandler}
              disabled={!currentHomeAddressCountryValue}
              options={countryStates}
              getOptionLabel={memoizedAddressGetOptionLabel}
              isOptionEqualToValue={memoizedAddressIsOptionEqualToValue}
              loading={isCountryStatesLoading}
            />

            <RHFAutocompleteField
              control={control}
              name="contact.homeAddress.cityId"
              placeholder={!currentHomeAddressStateValue ? 'Select State First' : 'City'}
              onInputChange={onChangeCityInputValueHandler}
              disabled={!currentHomeAddressStateValue}
              options={stateCities}
              getOptionLabel={memoizedCityGetOptionLabel}
              isOptionEqualToValue={memoizedCityIsOptionEqualToValue}
              loading={isStateCitiesLoading}
            />
          </Stack>

          <FormHeader isDivider={false} title="Billing Address" />
          <Stack spacing={2} width={1}>
            <RHFAutocompleteField
              control={control}
              name="contact.billingAddress.countryIsoCode"
              placeholder="Select Country"
              onChangeHandler={onChangeBillingAddressCountryAutocompleteHandler}
              onInputChange={onChangeBillingAddressCountryInputValueHandler}
              options={billingAddressCountriesOptions}
              getOptionLabel={memoizedAddressGetOptionLabel}
              isOptionEqualToValue={memoizedAddressIsOptionEqualToValue}
              loading={isBillingAddressCountriesLoading}
            />

            <RHFAutocompleteField
              control={control}
              name="contact.billingAddress.stateIsoCode"
              placeholder={!currentBillingAddressCountryValue ? 'Select Country First' : 'State'}
              onChangeHandler={onSelectBillingAddressStateHandler}
              disabled={!currentBillingAddressCountryValue}
              loading={isBillingAddressStatesLoading}
              options={billingAddressStates}
              getOptionLabel={memoizedAddressGetOptionLabel}
              isOptionEqualToValue={memoizedAddressIsOptionEqualToValue}
            />

            <RHFAutocompleteField
              control={control}
              name="contact.billingAddress.cityId"
              placeholder={!currentBillingAddressStateValue ? 'Select State First' : 'City'}
              onInputChange={onChangeBillingAddressCityInputValueHandler}
              disabled={!currentBillingAddressStateValue}
              options={billingAddressCities}
              getOptionLabel={memoizedCityGetOptionLabel}
              isOptionEqualToValue={memoizedCityIsOptionEqualToValue}
              loading={isBillingAddressCitiesLoading}
            />
          </Stack>
        </Stack>
      </Stack>
    );
  }
);
