import { Dispatch, FC, SetStateAction, useState } from 'react';

import { Grid } from '@mui/material';
import { Patient } from 'apiServices/patient/types';
import { Practice } from 'apiServices/practice/types';

import { FooterContent } from '../components';
import { MainContent, OptionsContent } from './components';

export const TestingTabContent: FC<{
  setPatient: Dispatch<SetStateAction<Patient>>;
  setPractice: Dispatch<SetStateAction<Practice>>;
  selectedPatient: Patient;
  selectedPractice: Practice;
}> = ({ setPatient, setPractice, selectedPatient, selectedPractice }) => {
  const [responseData, setResponseData] = useState(null);

  return (
    <>
      <Grid container flex={1}>
        <Grid item xs={12} md={8} flex={1}>
          <MainContent responseData={responseData} />
        </Grid>
        <Grid item xs={12} md={4}>
          <OptionsContent
            setResponseData={setResponseData}
            responseData={responseData}
            setPatient={setPatient}
            setPractice={setPractice}
            selectedPatient={selectedPatient}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <FooterContent />
        </Grid>
      </Grid>
    </>
  );
};
