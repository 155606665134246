import { useState } from 'react';

import { ExpandMoreRounded } from '@mui/icons-material';
import { ButtonGroup } from '@mui/material';

import MDButton, { MDButtonProps } from 'components/MDButton';
import { MenuPopover, PopoverMenuItem } from 'components/MenuPopover';
import { usePopover } from 'hooks/usePopover';

type MDSelectButtonProps<TMenuItem> = MDButtonProps & {
  setButtonOption: React.Dispatch<React.SetStateAction<TMenuItem>>;
  menuItems: TMenuItem[];
};

export const MDSelectButton = <TMenuItem extends string>({
  variant,
  setButtonOption,
  menuItems = [],
  ...rest
}: MDSelectButtonProps<TMenuItem>) => {
  const { handleClosePopover, handleOpenPopover, openPopover } = usePopover();

  const [selectedMenuItem, setSelectedMenuItem] = useState<TMenuItem>(menuItems[0]);

  const onSelectMenuItem = (event: React.MouseEvent<HTMLLIElement>) => {
    const option = event.currentTarget.id as TMenuItem;

    handleClosePopover();
    setButtonOption(option);
    setSelectedMenuItem(option);
  };

  return (
    <>
      <ButtonGroup variant="contained" aria-label="Button group with a nested menu" sx={{ boxShadow: 'none' }}>
        <MDButton variant={variant} {...rest}>
          {selectedMenuItem}
        </MDButton>

        <MDButton
          size="small"
          variant={variant}
          aria-controls={openPopover ? 'split-button-menu' : undefined}
          aria-expanded={openPopover ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          sx={{ px: 1 }}
          onClick={handleOpenPopover}
        >
          <ExpandMoreRounded
            sx={{ transform: openPopover ? 'rotate(-180deg)' : 'rotate(0)', transition: 'transform 0.3s' }}
            fontSize="medium"
          />
        </MDButton>
      </ButtonGroup>

      <MenuPopover
        open={openPopover}
        arrow="bottom-right"
        onClose={handleClosePopover}
        disabledArrow
        sx={{ transform: 'translateY(-5px) !important' }}
      >
        {menuItems.map(item => (
          <PopoverMenuItem key={item} itemId={item} onClick={onSelectMenuItem} title={item} />
        ))}
      </MenuPopover>
    </>
  );
};
