import { Icon } from 'components/Icon';
import { ActionDataInitialState } from 'hooks';

import { BACardMenuItem } from './types';

export type MenuItemKey = 'add' | 'edit' | 'clone' | 'activateToggle' | 'delete';

export type ActionHandler<TParams> = (params?: TParams) => void;

interface MenuItemsProps {
  isActive: boolean;
  id: string;
  name: string;
  handlers?: Partial<Record<MenuItemKey, ActionHandler<ActionDataInitialState>>>;
}

export const getBACardMenuItems = ({ isActive, id, name, handlers = {} }: MenuItemsProps): BACardMenuItem[] => {
  const menuItems: Record<MenuItemKey, BACardMenuItem> = {
    add: {
      icon: <Icon type="add" />,
      title: 'Add before & after photos',
      onClick: () => handlers.add?.(),
    },
    edit: {
      icon: <Icon type="editPen" sx={{ svg: { height: 20, width: 24 } }} />,
      title: 'Edit',
      onClick: () => handlers.edit?.(),
    },
    clone: {
      icon: <Icon type="copy" />,
      title: 'Clone',
      onClick: () => handlers.clone?.({ id, name, actionType: 'copy' }),
    },
    activateToggle: {
      icon: <Icon type={isActive ? 'eyeClosed' : 'eyeOpened'} />,
      title: isActive ? 'Deactivate' : 'Activate',

      onClick: () =>
        handlers.activateToggle?.({
          id,
          name,
          actionType: isActive ? 'deactivate' : 'activate',
        }),
    },
    delete: {
      icon: <Icon type="trashBin" fontSize="medium" />,
      title: 'Delete',
      onClick: () => handlers.delete?.({ id, name, actionType: 'delete' }),
    },
  };

  const menuItemsKeys = Object.keys(handlers);

  return menuItemsKeys.map(itemKey => menuItems[itemKey as MenuItemKey]);
};
