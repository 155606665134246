import { FC, memo, useState } from 'react';

import { useFormContext } from 'react-hook-form';

import { EditMedicalFormSchema } from './form.config';
import { MedicinesCardProps } from './types';

export const MedicinesCard: FC<MedicinesCardProps> = memo(({ cardRef }) => {
  //TODO: waiting for BE
  const {
    register,
    formState: { errors },
    control,
  } = useFormContext<EditMedicalFormSchema>();

  const [anchor, setAnchor] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchor(anchor ? null : event.currentTarget);
  };

  const open = Boolean(anchor);

  return (
    <>
      {/* TODO: BE not ready */}
      {/*<Stack ref={cardRef} id={MEDICINES_ID} sx={{ p: { xs: 2, sm: 2.5, md: 3, lg: 3.5 } }}>*/}
      {/*  <FormHeader isDivider={false} title="Medicines" />*/}
      {/*  <Box mt={1.5}>*/}
      {/*    <Grid container spacing={2} direction="column">*/}
      {/*      <Grid container item spacing={2}>*/}
      {/*        <Grid container item>*/}
      {/*          <Grid item xs={12} lg={10} display="flex" justifyContent="space-between" alignItems="center">*/}
      {/*            <PopoverMenuItem*/}
      {/*              sx={{ display: 'flex', justifyContent: 'space-between' }}*/}
      {/*              iconEnd={<Icon type={'add'} />}*/}
      {/*              onClick={handleClick}*/}
      {/*              title={'Add medicine'}*/}
      {/*              color={'text.secondary'}*/}
      {/*            />*/}
      {/*            <SelectorPopupSecond anchor={anchor} isOpened={open} onClose={() => setAnchor(null)} />*/}
      {/*          </Grid>*/}
      {/*          <Grid item xs={12} lg={10}>*/}
      {/*            <Diagnosis*/}
      {/*              title={'Pollen'}*/}
      {/*              icon={'https://placehold.co/300x200'}*/}
      {/*              notes={[*/}
      {/*                {*/}
      {/*                  noteAuthor: 'Michael Lee',*/}
      {/*                  noteDate: 'Jul 1 at 2:37 pm',*/}
      {/*                  note: 'For example, symptoms include sneezing and itchy nose. I avoid outdoor activities during peak pollen season',*/}
      {/*                },*/}
      {/*              ]}*/}
      {/*              texts={{*/}
      {/*                'Label 1': 'Value 1',*/}
      {/*                'Label 2': 'Value 2',*/}
      {/*              }}*/}
      {/*              mt={10}*/}
      {/*            />*/}
      {/*          </Grid>*/}
      {/*        </Grid>*/}
      {/*      </Grid>*/}
      {/*    </Grid>*/}
      {/*  </Box>*/}
      {/*</Stack>*/}
      {/*<Divider />*/}
    </>
  );
});
