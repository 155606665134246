import { FC, useMemo } from 'react';

import { Grid } from '@mui/material';

import { SideNavFormMenu } from 'components/BaseSideNavMenu';
import { FormActions } from 'components/Form';
import { FormProvider } from 'components/HookForm';
import { ROUTES } from 'constants/routes';

import { EditPatientForm } from './components';
import {
  ALLERGIES_ID,
  BODY_MEASUREMENTS_ID,
  DERMATOLOGICAL_ASSESSMENT_ID,
  GENERAL_ID,
  LIFESTYLE_ID,
  MEDICAL_CONDITIONS_ID,
  MEDICINES_ID,
} from './constants';
import { useMedicalState } from './useMedical.state';

export const Medical: FC = () => {
  const {
    formMethods,
    refs,
    activeSectionId,
    bodyMeasurementsEntry,
    generalEntry,
    executeScroll,
    isSubmitButtonDisabled,
    isSubmitting,
    onFormSubmitHandler,
    patientId,
    fetchPatient,
    isDirty,
    dermatologicalAssessmentEntry,
    lifestyleEntry,
    allergiesEntry,
    medicalConditionsEntry,
    medicinesEntry,
  } = useMedicalState();

  const sidenavItems = useMemo(
    () => [
      {
        label: 'General',
        id: GENERAL_ID,
        entry: generalEntry,
      },

      // TODO: BE not ready
      // {
      //   label: 'Dermatological Assessment',
      //   id: DERMATOLOGICAL_ASSESSMENT_ID,
      //   entry: dermatologicalAssessmentEntry,
      // },
      //
      // {
      //   label: 'Body Measurements',
      //   id: BODY_MEASUREMENTS_ID,
      //   entry: bodyMeasurementsEntry,
      // },

      {
        label: 'Lifestyle',
        id: LIFESTYLE_ID,
        entry: lifestyleEntry,
      },

      {
        label: 'Allergies',
        id: ALLERGIES_ID,
        entry: allergiesEntry,
      },

      {
        label: 'Medical Conditions',
        id: MEDICAL_CONDITIONS_ID,
        entry: medicalConditionsEntry,
      },

      // TODO: BE not ready
      // {
      //   label: 'Medicines',
      //   id: MEDICINES_ID,
      //   entry: medicinesEntry,
      // },
    ],
    []
  );

  return (
    <>
      <Grid
        item
        xs={12}
        sm={2}
        sx={({ borders, breakpoints }) => ({
          borderRight: `${borders.borderWidth[1]} solid ${borders.borderColor}`,
          borderBottom: 0,
          [breakpoints.down('sm')]: {
            borderRight: 0,
            borderBottom: `${borders.borderWidth[1]} solid ${borders.borderColor} `,
          },
        })}
      >
        <SideNavFormMenu activeSectionId={activeSectionId} onClick={executeScroll} sidenavItems={sidenavItems} />
      </Grid>
      <Grid item xs={12} sm={10}>
        <FormProvider methods={formMethods} onSubmit={onFormSubmitHandler}>
          <EditPatientForm refs={refs} patientId={patientId} fetchPatient={fetchPatient} />
        </FormProvider>

        <FormActions
          isDisabled={isSubmitButtonDisabled}
          isLoading={isSubmitting}
          onFormSubmitHandler={onFormSubmitHandler}
          isDirtyForm={isDirty}
          backRoute={ROUTES.patients}
        />
      </Grid>
    </>
  );
};
