import { useState } from 'react';

import { AddOutlined, ArrowBackIosRounded, ArrowForwardIosRounded } from '@mui/icons-material';
import { Box, Button, Dialog, Grid, IconButton, Skeleton, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Keyboard, Mousewheel, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import previewImage2 from 'assets/illustrations/auth_cover.png';
import previewImage from 'assets/illustrations/before_after_card_image.png';
import before_after_1 from 'assets/mocked/before_after_1.png';
import before_after_2 from 'assets/mocked/before_after_2.png';
import before_after_3 from 'assets/mocked/before_after_3.png';
import before_after_4 from 'assets/mocked/before_after_4.png';
import before_after_5 from 'assets/mocked/before_after_5.png';
import before_after_6 from 'assets/mocked/before_after_6.png';
import rgba from 'assets/theme/functions/rgba';
import { BaseDialogWindow } from 'components/BaseDialogWindow';
import { EmptyContent } from 'components/EmptyState';
import { Icon } from 'components/Icon';
import MDButton from 'components/MDButton';
import { ROUTES } from 'constants/routes';
import { UseActionDialogManagementProps } from 'hooks';
import { useBoolean } from 'hooks/useBoolean';
import { BACardItemSkeleton } from 'pages/BeforeAfter';

import { ActionsBar } from './ActionsBar';

import 'swiper/css';

type BeforeAfterPhotosProps<TAlbumPair> = {
  data: TAlbumPair[];
  isLoading: boolean;
  renderItem: (item: TAlbumPair, index: number) => JSX.Element;
  isLoadingNext: boolean;
  checkedImagePairIdSize: number;
  onResetImagePairChekedIds: () => void;
  totalItems: number;
  openAddAlbumImagesDialogWindow: () => void;
  albumPairActionMethods: UseActionDialogManagementProps;
  deleteAlbumPairHandler: (event: React.MouseEvent<HTMLElement>) => Promise<void>;
};

const images2 = [before_after_1, before_after_3, before_after_1, before_after_6];

const caseImages = [
  [before_after_1, before_after_2, previewImage, previewImage2],
  [before_after_3, before_after_4],
  [before_after_1, before_after_5],
  [before_after_6],
];

const MOCKED_DESCRIPTION = `The aftercare instructions for CoolSculpting include the following key points based on the provided
                    sources: 1. Immediate Aftercare: - The treated areas may be red, slightly swollen, stiff, and tender
                    to the touch immediately after treatment. It is normal to experience sensations like nausea,
                    dizziness, stinging, cramping, and temporary dulling of sensation in the treated area. These
                    reactions typically resolve within a few minutes to hours post-treatment[1]. 2. Post-Treatment Care:
                    - Wear loose, comfortable clothes in the first few days after treatment. Most patients can return to
                    their normal routines with minimal discomfort. Prescription painkillers are usually not necessary,
                    and any discomfort can be.`;

export const BeforeAfterPhotos = <TAlbumPair,>({
  data,
  renderItem,
  isLoading,
  isLoadingNext,
  totalItems,
  checkedImagePairIdSize,
  albumPairActionMethods,
  deleteAlbumPairHandler,
  onResetImagePairChekedIds,
  openAddAlbumImagesDialogWindow,
}: BeforeAfterPhotosProps<TAlbumPair>) => {
  const [isDialogOpen, openDialog, closeDialog] = useBoolean();

  const [activeIndex, setActiveIndex] = useState(0);

  const [isCollapsedText, setIsCollapsedText] = useState(true);

  const onExpandDescription = () => setIsCollapsedText(prevState => !prevState);

  const beginText = MOCKED_DESCRIPTION.slice(0, 130 - 1);

  const endText = MOCKED_DESCRIPTION.slice(130 - 1);

  return (
    <>
      <Stack p={{ xs: 2, sm: 2.5, md: 3, lg: 3.5 }}>
        <Stack
          mb={2}
          direction={{ xs: 'column', sm: 'row' }}
          alignItems="center"
          justifyContent="space-between"
          gap={1}
        >
          {isLoading ? (
            <>
              <Skeleton variant="rounded" width="40%" />

              <Skeleton variant="rounded" height={35} width={150} />
            </>
          ) : (
            <>
              <Typography variant="body2" fontWeight={500}>
                {`Before & After Photos (${totalItems})`}
              </Typography>
              {openAddAlbumImagesDialogWindow && (
                <MDButton
                  variant="contained"
                  color="primary"
                  onClick={openAddAlbumImagesDialogWindow}
                  startIcon={<AddOutlined sx={{ width: 20, height: 20 }} />}
                >
                  Add Before & After
                </MDButton>
              )}
            </>
          )}
        </Stack>

        <Grid container spacing={2} position="relative" height={1}>
          {isLoading ? (
            <BACardItemSkeleton />
          ) : data.length ? (
            data.map((albumPair, index) => (
              <Grid item key={index} xs={12} sm={6} md={6} lg={3}>
                {renderItem(albumPair, index)}
              </Grid>
            ))
          ) : (
            <EmptyContent sx={{ width: 1 }} />
          )}
          {isLoadingNext && <BACardItemSkeleton />}

          <ActionsBar itemsAmount={checkedImagePairIdSize} onClickUnselectButtonHandler={onResetImagePairChekedIds} />
        </Grid>

        {/* TODO: implement fullscreenView */}
        {/* <Dialog
        slotProps={{
          backdrop: {
            sx: {
              bgcolor: 'rgba(24, 34, 48, 0.9)',
            },
          },
        }}
        PaperProps={{
          sx: {
            m: 0,
            px: 2,
            pb: 1,
            boxShadow: 'none',
            background: 'transparent',
            maxHeight: 'unset',
            justifyContent: 'center',
          },
        }}
        fullWidth
        fullScreen
        open={isDialogOpen}
        onClose={closeDialog}
      >
        <Stack
          sx={{
            backdropFilter: `blur(${isCollapsedText ? 8 : 40}px)`,
            borderBottom: theme => `${theme.borders.borderWidth[1]} solid ${theme.palette.grey[700]}`,
            color: theme => theme.palette.common.white,
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            minHeight: 60,
            zIndex: theme => theme.zIndex.drawer,
          }}
          p={1}
        >
          <Stack
            direction={{ xs: 'column-reverse', sm: 'row' }}
            alignItems="flex-start"
            justifyContent="space-between"
            gap={1}
          >
            <Stack>
              <Typography fontWeight={500} fontSize={14}>
                Facial Rejuvenation
              </Typography>
              <Typography fontWeight={400} fontSize={12}>
                {beginText}
                {
                  <>
                    {isCollapsedText && <span>... </span>}
                    <Typography variant="caption" display={isCollapsedText ? 'none' : 'block'}>
                      {endText}
                    </Typography>
                    <Typography
                      sx={{ ':hover': { cursor: 'pointer' } }}
                      onClick={onExpandDescription}
                      fontWeight={600}
                      variant="caption"
                    >
                      {isCollapsedText ? 'Read more' : 'Read less'}
                    </Typography>
                  </>
                }
              </Typography>
            </Stack>
            <Stack alignSelf={{ xs: 'center', sm: 'auto' }} direction="row" gap={1}>
              <MDButton color="dark" size="small" sx={{ minWidth: 'unset', width: 40 }}>
                <Icon type="editPen" />
              </MDButton>
              <MDButton onClick={closeDialog} color="dark" startIcon={<Icon type="closeIcon" />}>
                Close
              </MDButton>
            </Stack>
          </Stack>
        </Stack>
        <Stack maxWidth={850} width={1} mx="auto" height={1} justifyContent="center" pt={7.5} spacing={2}>
          <Box
            sx={theme => ({
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative',
              '.arrow': {
                position: 'absolute',
                top: '50%',
                transform: 'translateY(-50%)',
                zIndex: 10,
              },
              '.button-next:after, .button-prev:after': {
                content: '""',
              },
              '.button-next': {
                right: -50,
              },
              '.button-prev': {
                left: -50,
              },
              '.swiper-button-disabled': {
                display: 'none',
              },
              [theme.breakpoints.down(1001)]: {
                '.button-next': {
                  right: 0,
                },
                '.button-prev': {
                  left: 0,
                },
              },
            })}
          >
            <Box
              component={Swiper}
              display="flex"
              maxHeight={550}
              minHeight={450}
              height={1}
              width={1}
              sx={{
                overflow: 'hidden',
                position: 'relative',
                borderRadius: theme => theme.borders.borderRadius.xl,
                '.swiper-pagination-bullet': {
                  height: 2,
                  maxWidth: 40,
                  width: 1,
                  bgcolor: theme => theme.palette.common.white,
                  opacity: 0.5,
                  borderRadius: theme => theme.borders.borderRadius.section,
                },
                '.swiper-pagination-bullet-active-main': {
                  opacity: 1,
                  width: 40,
                },
                '.swiper-pagination-bullet-active-next, .swiper-pagination-bullet-active-prev, .swiper-pagination-bullet-active-prev-prev, .swiper-pagination-bullet-active-next-next':
                  {
                    transform: 'scale(1)',
                  },
              }}
              navigation={{
                nextEl: '.button-next',
                prevEl: '.button-prev',
              }}
              pagination={{ dynamicBullets: true }}
              modules={[Navigation, Pagination, Mousewheel, Keyboard]}
              grabCursor
              keyboard
              mousewheel
            >
              {caseImages[activeIndex].map((src, index) => (
                <SwiperSlide key={index}>
                  <Box
                    component="img"
                    loading="lazy"
                    src={src}
                    alt={`Slide ${index}`}
                    width={1}
                    height={1}
                    sx={{ objectFit: 'cover' }}
                  />
                  <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
                </SwiperSlide>
              ))}
            </Box>
            <IconButton
              sx={{ display: { xs: 'none', sm: 'flex', bgcolor: 'red' } }}
              color="primary"
              className="button-prev arrow"
            >
              <ArrowBackIosRounded />
            </IconButton>
            <IconButton
              sx={{ display: { xs: 'none', sm: 'flex', bgcolor: 'red' } }}
              color="primary"
              className="button-next arrow"
            >
              <ArrowForwardIosRounded />
            </IconButton>
          </Box>

          <Box
            sx={theme => ({
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative',
              '.arrow': {
                position: 'absolute',
                top: '50%',
                transform: 'translateY(-50%)',
                zIndex: 10,
              },
              '.button-thumb-prev, .button-thumb-next': {
                content: '""',
              },
              '.button-thumb-next': {
                right: -50,
              },
              '.button-thumb-prev': {
                left: -50,
              },
              [theme.breakpoints.down(1001)]: {
                '.button-thumb-next': {
                  right: 0,
                },
                '.button-thumb-prev': {
                  left: 0,
                },
              },
            })}
          >
            <Box
              component={Swiper}
              display="flex"
              width={1}
              maxHeight={90}
              height={1}
              sx={{
                overflow: 'hidden',
                position: 'relative',
                '.swiper-slide': {
                  overflow: 'hidden',
                  opacity: 0.6,
                  borderRadius: theme => theme.borders.borderRadius.lg,
                  ':hover': { cursor: 'pointer', opacity: 1 },
                },

                '.swiper-wrapper': {
                  boxSizing: 'border-box',
                },
              }}
              navigation={{
                nextEl: '.button-thumb-next',
                prevEl: '.button-thumb-prev',
              }}
              modules={[Navigation]}
              slidesPerView={2}
              breakpoints={{ 480: { slidesPerView: 4 } }}
              spaceBetween={10}
              slideToClickedSlide
              grabCursor
            >
              {images2.map((src, index) => (
                <SwiperSlide
                  onClick={e => {
                    setActiveIndex(index);
                  }}
                  key={index}
                  id={index.toString()}
                  style={{
                    ...(activeIndex === index && {
                      opacity: 1,
                      borderWidth: '2px',
                      borderStyle: 'inset',
                      borderColor: rgba(theme.palette.common.white, 0.5),
                    }),
                  }}
                >
                  <Box
                    component="img"
                    loading="lazy"
                    src={src}
                    alt={`Slide ${index}`}
                    width={1}
                    height={1}
                    sx={{
                      display: 'block',
                      width: 1,
                      height: 1,
                      objectFit: 'cover',
                    }}
                  />
                  <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
                </SwiperSlide>
              ))}
            </Box>

            <IconButton
              sx={{ display: { xs: 'none', sm: 'flex' } }}
              color="primary"
              className="button-thumb-prev arrow"
            >
              <ArrowBackIosRounded />
            </IconButton>
            <IconButton
              sx={{ display: { xs: 'none', sm: 'flex' } }}
              color="primary"
              className="button-thumb-next arrow"
            >
              <ArrowForwardIosRounded />
            </IconButton>
          </Box>
        </Stack>
      </Dialog> */}
      </Stack>
      <BaseDialogWindow
        onClickCancelButtonHandler={albumPairActionMethods.closeDeleteDialogWindow}
        open={albumPairActionMethods.isOpenDeleteDialogWindow}
        description={
          <Typography variant="body2">
            Are you sure you want to delete{' '}
            <Typography variant="body2" component="span" fontWeight={600}>
              {albumPairActionMethods.actionState.name}
            </Typography>{' '}
            Before & After? This action cannot be undone.
          </Typography>
        }
        title="Delete Before & After"
        isApproveButtonDisabled={albumPairActionMethods.isActionPending}
        isApproveButtonLoading={albumPairActionMethods.isActionPending}
        approveButtonTitle="Delete Before & After"
        onClickApproveButtonHandler={deleteAlbumPairHandler}
      />
    </>
  );
};
