import { useCallback, useState } from 'react';

import { ROUTES } from 'constants/routes';
import { useRouter } from 'hooks';
import { BUTTON_OPTIONS } from 'types/enums';

type UseFormDiscardProps = {
  forwardRoute?: ROUTES | string;
  backRoute?: ROUTES | string;
};

export const useFormNavigation = ({ forwardRoute, backRoute }: UseFormDiscardProps) => {
  const { navigate } = useRouter();

  const [buttonOption, setButtonOption] = useState<BUTTON_OPTIONS>(BUTTON_OPTIONS.SAVE_CLOSE);

  const handleFormNavigation = useCallback(() => {
    switch (buttonOption) {
      case BUTTON_OPTIONS.SAVE_CONTINUE_EDITING:
        return navigate(forwardRoute);

      default:
        return navigate(backRoute);
    }
  }, [buttonOption]);

  return {
    setButtonOption,
    handleFormNavigation,
  };
};
