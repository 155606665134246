import { yupResolver } from '@hookform/resolvers/yup';
import {
  activateGLBAalbum,
  deactivateGLBAalbum,
  deleteGLBAalbumCover,
  delete_gl_ba_album_pair,
  getGLBAalbum,
  get_gl_ba_album_pairs,
  updateGLBAalbum,
  uploadGLBAalbumCover,
} from 'apiServices';
import { useForm } from 'react-hook-form';
import { useAppStore } from 'store';
import { shallow } from 'zustand/shallow';

import { ToastType, notice } from 'components/ToastNotification';
import { useRouter, useUserProfile } from 'hooks';
import { CreateBAalbumFormSchema, createBAalbumValidationSchema } from 'pages/BeforeAfter/components';
import { formErrorHandler } from 'utils/errorHanders';

import { useAlbumProfileState } from '../useAlbumProfile.state';

export const useGLBeforeAfterAlbumProfileState = () => {
  const { params } = useRouter();

  const { isRoleAdmin } = useUserProfile();

  const albumId = params?.albumId;

  const { b_a_gl_album, set_next_b_a_album_pairs, b_a_gl_album_pairs, set_b_a_gl_album, set_b_a_album_pairs } =
    useAppStore(
      store => ({
        b_a_gl_album: store.b_a_gl_album,
        b_a_gl_album_pairs: store.b_a_gl_album_pairs,
        set_b_a_gl_album: store.set_b_a_album,
        set_b_a_album_pairs: store.set_b_a_album_pairs,
        set_next_b_a_album_pairs: store.set_next_b_a_album_pairs,
      }),
      shallow
    );

  const formMethods = useForm<CreateBAalbumFormSchema>({
    resolver: yupResolver(createBAalbumValidationSchema),
    mode: 'onTouched',
    values: { name: b_a_gl_album?.name, description: b_a_gl_album?.description || '' },
  });

  const { handleSubmit, setError } = formMethods;

  const onFormSubmitHandler = handleSubmit(async ({ name, description }) => {
    try {
      const { data } = await updateGLBAalbum(albumId, { name, ...(description && { description }) });

      notice(ToastType.SUCCESS, 'Album has been successfully edited!');

      set_b_a_gl_album({
        ...data,
        ...(b_a_gl_album.coverThumbUrl && {
          coverThumbUrl: b_a_gl_album.coverThumbUrl,
        }),
      });
    } catch (error) {
      formErrorHandler({ error, config: { formError: { setError } } });
    }
  });

  const {
    checkedIds,
    getMenuItems,
    isActionPending,
    isActivateDialogOpen,
    isDeactivateDialogOpen,
    isFetchMoreLoading,
    isLoading,
    isAlbumPairLoading,
    onActivateAlbumHandler,
    onCheckItemHandler,
    onDeactivateAlbumHandler,
    onDeleteAvatarHandler,
    onResetChekedIds,
    onUploadAvatarHandler,
    openActivateDialog,
    openDeactivateDialog,
    ref,
    closeActivateDialog,
    closeDeactivateDialog,
    isOpenAddAlbumImagesDialogWindow,
    openAddAlbumImagesDialogWindow,
    closeAddAlbumImagesDialogWindow,
    fetchAlbumPairs,
    deleteAlbumPairHandler,
    albumPairActionMethods,
  } = useAlbumProfileState({
    setAlbum: set_b_a_gl_album,
    fetchAlbumApi: getGLBAalbum,
    totalPages: b_a_gl_album_pairs.pages,
    activateAlbumApi: activateGLBAalbum,
    deactivateAlbumApi: deactivateGLBAalbum,
    deleteAlbumCoverApi: deleteGLBAalbumCover,
    uploadAlbumCoverApi: uploadGLBAalbumCover,
    setAlbumPairs: set_b_a_album_pairs,
    fetchAlbumPairsApi: get_gl_ba_album_pairs,
    setNextAlbumPairs: set_next_b_a_album_pairs,
    deleteAlbumPairApi: delete_gl_ba_album_pair,
    libraryType: 'gl',
  });

  return {
    b_a_gl_album,
    isLoading,
    openActivateDialog,
    openDeactivateDialog,
    closeActivateDialog,
    closeDeactivateDialog,
    isActionPending,
    onActivateAlbumHandler,
    onDeactivateAlbumHandler,
    formMethods,
    isActivateDialogOpen,
    isDeactivateDialogOpen,
    isAlbumPairLoading,
    b_a_gl_album_pairs,
    ref,
    isFetchMoreLoading,
    onUploadAvatarHandler,
    onDeleteAvatarHandler,
    onFormSubmitHandler,
    checkedIds,
    onCheckItemHandler,
    onResetChekedIds,
    getMenuItems,
    isOpenAddAlbumImagesDialogWindow,
    openAddAlbumImagesDialogWindow,
    closeAddAlbumImagesDialogWindow,
    albumId,
    fetchAlbumPairs,
    deleteAlbumPairHandler,
    albumPairActionMethods,
    isRoleAdmin,
  };
};
