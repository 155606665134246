import { FC } from 'react';

import { Box, Typography } from '@mui/material';

const variants = {
  success: {
    bgcolor: '#ECFDF3',
    borderColor: '#ABEFC6',
    sxColor: '#067647',
  },
  warning: {
    bgcolor: '#FFFAEB',
    borderColor: '#FEDF89',
    sxColor: '#B54708',
  },
  error: {
    bgcolor: '#FEF3F2',
    borderColor: '#FECDCA',
    sxColor: '#B42318',
  },
};

interface Props {
  type?: keyof typeof variants;
  children: string;
}

export const Badge: FC<Props> = ({ type = 'success', children }) => {
  const Dot = () => (
    <div style={{ width: '6px', height: '6px', borderRadius: '50%', background: variants[type].sxColor }} />
  );

  return (
    <Box
      borderRadius="400px"
      border={`1px solid ${variants[type].borderColor}`}
      bgcolor={variants[type].bgcolor}
      color={variants[type].sxColor}
      sx={{ padding: '2px 8px' }}
      display="flex"
      flexDirection="row"
      alignItems="center"
      gap="4px"
    >
      <Dot />
      <Typography fontSize={12} fontWeight={400}>
        {children}
      </Typography>
    </Box>
  );
};
