import { FC } from 'react';

import { Box, Card, Divider, Grid, Skeleton, Stack, Table, TableBody, TableContainer, Typography } from '@mui/material';

import { BaseDialogWindow, ZoomCropDialogWindow } from 'components/BaseDialogWindow';
import { SideNavFormMenu } from 'components/BaseSideNavMenu';
import { FormActions, SideNavCardSkeleton } from 'components/Form';
import { RHFTextField } from 'components/HookForm';
import { Icon } from 'components/Icon';
import MDButton from 'components/MDButton';
import { Scrollbar } from 'components/ScrollBar';
import { EntityHeader, EntityHeaderSkeleton } from 'components/Widgets';
import { TagsTableRow, UploadImageCard } from 'pages/BeforeAfter';
import { ActivateDeactivateDialogWindow } from 'pages/BeforeAfter/components/ActivateDeactivateDialog';

import { usePLBeforeAfterAlbumPhotosProfileState } from './usePLBeforeAfterAlbumPhotosProfile.state';

export const PracticeLibraryBAphotos: FC = () => {
  const {
    ba_pl_image_pair,
    photoRef,
    infoRef,
    tagsRef,
    activeSectionId,
    executeScroll,
    isLoading,
    errors,
    onFormSubmitHandler,
    register,
    isSubmitButtonDisabled,
    isDirty,
    isSubmitting,
    sideNavItems,
    isActionPending,
    onDeleteBeforeImageHandler,
    onUploadDropzoneFileHandler,
    previewURL,
    onClickRemoveBeforeImageHandler,
    onCreateBeforeImageHandler,
    isCropFileDialogOpen,
    onCropCompleteHandler,
    onFileInputChangeHandler,
    onActivateDeactivateAlbumPairHandler,
    imagePosition,
    onDeleteAfterImageHandler,
    isImageUplaoding,
    onClickRemoveAfterImageHandler,
    onAfterFileInputChangeHandler,
    onUploadAfterDropzoneFileHandler,
    isAfterCropFileDialogOpen,
    afterPreviewURL,
    onCreateAfterImageHandler,
    onAfterCropCompleteHandler,
    isActivateDialogOpen,
    isDeactivateDialogOpen,
    closeActivateDialog,
    closeDeactivateDialog,
    openActivateDialog,
    openDeactivateDialog,
    isOpenDeleteImageDialogWindow,
    closeDeleteImageDialogWindow,
    isDeletingImage,
    onCloseCropDialogHandler,
  } = usePLBeforeAfterAlbumPhotosProfileState();

  return (
    <Box component="section">
      <Card>
        <Grid container>
          <Grid item xs={12}>
            {isLoading ? (
              <EntityHeaderSkeleton avatarVariant="rounded" isActiveStatus />
            ) : (
              <EntityHeader
                title={ba_pl_image_pair?.name}
                subtitle={ba_pl_image_pair?.description}
                activeStatusProps={{
                  isActive: ba_pl_image_pair?.isActive,
                  onActivateHandler: openActivateDialog,
                  onDeactivateHandler: openDeactivateDialog,
                  isLoading: isActionPending,
                }}
                avatarProps={{
                  // TODO: uncomment when BE will be ready
                  // src: albumPair?.album?.coverUrl,
                  // initialPhotoUrl: b_a_gl_album?.coverUrl,
                  onCreateFileHandler: async () => {},
                  onDeleteFileHandler: async () => {},
                  formDataImageName: 'cover_image',
                  variant: 'rounded',
                  cropShape: 'rect',
                  isDisabled: true,
                }}
              />
            )}
          </Grid>
          <Grid
            item
            xs={12}
            sm={2}
            sx={({ borders, breakpoints }) => ({
              borderRight: `${borders.borderWidth[1]} solid ${borders.borderColor}`,
              borderBottom: 0,
              [breakpoints.down('sm')]: {
                borderRight: 0,
                borderBottom: `${borders.borderWidth[1]} solid ${borders.borderColor} `,
              },
            })}
          >
            {isLoading ? (
              <SideNavCardSkeleton sx={{ border: 0, borderWidth: 0 }} />
            ) : (
              <SideNavFormMenu onClick={executeScroll} activeSectionId={activeSectionId} sidenavItems={sideNavItems} />
            )}
          </Grid>

          <Grid item xs={12} sm={10}>
            <Stack direction="row" width={1}>
              <Stack width={1}>
                <Stack width={1} ref={photoRef} id="photo">
                  <Stack
                    p={{ xs: 2, sm: 2.5, md: 3, lg: 3.5 }}
                    width={1}
                    direction={{ xs: 'column', sm: 'row' }}
                    gap={1.5}
                  >
                    {isLoading ? (
                      <>
                        <Skeleton variant="rounded" width="100%" height={300} />
                        <Skeleton variant="rounded" width="100%" height={300} />
                      </>
                    ) : (
                      <>
                        <UploadImageCard
                          previewURL={
                            ba_pl_image_pair?.imageLeftThumbUrl && !previewURL
                              ? ba_pl_image_pair?.imageLeftThumbUrl
                              : previewURL
                          }
                          onClickRemoveImageHandler={onClickRemoveBeforeImageHandler}
                          onUploadAnotherFileHandler={onFileInputChangeHandler}
                          type="before"
                          onDropFileHandler={onUploadDropzoneFileHandler}
                        />

                        <UploadImageCard
                          previewURL={
                            ba_pl_image_pair?.imageRightThumbUrl && !afterPreviewURL
                              ? ba_pl_image_pair?.imageRightThumbUrl
                              : afterPreviewURL
                          }
                          onClickRemoveImageHandler={onClickRemoveAfterImageHandler}
                          onUploadAnotherFileHandler={onAfterFileInputChangeHandler}
                          type="after"
                          onDropFileHandler={onUploadAfterDropzoneFileHandler}
                        />
                      </>
                    )}
                  </Stack>
                  <Divider flexItem />
                </Stack>

                <Stack ref={infoRef} id="info">
                  <Stack
                    component="form"
                    onSubmit={onFormSubmitHandler}
                    p={{ xs: 2, sm: 2.5, md: 3, lg: 3.5 }}
                    spacing={2.5}
                    maxWidth={700}
                  >
                    {isLoading ? (
                      <>
                        <Skeleton variant="rounded" width="100%" height={40} />
                        <Skeleton variant="rounded" width="100%" height={100} />
                      </>
                    ) : (
                      <>
                        <RHFTextField
                          label="Name"
                          register={register}
                          registerName="name"
                          registerErrors={errors?.name?.message}
                          placeholder="Enter name"
                        />

                        <RHFTextField
                          label="Description"
                          register={register}
                          registerName="description"
                          registerErrors={errors?.description?.message}
                          multiline
                          minRows={5}
                          maxRows={8}
                          placeholder="Enter description"
                        />
                      </>
                    )}

                    {/* TODO: when BE will be ready */}
                    {/* <MDAutocomplete
                      multiple
                      options={[{ id: '1', name: 'Product 1' }]}
                      inputProps={{ label: 'Products' }}
                      getOptionLabel={option => {
                        if (typeof option !== 'string') return option.name;
                      }}
                    />

                    <MDAutocomplete
                      multiple
                      options={[{ id: '1', name: 'Service 1' }]}
                      inputProps={{ label: 'Services' }}
                      getOptionLabel={option => {
                        if (typeof option !== 'string') return option.name;
                      }}
                    /> */}
                  </Stack>
                  {/* TODO: when tags will be ready */}
                  {/* <Divider /> */}
                </Stack>

                {/* TODO: when BE will be ready */}
                <Stack ref={tagsRef} id="tags" p={{ xs: 2, sm: 2.5, md: 3, lg: 3.5 }} spacing={2}>
                  <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="center" justifyContent="space-between">
                    <Typography>Tags (5/44)</Typography>

                    <Stack direction={{ xs: 'column', sm: 'row' }} gap={1}>
                      <MDButton
                        variant="outlined"
                        color="primary"
                        size="small"
                        sx={{
                          minWidth: 'unset',
                          width: 40,
                        }}
                      >
                        <Icon
                          type="filter"
                          sx={{
                            width: 20,
                            height: 20,
                            alignItems: 'center',
                          }}
                        />
                      </MDButton>
                      <MDButton startIcon={<Icon type="starsIcon" />}>AI assistant</MDButton>
                    </Stack>
                  </Stack>

                  <Card sx={{ overflow: 'hidden' }}>
                    <TableContainer>
                      <Scrollbar>
                        <Table size="medium" sx={{ minWidth: 600, th: { width: 200 } }}>
                          <TableBody>
                            <TagsTableRow />
                          </TableBody>
                        </Table>
                      </Scrollbar>
                    </TableContainer>
                  </Card>

                  <Card sx={{ overflow: 'hidden' }}>
                    <TableContainer>
                      <Scrollbar>
                        <Table size="medium" sx={{ minWidth: 600, th: { width: 200 } }}>
                          <TableBody>
                            <TagsTableRow />
                          </TableBody>
                        </Table>
                      </Scrollbar>
                    </TableContainer>
                  </Card>
                </Stack>

                <FormActions
                  isDisabled={isSubmitButtonDisabled}
                  isLoading={isSubmitting}
                  onFormSubmitHandler={onFormSubmitHandler}
                  isDirtyForm={isDirty}
                  backRoute="auto"
                />
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Card>

      <BaseDialogWindow
        onClickCancelButtonHandler={closeDeleteImageDialogWindow}
        open={isOpenDeleteImageDialogWindow}
        description={
          <Typography variant="body2">
            Are you sure you want to delete{' '}
            <Typography variant="body2" component="span" fontWeight={600}>
              {imagePosition === 'left' ? 'before' : 'after'}
            </Typography>{' '}
            image?
          </Typography>
        }
        title="Delete Image"
        isApproveButtonDisabled={isDeletingImage}
        isApproveButtonLoading={isDeletingImage}
        approveButtonTitle="Delete Image"
        onClickApproveButtonHandler={imagePosition === 'left' ? onDeleteBeforeImageHandler : onDeleteAfterImageHandler}
      />

      {isCropFileDialogOpen && (
        <ZoomCropDialogWindow
          imageURL={previewURL}
          open={isCropFileDialogOpen}
          onClickCloseButtonHandler={onCloseCropDialogHandler}
          onUploadNewFileHandler={onFileInputChangeHandler}
          onSaveFileHandler={onCreateBeforeImageHandler}
          onCropCompleteHandler={onCropCompleteHandler}
          isLoading={isImageUplaoding}
          cropShape="rect"
        />
      )}

      {isAfterCropFileDialogOpen && (
        <ZoomCropDialogWindow
          imageURL={afterPreviewURL}
          open={isAfterCropFileDialogOpen}
          onClickCloseButtonHandler={onCloseCropDialogHandler}
          onUploadNewFileHandler={onAfterFileInputChangeHandler}
          onSaveFileHandler={onCreateAfterImageHandler}
          onCropCompleteHandler={onAfterCropCompleteHandler}
          isLoading={isImageUplaoding}
          cropShape="rect"
        />
      )}

      <ActivateDeactivateDialogWindow
        title="Before & After Photos"
        descriptionSuffix="the before & after photos later."
        actionType={isDeactivateDialogOpen ? 'deactivate' : 'activate'}
        name={ba_pl_image_pair?.name}
        isOpen={isDeactivateDialogOpen || isActivateDialogOpen}
        isActionPending={isActionPending}
        onClose={isDeactivateDialogOpen ? closeDeactivateDialog : closeActivateDialog}
        onApprove={onActivateDeactivateAlbumPairHandler}
      />
    </Box>
  );
};
