import { FC } from 'react';

import { Stack, Typography } from '@mui/material';

import { BACardItemFooterProps } from './types';

export const BACardItemFooter: FC<BACardItemFooterProps> = ({ description, title }) => {
  return (
    <Stack px={1.5} spacing={0.5}>
      <Typography variant="body2" noWrap fontSize={14} fontWeight={500}>
        {title}
      </Typography>
      {description && (
        <Typography variant="body2" noWrap color="text.main" fontWeight={400} fontSize={12}>
          {description}
        </Typography>
      )}
    </Stack>
  );
};
