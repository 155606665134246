import { CreatePracticeContactPersonPayload } from 'apiServices';
import * as yup from 'yup';

import { Shape } from 'types/RHF-types';
import { EmailLazyValidationSchema, PhoneValidationSchema, WebsiteLazyValidationSchema } from 'utils/formValidation';

export type EditContactPersonFormSchema = Omit<CreatePracticeContactPersonPayload, 'id'>;

export const editContactPersonValidationSchema = yup
  .object()
  .shape<Shape<EditContactPersonFormSchema>>({
    contact: yup
      .object()
      .shape<Shape<BaseContact>>({
        email: EmailLazyValidationSchema,
        phone: PhoneValidationSchema,
        website: WebsiteLazyValidationSchema,
      })
      .required(),
  })

  .required();

export const CONTACT_PERSON_DEFAULT_VALUES = {
  contact: { email: '', phone: '', website: '' },
};
