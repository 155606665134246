import { FC } from 'react';

import { Box, Grid } from '@mui/material';

import { Icon } from '../../../../../components/Icon';

export const ActionMenu: FC<{
  onCopy?: () => void;
  onEdit?: () => void;
  onDelete?: () => void;
  className?: string;
  sx?: Parameters<typeof Icon>[0]['sx'];
}> = props => {
  return (
    <Box
      sx={{
        display: 'flex',
        border: '1px solid #E0E0E0',
        backgroundColor: '#F9F9F9',
        borderRadius: '8px',
        overflow: 'hidden',
        cursor: 'pointer',
        ':hover': {
          backgroundColor: '#E0E0E0',
        },
        ...props.sx,
      }}
      className={props.className}
    >
      {/* TODO: BE not ready */}
      {/*<Grid container spacing={0}>*/}
      {/*<Grid*/}
      {/*  item*/}
      {/*  xs={4}*/}
      {/*  sx={{*/}
      {/*    padding: '8px',*/}
      {/*    textAlign: 'center',*/}
      {/*    borderRight: '1px solid #E0E0E0',*/}
      {/*    ':hover': {*/}
      {/*      backgroundColor: '#E0E0E0',*/}
      {/*    },*/}
      {/*  }}*/}
      {/*  onClick={props.onCopy}*/}
      {/*>*/}
      {/*  <Icon type="copy" width={14} height={14} />*/}
      {/*</Grid>*/}
      {/*<Grid*/}
      {/*  item*/}
      {/*  xs={4}*/}
      {/*  sx={{*/}
      {/*    padding: '8px',*/}
      {/*    textAlign: 'center',*/}
      {/*    borderRight: '1px solid #E0E0E0',*/}
      {/*    ':hover': {*/}
      {/*      backgroundColor: '#E0E0E0',*/}
      {/*    },*/}
      {/*  }}*/}
      {/*  onClick={props.onEdit}*/}
      {/*>*/}
      {/*<Icon type="editPen" width={14} height={14} />*/}
      {/*</Grid>*/}
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          padding: '8px',
          textAlign: 'center',
          // borderRight: '1px solid #E0E0E0',
          // ':hover': {
          //   backgroundColor: '#E0E0E0',
          // },
        }}
        onClick={props.onDelete}
      >
        <Icon type="trashBin" width={14} height={14} />
      </Grid>
      {/*</Grid>*/}
    </Box>
  );
};
