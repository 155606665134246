import { FC, memo } from 'react';

import { FileDownloadOutlined, VisibilityOffRounded } from '@mui/icons-material';
import { Chip, TableCell, TableRow } from '@mui/material';

import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';
import { ThreeDotsTableCell } from 'components/Table';
import { usePopover } from 'hooks/usePopover';
import { useRouter } from 'hooks/useRouter';

type PromptLibraryTableRowProps = {
  prompt_id?: string;
  promptName?: string;
  text?: string;
  versions?: string;
  comment?: string;
  workflow?: string;
  interaction?: string;
  assessment?: string;
  dateEdited?: string;
  openDeleteDialogWindow?: () => void;
  openDeactivateDialogWindow?: () => void;
};

export const PromptLibraryTableRow: FC<PromptLibraryTableRowProps> = memo(
  ({
    prompt_id,
    promptName,
    text,
    comment,
    workflow = 'workflow',
    interaction = 'interaction',
    assessment = 'Medical History Review',
    dateEdited = '2024-07-15',
    openDeleteDialogWindow,
    openDeactivateDialogWindow,
  }) => {
    const { navigate } = useRouter();

    const { handleClosePopover, handleOpenPopover, openPopover } = usePopover();

    const onCloseThreeDotsMenuHandler = (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      handleClosePopover();
    };

    const onClickThreeDotsMenuButtonHandler = (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      handleOpenPopover(event);
    };

    const onClickDeleteButtonHandler = (event: React.MouseEvent<HTMLLIElement>) => {
      event.stopPropagation();
      handleClosePopover();
      openDeleteDialogWindow();
    };

    const onClickDeactivateButtonHandler = (event: React.MouseEvent<HTMLLIElement>) => {
      event.stopPropagation();
      handleClosePopover();
      openDeactivateDialogWindow();
    };

    const onClickTableRowHandler = () => {
      navigate(`/prompt-library/${prompt_id}`, {
        state: {
          breadCrumbName: promptName || 'Prompt Name',
          prompt_id: prompt_id,
          text: text,
        },
      });
    };

    const promptVersionList = () => {
      navigate(`/prompt-versions-list/${prompt_id}`, {
        state: {
          breadCrumbName: promptName || 'Prompt Name',
          prompt_id: prompt_id,
        },
      });
    };

    return (
      <TableRow onClick={onClickTableRowHandler}>
        <TableCell>
          <MDTypography variant="body2">{promptName}</MDTypography>
        </TableCell>
        <TableCell>
          <MDTypography variant="body2">
            <MDButton
              variant="contained"
              color="primary"
              size="small"
              onClick={event => {
                event.stopPropagation();
                promptVersionList();
              }}
            >
              versions
            </MDButton>
          </MDTypography>
        </TableCell>
        <TableCell>
          <Chip label={workflow} color="primary" variant="soft" />
        </TableCell>
        <TableCell>
          <Chip label={interaction} color="warning" variant="soft" />
        </TableCell>
        <TableCell>
          <Chip label={assessment} color="success" variant="outlined" />
        </TableCell>
        <TableCell>{dateEdited}</TableCell>
        <ThreeDotsTableCell
          open={openPopover}
          onClickButtonHandler={onClickThreeDotsMenuButtonHandler}
          onClose={onCloseThreeDotsMenuHandler}
          onClickDeleteMenuItemHandler={onClickDeleteButtonHandler}
          menuItems={[
            { title: 'Export', icon: <FileDownloadOutlined fontSize="small" />, onClick: onClickTableRowHandler },
            {
              title: 'Hide',
              icon: <VisibilityOffRounded fontSize="small" />,
              onClick: onClickDeactivateButtonHandler,
            },
          ]}
        />
      </TableRow>
    );
  }
);
