import colors from 'assets/theme/base/colors';
import typography from 'assets/theme/base/typography';
import pxToRem from 'assets/theme/functions/pxToRem';

const { dark } = colors;
const { size, fontWeightRegular } = typography;

const formControlLabel = {
  defaultProps: {
    sx: {
      textTransform: 'capitalize',
    },
  },
  styleOverrides: {
    root: {
      marginLeft: 0,
      marginRight: 0,
      minHeight: pxToRem(24),
      marginBottom: pxToRem(2),
    },

    labelPlacementStart: {
      '.MuiFormControlLabel-label': {
        marginRight: 6,
      },
    },

    labelPlacementEnd: {
      '.MuiFormControlLabel-label': {
        marginLeft: 6,
      },
    },

    label: {
      display: 'inline-block',
      fontSize: size.sm,
      fontWeight: fontWeightRegular,
      color: dark.main,
      lineHeight: 1,
      '&.Mui-disabled': {
        color: dark.main,
      },
    },
  },
};

export default formControlLabel;
