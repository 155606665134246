import { FC, useEffect, useState } from 'react';

import { Card, Grid, Table, TableBody, TableContainer } from '@mui/material';
import { deletePrompt } from 'apiServices/ml/prompt.api';

import { BaseDialogWindow } from 'components/BaseDialogWindow';
import { PopoverWrapper } from 'components/MenuPopover';
import { Scrollbar } from 'components/ScrollBar';
import {
  CustomTableHeader,
  CustomTablePagination,
  TableFiltersPanel,
  TableNoData,
  TableSkeleton,
} from 'components/Table';
import { ROUTES } from 'constants/routes';
import { usePrompts } from 'hooks/usePrompts';
import { useRouter } from 'hooks/useRouter';

import { FiltersMenuContent } from './components/FilterMenuContent';
import { PromptLibraryTableRow } from './components/PromptLibraryTableRow';
import { PROMPT_LIBRARY_TABLE_HEADERS } from './table.config';
import { usePromptLibraryState } from './usePromptLibrary.state';

export const PromptList: FC = () => {
  const { navigate } = useRouter();
  const { prompts: initialPrompts, isLoading } = usePrompts();

  const [searchQuery, setSearchQuery] = useState('');
  const [prompts, setPrompts] = useState(initialPrompts);
  const [filteredPrompts, setFilteredPrompts] = useState(initialPrompts);
  const [promptToDelete, setPromptToDelete] = useState<string | null>(null);

  const {
    closeFilterMenu,
    isFilterMenuOpen,
    openFilterMenu,
    isOpenDeleteDialogWindow,
    openDeleteDialogWindow,
    closeDeleteDialogWindow,
  } = usePromptLibraryState();

  useEffect(() => {
    setPrompts(initialPrompts);
    setFilteredPrompts(initialPrompts);
  }, [initialPrompts]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    const filtered = prompts.filter(prompt => prompt.name.toLowerCase().includes(query));

    setFilteredPrompts(filtered);
  };

  const handleDeletePrompt = async () => {
    if (promptToDelete) {
      try {
        await deletePrompt(promptToDelete);
        setPrompts(prevPrompts => prevPrompts.filter(p => p.id !== promptToDelete));
        setFilteredPrompts(prevPrompts => prevPrompts.filter(p => p.id !== promptToDelete));
        closeDeleteDialogWindow();
      } catch (error) {
        console.error('Error deleting prompt:', error);
      }
    }
  };

  return (
    <section>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card sx={{ borderRadius: 2, overflow: 'hidden' }}>
            <TableFiltersPanel
              searchInputProps={{
                value: searchQuery,
                placeholder: 'Search prompt',
                onChange: handleSearchChange,
              }}
              onOpenFilterMenuHandler={openFilterMenu}
              actionButtonProps={{
                children: 'Create new prompt',
                onClick: () => {
                  navigate(ROUTES.createPrompt);
                },
              }}
              secondaryActionButtonProps={{
                children: 'Model List',
                onClick: () => {
                  navigate(ROUTES.modelList, {
                    state: {
                      breadCrumbName: 'Model List',
                    },
                  });
                },
              }}
            />

            <TableContainer sx={{ position: 'relative' }}>
              <Scrollbar>
                <Table size="medium" sx={{ minWidth: 1000 }}>
                  <CustomTableHeader
                    headLabel={PROMPT_LIBRARY_TABLE_HEADERS}
                    sx={{
                      bgcolor: 'grey.200',
                      th: { p: 1 },
                      'th:first-of-type': { pl: '16px !important' },
                    }}
                  />

                  <TableBody>
                    {isLoading ? (
                      <TableSkeleton cellsAmount={7} />
                    ) : filteredPrompts.length ? (
                      filteredPrompts.map(prompt => (
                        <PromptLibraryTableRow
                          key={prompt.id}
                          prompt_id={prompt.id}
                          text={prompt.text}
                          promptName={prompt.name}
                          comment={prompt.comment}
                          openDeleteDialogWindow={() => {
                            setPromptToDelete(prompt.id);
                            openDeleteDialogWindow();
                          }}
                        />
                      ))
                    ) : (
                      <TableNoData isNotFound />
                    )}
                  </TableBody>
                </Table>
              </Scrollbar>
            </TableContainer>

            <CustomTablePagination
              count={filteredPrompts.length}
              page={0}
              rowsPerPage={20}
              onPageChange={() => {}}
              onRowsPerPageChange={() => {}}
            />

            <BaseDialogWindow
              onClickCancelButtonHandler={closeDeleteDialogWindow}
              open={isOpenDeleteDialogWindow}
              description="Are you sure you want to delete this prompt?"
              title="Deleting prompt"
              approveButtonTitle="Delete prompt"
              isApproveButtonDisabled={false}
              isApproveButtonLoading={false}
              onClickApproveButtonHandler={handleDeletePrompt}
            />

            <PopoverWrapper
              variant="filter"
              open={isFilterMenuOpen}
              title="Filter"
              sx={{ width: 1, maxWidth: 290, ml: 0, mt: 1, px: 2, py: 1 }}
              handleClosePopover={closeFilterMenu}
            >
              <FiltersMenuContent />
            </PopoverWrapper>
          </Card>
        </Grid>
      </Grid>
    </section>
  );
};
