export const GENERAL_ID = 'general';
export const DERMATOLOGICAL_ASSESSMENT_ID = 'dermatological-assessment';
export const BODY_MEASUREMENTS_ID = 'body-measurements';
export const LIFESTYLE_ID = 'lifestyle';
export const ALLERGIES_ID = 'allergies';
export const MEDICAL_CONDITIONS_ID = 'medical-conditions';
export const MEDICINES_ID = 'medicines';
/* export const CONTACT_ID = 'contact';
export const NOTE_ID = 'note';
 */
