import { useMemo, useState } from 'react';

import { AxiosResponse } from 'axios';

import { ToastType, notice } from 'components/ToastNotification';
import { useActionDialogManagement } from 'hooks/useActionDialogManagement';
import { useBoolean } from 'hooks/useBoolean';
import { useDebounce } from 'hooks/useDebounce';
import { usePopover } from 'hooks/usePopover';
import { useTable } from 'hooks/useTable';
import { backendErrorHandler } from 'utils/errorHanders';
import { checkIsFilterApplied } from 'utils/helpers';

interface BaseTableStateParams<TItem> {
  fetchItems: (params?: { page?: number; [key: string]: any }) => Promise<void>;
  activateItem: (id: string) => Promise<AxiosResponse<TItem, any>>;
  deactivateItem: (id: string) => Promise<AxiosResponse<TItem, any>>;
  deleteItem: (id: string) => Promise<AxiosResponse<any, any>>;
  messages: {
    activationSuccess?: string;
    activationError?: string;
    deactivationSuccess?: string;
    deactivationError?: string;
    deletionSuccess?: string;
    deletionError?: string;
  };
}

interface CopySpecificParams<TItem> {
  copyItem: (payload: { sourceId: string; practiceId: string }) => Promise<AxiosResponse<TItem, any>>;
  practiceId: string;
  messages: {
    copySuccess: string;
    copyError: string;
  };
}

// Conditional type to choose the appropriate type based on the presence of copyItem
type UseTableStateParams<TItem> = BaseTableStateParams<TItem> &
  (
    | CopySpecificParams<TItem>
    | {
        copyItem?: never;
        practiceId?: never;
        messages: {
          copySuccess?: never;
          copyError?: never;
        };
      }
  );

const FiltersInitialStateValue: BaseFilterState = {
  isShowDeactivated: false,
};

export const useTableState = <TItem>({
  fetchItems,
  activateItem,
  deactivateItem,
  deleteItem,
  copyItem,
  messages,
  practiceId,
}: UseTableStateParams<TItem>) => {
  const { page, rowsPerPage, onChangeRowsPerPage, setPage, isResettingPage, resetPageHandler } = useTable();

  const [searchValue, setSearchValue] = useState('');

  const [appliedFilters, setAppliedFilters] = useState<BaseFilterState>(FiltersInitialStateValue);

  const debouncedSearchValue = useDebounce(searchValue, 200);

  const {
    handleClosePopover: closeFiltersMenu,
    handleOpenPopover: openFilterMenu,
    openPopover: isFiltersMenuOpen,
  } = usePopover();

  const [isAddItemDialogOpen, openAddItemDialog, closeAddItemDialog] = useBoolean();

  const {
    actionState,
    isActionPending,
    setIsActionPending,
    closeActivateDialog,
    closeDeactivateDialog,
    isActivateDialogOpen,
    isDeactivateDialogOpen,
    onClickActionOptionHandler,
    closeDeleteDialogWindow,
    isOpenDeleteDialogWindow,
    closeCopyDialogWindow,
    openCopyDialogWindow,
    isOpenCopyDialogWindow,
  } = useActionDialogManagement();

  const onDeactivateServiceHandler = async (event: React.MouseEvent<HTMLElement>) => {
    setIsActionPending(true);
    const { id } = actionState;
    try {
      await deactivateItem(id);

      notice(ToastType.SUCCESS, messages?.deactivationSuccess);
      closeDeactivateDialog();

      await fetchItems({ page: 1 });
    } catch (error) {
      backendErrorHandler({
        error,
        config: {
          customErrorMessage: messages?.deactivationError,
        },
      });
    } finally {
      setIsActionPending(false);
    }
  };

  const onActivateServiceHandler = async (event: React.MouseEvent<HTMLElement>) => {
    setIsActionPending(true);
    const { id } = actionState;
    try {
      await activateItem(id);
      notice(ToastType.SUCCESS, messages?.activationSuccess);
      closeActivateDialog();

      fetchItems({ page: 1 });
    } catch (error) {
      backendErrorHandler({
        error,
        config: {
          customErrorMessage: messages?.activationError,
        },
      });
    } finally {
      setIsActionPending(false);
    }
  };

  const onDeleteServiceHandler = async (event: React.MouseEvent<HTMLElement>) => {
    setIsActionPending(true);
    const { id } = actionState;
    try {
      await deleteItem(id);

      notice(ToastType.SUCCESS, messages?.deletionSuccess);

      closeDeleteDialogWindow();

      fetchItems({ page: 1 });
    } catch (error) {
      backendErrorHandler({
        error,
        config: {
          customErrorMessage: messages?.deletionError,
        },
      });
    } finally {
      setIsActionPending(false);
    }
  };

  const onChangeSearchInputValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);

    isResettingPage.current = true;
  };

  const onChangePage = async (e: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    const newPage = page + 1;
    setPage(newPage);
    await fetchItems({ page: newPage });
  };

  const onApplyFilters = (newFilters: BaseFilterState) => {
    setAppliedFilters(newFilters);
    isResettingPage.current = true;
    closeFiltersMenu();
  };

  const isFiltersApplied = useMemo(
    () => checkIsFilterApplied(FiltersInitialStateValue, appliedFilters),
    [appliedFilters]
  );

  const onCopyItemHandler = async () => {
    if (!copyItem && !practiceId) return;

    setIsActionPending(true);
    try {
      await copyItem({ sourceId: actionState.id, practiceId });
      closeCopyDialogWindow();
      notice(ToastType.SUCCESS, messages?.copySuccess || 'Item copied successfully!');
    } catch (error) {
      backendErrorHandler({
        error,
        config: {
          customErrorMessage: messages?.copyError || 'Failed to copy item, please try again!',
        },
      });
    } finally {
      setIsActionPending(false);
    }
  };

  return {
    searchValue,
    onChangeSearchInputValue,
    isOpenDeleteDialogWindow,
    closeDeleteDialogWindow,
    closeFiltersMenu,
    openFilterMenu,
    isFiltersMenuOpen,
    onChangeRowsPerPage,
    rowsPerPage,
    onChangePage,
    page,
    appliedFilters,
    isActionPending,
    onActivateServiceHandler,
    onDeactivateServiceHandler,
    onClickActionOptionHandler,
    isDeactivateDialogOpen,
    isActivateDialogOpen,
    closeDeactivateDialog,
    closeActivateDialog,
    actionState,
    onApplyFilters,
    isFiltersApplied,
    onDeleteServiceHandler,
    debouncedSearchValue,
    setPage,
    isAddItemDialogOpen,
    openAddItemDialog,
    closeAddItemDialog,
    resetPageHandler,
    isResettingPage,
    closeCopyDialogWindow,
    openCopyDialogWindow,
    isOpenCopyDialogWindow,
    onCopyItemHandler,
  };
};
