import { FC } from 'react';

import { Box, Divider, Stack, Typography } from '@mui/material';

import { UploadAvatar } from 'components/UploadAvatar';
import { UserRoleChip } from 'components/UserRoleChip';

import { ClipboardLabel } from '../../ClipboardLabel/ClipboardLabel';
import { Note } from '../Note';
import { ActionsHeader } from './ActionsHeader';
import { EntityHeaderProps } from './types';

export const EntityHeader: FC<EntityHeaderProps> = ({
  onClickThreeDotsButtonHandler,
  noteProps,
  title,
  subtitle,
  tabs,
  id,
  avatarProps,
  activeStatusProps,
  role,
}) => {
  return (
    <>
      <Box sx={{ p: 2, ...(tabs && { pb: 0 }) }}>
        <ActionsHeader
          activeStatusProps={activeStatusProps}
          onClickThreeDotsButtonHandler={onClickThreeDotsButtonHandler}
        />
        <Stack
          direction={{ xs: 'column', lg: 'row' }}
          alignItems={{ xs: 'center', sm: 'flex-start' }}
          gap={2}
          width={1}
          justifyContent="space-between"
        >
          <Stack direction={{ xs: 'column', sm: 'row' }} gap={2} alignItems={{ xs: 'center', sm: 'flex-start' }}>
            <UploadAvatar avatarSize="3xl" {...avatarProps} />
            <Stack sx={{ position: 'relative', flexGrow: 1 }} justifyContent="space-between">
              <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="center" spacing={{ xs: 1, sm: 1.5 }}>
                <Typography textAlign={{ xs: 'center', sm: 'start' }} fontSize={{ xs: 18, md: 22 }} fontWeight={500}>
                  {title}
                </Typography>

                {role && <UserRoleChip userRole={role} />}
                {id && <ClipboardLabel label={`ID ${id}`} />}
              </Stack>
              {subtitle && (
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent={{ xs: 'center', sm: 'flex-start' }}
                  spacing={1}
                  mt={{ xs: 1, sm: 0 }}
                >
                  {subtitle}
                </Stack>
              )}
            </Stack>
          </Stack>

          {noteProps && <Note {...noteProps} />}
        </Stack>

        {tabs}
      </Box>
      <Divider sx={{ mt: tabs ? '-1px' : 0 }} />
    </>
  );
};
