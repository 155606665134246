import { FC } from 'react';

import { Card, CardContent, CardHeader } from '@mui/material';

import { UserEmailForm } from './components';

export const UserProfile: FC = () => {
  return (
    <Card sx={{ minHeight: 250, height: 1 }}>
      <CardHeader title="Profile" />
      <CardContent sx={{ height: 1 }}>
        <UserEmailForm />
        {/* <ProfileForm /> */}
      </CardContent>
    </Card>
  );
};
