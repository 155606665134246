import { useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  GlobalLibraryTagsSearchParams,
  TagListSortFieldName,
  createGlobalLibraryTag,
  deleteGlobalLibraryTag,
  getGlobalLibraryTagCategory,
  getGlobalLibraryTags,
  updateGlobalLibraryTag,
  updateGlobalLibraryTagCategory,
} from 'apiServices';
import { Resolver, useForm } from 'react-hook-form';
import { useAppStore } from 'store';
import { shallow } from 'zustand/shallow';

import { ToastType, notice } from 'components/ToastNotification';
import { useBoolean } from 'hooks/useBoolean';
import { useTable } from 'hooks/useTable';
import { CreateTagCategoryFormSchema, createTagCategoryValidationSchema } from 'pages/TagManagement';
import { backendErrorHandler, formErrorHandler } from 'utils/errorHanders';

import { useEditTagCategoryState } from '../useEditTagCategory.state';

export const useEditGlobalLibraryState = () => {
  const {
    globalLibraryTagCategoryData: {
      category,
      tags: { data: tags, total },
    },
    setGlobalLibraryTagCategory,
    setGlobalLibraryTags,
  } = useAppStore(
    store => ({
      globalLibraryTagCategoryData: store.globalLibraryTagCategoryData,
      setGlobalLibraryTagCategory: store.setGlobalLibraryTagCategory,
      setGlobalLibraryTags: store.setGlobalLibraryTags,
    }),
    shallow
  );

  const {
    register,
    handleSubmit,
    setError,
    formState: { isValid, isSubmitting, isDirty },
  } = useForm<CreateTagCategoryFormSchema>({
    resolver: yupResolver(createTagCategoryValidationSchema) as Resolver<CreateTagCategoryFormSchema>,
    mode: 'onTouched',
    values: { name: category?.name || '', serviceName: category?.serviceName || '' },
  });

  const onFormSubmitHandler = handleSubmit(async ({ name, serviceName }) => {
    try {
      const { data } = await updateGlobalLibraryTagCategory(categoryId, {
        isActive: category?.isActive,
        isCore: category?.isCore,
        name,
        ...(serviceName && { serviceName }),
      });
      setGlobalLibraryTagCategory(data);

      notice(ToastType.SUCCESS, 'Tag category has been successfully updated!');
    } catch (error) {
      formErrorHandler({
        error,
        config: { formError: { setError } },
        customErrorMessage: 'Failed to update tag category, please try again!',
      });
    }
  });

  const isSubmitButtonDisabled = !isValid || isSubmitting || !isDirty;

  const onActivate = async (id: string) => {
    await updateGlobalLibraryTag(id, { isActive: true, categoryId });

    notice(ToastType.SUCCESS, 'Tag has been successfully activated!');

    await onGetTagsByCategoryHandler({ isResetPage: true, params: { page: 1 } });
  };

  const onDeactivate = async (id: string) => {
    await deleteGlobalLibraryTag(id);

    notice(ToastType.SUCCESS, 'Tag has been successfully inactivated!');

    await onGetTagsByCategoryHandler({ isResetPage: true, params: { page: 1 } });
  };

  const { order, orderBy, onSort, page, rowsPerPage, onChangeRowsPerPage, setPage, isResettingPage, resetPageHandler } =
    useTable<TagListSortFieldName>({ defaultOrderBy: 'name', defaultOrder: 'asc' });

  const {
    actionState,
    closeActivateDialog,
    closeDeactivateDialog,
    isActivateDialogOpen,
    isActionPending,
    isDeactivateDialogOpen,
    onClickActionOptionHandler,
    closeAddTagMenu,
    closeEditTagMenu,
    isAddTagMenuOpen,
    isEditTagMenuOpen,
    onClickEditOptionMenuHandler,
    openAddTagMenu,
    selectedTagId,
    setSelectedTagId,
    onActivateTagHandler,
    onDeactivateTagHandler,
    debouncedSearchValue,
    onChangeSearchInputValue,
    searchValue,
    categoryId,
    appliedFilters,
    isFiltersMenuOpen,
    onApplyFilters,
    openFilterMenu,
    closeFiltersMenu,
    isFiltersApplied,
  } = useEditTagCategoryState({ onActivate, onDeactivate, isResettingPage });

  const [isOpenDeleteDialogWindow, openDeleteDialogWindow, closeDeleteDialogWindow] = useBoolean();

  const [isLoading, setIsLoading] = useState(true);

  const onGetTagCategoryHandler = async () => {
    !isLoading && setIsLoading(true);

    try {
      const { data } = await getGlobalLibraryTagCategory(categoryId);

      setGlobalLibraryTagCategory(data);
    } catch (error) {
      console.error(error);
      backendErrorHandler({ error, config: { customErrorMessage: 'Failed to get tag category, please try again!' } });
    } finally {
      setIsLoading(false);
    }
  };

  const [isLoadingTags, setIsLoadingTags] = useState(true);

  const onGetTagsByCategoryHandler = async ({
    isResetPage,
    params,
  }: {
    isResetPage?: boolean;
    params?: GlobalLibraryTagsSearchParams;
  }) => {
    !isLoading && setIsLoadingTags(true);

    try {
      const { data } = await getGlobalLibraryTags({
        ...(debouncedSearchValue && { search: debouncedSearchValue }),
        is_active: appliedFilters.isShowDeactivated ? false : true,
        order: [`${orderBy},${order}`],
        tag_category_id: categoryId,
        size: rowsPerPage,
        page,
        ...params,
      });

      setGlobalLibraryTags({
        data: data.items,
        page: data.page,
        pages: data.pages,
        size: data.size,
        total: data.total,
      });
      if (isResetPage && page !== 1) {
        setPage(1);
      }
    } catch (error) {
      console.error(error);
      backendErrorHandler({
        error,
        config: { customErrorMessage: 'Failed to get tags by category, please try again!' },
      });
    } finally {
      setIsLoadingTags(false);
    }
  };

  const onChangePage = async (e: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    const newPage = page + 1;
    setPage(newPage);
    await onGetTagsByCategoryHandler({ params: { page: newPage } });
  };

  useEffect(() => {
    onGetTagCategoryHandler();
  }, []);

  useEffect(() => {
    if (categoryId) {
      onGetTagsByCategoryHandler({ params: { ...(isResettingPage.current && { page: 1 }) } });
      resetPageHandler();
    }
  }, [debouncedSearchValue, appliedFilters, order, orderBy]);

  const createTagHandler = async (name: string) => {
    await createGlobalLibraryTag({
      isActive: true,
      name,
      categoryId,
    });

    closeAddTagMenu();

    await onGetTagsByCategoryHandler({ isResetPage: true, params: { page: 1 } });
  };

  const editTagHandler = async (name: string, isActive: boolean) => {
    await updateGlobalLibraryTag(selectedTagId, {
      isActive,
      name,
      categoryId,
    });

    closeEditTagMenu();
    setSelectedTagId('');
    await onGetTagsByCategoryHandler({});
  };

  return {
    searchValue,
    onChangeSearchInputValue,
    isOpenDeleteDialogWindow,
    openDeleteDialogWindow,
    closeDeleteDialogWindow,
    tags,
    register,
    isSubmitButtonDisabled,
    onFormSubmitHandler,
    isSubmitting,
    isLoading,
    isLoadingTags,
    total,
    page,
    rowsPerPage,
    onChangePage,
    onChangeRowsPerPage,
    appliedFilters,
    onApplyFilters,
    isFiltersMenuOpen,
    closeFiltersMenu,
    openFilterMenu,
    isActivateDialogOpen,
    closeActivateDialog,
    isDeactivateDialogOpen,
    closeDeactivateDialog,
    onClickActionOptionHandler,
    isActionPending,
    actionState,
    onActivateTagHandler,
    onDeactivateTagHandler,
    isAddTagMenuOpen,
    openAddTagMenu,
    closeAddTagMenu,
    isEditTagMenuOpen,
    closeEditTagMenu,
    onClickEditOptionMenuHandler,
    selectedTagId,
    isFiltersApplied,
    createTagHandler,
    editTagHandler,
    order,
    orderBy,
    onSort,
  };
};
