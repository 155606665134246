import { FC } from 'react';

import { Box, CircularProgress, CircularProgressProps, Typography } from '@mui/material';

type CircularProgressWithLabelProps = CircularProgressProps & { valueToDisplay: number };

export const CircularProgressWithLabel: FC<CircularProgressWithLabelProps> = ({ valueToDisplay, ...other }) => {
  return (
    <Box sx={{ position: 'relative', display: 'flex', justifyContent: 'center' }}>
      <CircularProgress variant="determinate" {...other} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="button" component="div" color="text.secondary">{`${Math.round(
          valueToDisplay
        )}s`}</Typography>
      </Box>
    </Box>
  );
};
