import { FC } from 'react';

import { Box, Dialog, Stack, Table, TableBody, TableContainer, Typography } from '@mui/material';

import { BaseDialogWindow } from 'components/BaseDialogWindow';
import { PopoverWrapper } from 'components/MenuPopover';
import { Scrollbar } from 'components/ScrollBar';
import {
  CustomTableHeader,
  CustomTablePagination,
  TableCard,
  TableFiltersPanel,
  TableNoData,
  TableSkeleton,
} from 'components/Table';
import { FilterPopoverContent, TAG_LIST_TABLE_HEADERS, TagCategoryListTableRow } from 'pages/TagManagement';

import { CreateTagCategoryMenuContent } from './components';
import { useGlobalLibraryTagListState } from './useGlobalLibraryTagList.state';

export const GlobalLibraryTagList: FC = () => {
  const {
    closeDeleteDialogWindow,
    isOpenDeleteDialogWindow,
    onChangeSearchInputValue,
    searchValue,
    closeFiltersMenu,
    isFiltersMenuOpen,
    openFilterMenu,
    data,
    isLoading,
    onGetTagCategoriesHandler,
    onChangeRowsPerPage,
    rowsPerPage,
    total,
    onChangePage,
    page,
    appliedFilters,
    isActivateDialogOpen,
    isDeactivateDialogOpen,
    onClickActionOptionHandler,
    onDeactivateTagCategoryHandler,
    closeActivateDialog,
    closeDeactivateDialog,
    actionState,
    isActionPending,
    onActivateTagCategoryHandler,
    onApplyFilters,
    isFiltersApplied,
    isRoleAdmin,
    onSort,
    orderBy,
    order,
    isAddTagCategoryDialogOpen,
    closeCopyDialogWindow,
    isOpenCopyDialogWindow,
    onCopyTagCategoryHandler,
    openAddTagCategoryDialogMenu,
    closeAddTagCategoryDialogMenu,
  } = useGlobalLibraryTagListState();

  return (
    <TableCard>
      <TableFiltersPanel
        searchInputProps={{
          value: searchValue,
          placeholder: 'Search by name',
          onChange: onChangeSearchInputValue,
        }}
        onOpenFilterMenuHandler={openFilterMenu}
        actionButtonProps={
          isRoleAdmin && {
            children: 'Add tag category',
            onClick: openAddTagCategoryDialogMenu,
          }
        }
        isFiltersApplied={isFiltersApplied}
      />

      <TableContainer>
        <Scrollbar>
          <Table size="medium" sx={{ minWidth: 800, tableLayout: 'fixed', width: 1 }}>
            <colgroup>
              <Box component="col" width={1} />
              <Box component="col" width={1} />
              <Box component="col" width="20%" />
            </colgroup>
            <CustomTableHeader
              headLabel={TAG_LIST_TABLE_HEADERS}
              sx={{
                bgcolor: 'grey.200',
                th: { p: 1 },
                'th:first-of-type': { pl: '16px !important' },
              }}
              onSort={onSort}
              orderBy={orderBy}
              order={order}
            />

            <TableBody>
              {isLoading ? (
                <TableSkeleton cellsAmount={3} />
              ) : (
                data?.map(el => (
                  <TagCategoryListTableRow
                    key={el?.id}
                    onClickActivationOptionHandler={onClickActionOptionHandler}
                    tagCategoryName={el?.name}
                    categoryTags={el?.tags}
                    tagCategoryServiceName={el?.serviceName}
                    id={el?.id}
                    isActive={el?.isActive}
                    isGlobalLibrary
                    isPracticeUser={!isRoleAdmin}
                  />
                ))
              )}
              <TableNoData isNotFound={!isLoading && !data?.length} />
            </TableBody>
          </Table>
        </Scrollbar>
      </TableContainer>

      <CustomTablePagination
        count={total}
        page={page - 1}
        rowsPerPage={rowsPerPage}
        onPageChange={onChangePage}
        onRowsPerPageChange={onChangeRowsPerPage}
      />

      <Dialog
        open={isAddTagCategoryDialogOpen}
        onClose={closeAddTagCategoryDialogMenu}
        PaperProps={{
          sx: { width: 1 },
        }}
      >
        <CreateTagCategoryMenuContent
          fetchTagCategories={onGetTagCategoriesHandler}
          closeMenuHandler={closeAddTagCategoryDialogMenu}
        />
      </Dialog>

      <BaseDialogWindow
        onClickCancelButtonHandler={closeDeleteDialogWindow}
        open={isOpenDeleteDialogWindow}
        description="Are you sure you want to delete tag category?"
        title="Deleting tag category"
        isApproveButtonDisabled={false}
        isApproveButtonLoading={false}
        approveButtonTitle="Delete tag category"
        onClickApproveButtonHandler={() => {}}
      />

      <BaseDialogWindow
        open={isDeactivateDialogOpen}
        onClickCancelButtonHandler={closeDeactivateDialog}
        description={
          <Stack spacing={1}>
            <Typography variant="body2" fontWeight={400}>
              Are you sure you want to inactivate{' '}
              <Typography variant="button" fontWeight={700}>
                {actionState.name}
              </Typography>{' '}
              tag category?
            </Typography>
          </Stack>
        }
        title="Inactivate tag category"
        isApproveButtonDisabled={isActionPending}
        isApproveButtonLoading={isActionPending}
        approveButtonTitle="Inactivate tag category"
        onClickApproveButtonHandler={onDeactivateTagCategoryHandler}
      />

      <BaseDialogWindow
        open={isActivateDialogOpen}
        onClickCancelButtonHandler={closeActivateDialog}
        description={
          <Stack spacing={1}>
            <Typography variant="body2" fontWeight={400}>
              Are you sure you want to activate{' '}
              <Typography variant="button" fontWeight={700}>
                {actionState.name}
              </Typography>{' '}
              tag category?
            </Typography>
          </Stack>
        }
        title="Activate tag category"
        isApproveButtonDisabled={isActionPending}
        isApproveButtonLoading={isActionPending}
        approveButtonTitle="Activate tag category"
        onClickApproveButtonHandler={onActivateTagCategoryHandler}
      />

      <BaseDialogWindow
        onClickCancelButtonHandler={closeCopyDialogWindow}
        open={isOpenCopyDialogWindow}
        description={
          <Typography textAlign="center" variant="body2">
            Are you sure you want to copy{' '}
            <Typography variant="body2" component="span" fontWeight={600}>
              {actionState.name}
            </Typography>{' '}
            to practice library?
          </Typography>
        }
        title="Copying tag category"
        isApproveButtonDisabled={isActionPending}
        isApproveButtonLoading={isActionPending}
        approveButtonTitle="Copy tag category"
        onClickApproveButtonHandler={onCopyTagCategoryHandler}
      />

      <PopoverWrapper
        open={isFiltersMenuOpen}
        title="Filter by tag categories"
        handleClosePopover={closeFiltersMenu}
        sx={{ mt: 1, px: 2, py: 1 }}
        variant="filter"
      >
        <FilterPopoverContent
          isHideShowDeactived={!isRoleAdmin}
          appliedFilters={appliedFilters}
          onApplyFilters={onApplyFilters}
        />
      </PopoverWrapper>
    </TableCard>
  );
};
