import { FC, memo } from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { IconButton, Menu, MenuItem, Stack, TableCell, TableRow } from '@mui/material';
import { AIModelParameterPatchRequest } from 'apiServices/ml/types';

import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';
import { ROUTES } from 'constants/routes';
import { usePopover } from 'hooks/usePopover';
import { useRouter } from 'hooks/useRouter';

type AIModelTableRowProps = {
  model_id?: string;
  modelName?: string;
  parameters?: AIModelParameterPatchRequest[];
  openDeleteDialogWindow?: () => void;
};

export const AIModelTableRow: FC<AIModelTableRowProps> = memo(
  ({ model_id, modelName, openDeleteDialogWindow, parameters }) => {
    const { navigate } = useRouter();
    const { openPopover, handleOpenPopover, handleClosePopover } = usePopover();

    const createRoute = (route: string, params: Record<string, string | number>): string => {
      return Object.keys(params).reduce((acc, key) => {
        return acc.replace(`:${key}`, String(params[key]));
      }, route);
    };

    const onClickVersionsHandler = (e: React.MouseEvent) => {
      e.stopPropagation();
      navigate(createRoute(ROUTES.aiModelVersions, { model_name: modelName, model_id: model_id }), {
        state: { breadCrumbName: modelName, model: { id: model_id, name: modelName, parameters: parameters } },
      });
    };

    const onClickUpdateHandler = () => {
      navigate(`/prompt-library/update-ai-model/${model_id}`, {
        state: {
          breadCrumbName: modelName,
          model: {
            name: modelName,
            parameters: parameters,
          },
        },
      });
      handleClosePopover();
    };

    const onClickDeleteHandler = () => {
      handleClosePopover();
      if (openDeleteDialogWindow) {
        openDeleteDialogWindow?.();
      }
    };

    return (
      <TableRow>
        <TableCell>
          <MDTypography variant="body2">{modelName}</MDTypography>
        </TableCell>
        <TableCell align="right">
          <Stack direction="row" justifyContent="flex-end">
            <MDButton variant="contained" color="primary" onClick={onClickVersionsHandler}>
              Versions
            </MDButton>

            <IconButton
              color="primary"
              onClick={handleOpenPopover}
              aria-controls="split-button-menu"
              aria-haspopup="true"
            >
              <ArrowDropDownIcon />
            </IconButton>

            <Menu
              id="split-button-menu"
              anchorEl={openPopover}
              open={Boolean(openPopover)}
              onClose={handleClosePopover}
            >
              <MenuItem onClick={onClickUpdateHandler}>Update</MenuItem>
              <MenuItem onClick={onClickDeleteHandler}>Delete</MenuItem>
            </Menu>
          </Stack>
        </TableCell>
      </TableRow>
    );
  }
);
