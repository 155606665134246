import { FC, useEffect, useState } from 'react';

import { Card, Grid, Stack, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { deletePromptVersion } from 'apiServices/ml/prompt-version.api';
import { PromptVersionItemResponse } from 'apiServices/ml/types';

import MDButton from 'components/MDButton';
import { CustomTableHeader, CustomTablePagination, TableNoData, TableSkeleton } from 'components/Table';
import { usePromptVersions } from 'hooks/usePromptVersions';
import { useRouter } from 'hooks/useRouter';

export const PromptVersionList: FC = () => {
  const { state, navigate } = useRouter();
  const [localVersions, setLocalVersions] = useState<PromptVersionItemResponse[]>([]);
  const { promptVersions, isLoading, error } = usePromptVersions(state?.prompt_id);

  useEffect(() => {
    if (!isLoading && promptVersions) {
      setLocalVersions(promptVersions);
    }
  }, [isLoading, promptVersions]);

  const handleAddClick = () => {
    navigate(`/prompt-add-version-form`, {
      state: {
        breadCrumbName: 'Add Prompt Version',
        mode: 'create',
        prompt_id: state?.prompt_id,
        description: '',
      },
    });
  };

  const handleUpdateClick = (version: PromptVersionItemResponse) => {
    navigate(`/prompt-version-form/${version.id}`, {
      state: {
        breadCrumbName: version.version,
        mode: 'update',
        prompt_id: state?.prompt_id,
        promptVersion: {
          id: version.id,
          prompt_id: version.promptId,
          version: version.version,
          description: version.text,
          created_at: version.createdAt,
        },
      },
    });
  };

  const handleDeleteClick = async (version: PromptVersionItemResponse) => {
    const confirmDelete = window.confirm(`Are you sure you want to delete version ${version.version}?`);
    if (!confirmDelete) return;

    try {
      await deletePromptVersion(version.id);
      setLocalVersions(localVersions.filter(v => v.id !== version.id));
    } catch (error) {
      console.error('Failed to delete prompt version:', error);
    }
  };

  return (
    <section>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} md={10} lg={12}>
          <Card
            sx={{
              borderRadius: 2,
              overflow: 'visible',
              maxWidth: '1200px',
              height: '700px',
              mx: 'auto',
            }}
          >
            <Stack direction="row" justifyContent="flex-start" alignItems="center" p={2}>
              <MDButton variant="contained" color="primary" onClick={handleAddClick}>
                Add Prompt Version
              </MDButton>
            </Stack>
            <TableContainer sx={{ position: 'relative', maxHeight: 'none' }}>
              <Table size="medium" sx={{ minWidth: 1000, width: '100%' }}>
                <CustomTableHeader
                  headLabel={[
                    { id: 'version', label: 'Version' },
                    { id: 'description', label: 'Text' },
                    { id: 'actions', label: 'Actions' },
                  ]}
                  sx={{
                    bgcolor: 'grey.200',
                    th: { p: 1 },
                    'th:last-of-type': { pr: 10 },
                  }}
                />
                <TableBody>
                  {isLoading ? (
                    <TableSkeleton cellsAmount={3} />
                  ) : error ? (
                    <TableRow>
                      <TableCell colSpan={3}>Error loading prompt versions</TableCell>
                    </TableRow>
                  ) : localVersions.length ? (
                    localVersions.map(version => (
                      <TableRow key={version.id}>
                        <TableCell>{version.version}</TableCell>
                        <TableCell>{version.text}</TableCell>
                        <TableCell>
                          <Stack direction="row" spacing={1}>
                            <MDButton
                              variant="contained"
                              color="info"
                              size="small"
                              sx={{ minWidth: 100, borderRadius: 1, boxShadow: 'none', textTransform: 'capitalize' }}
                              onClick={() => handleUpdateClick(version)}
                            >
                              Update
                            </MDButton>
                            <MDButton
                              variant="outlined"
                              color="error"
                              size="small"
                              sx={{ minWidth: 100, borderRadius: 1, textTransform: 'capitalize' }}
                              onClick={() => handleDeleteClick(version)}
                            >
                              Delete
                            </MDButton>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableNoData isNotFound />
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <CustomTablePagination
              count={localVersions.length}
              page={0}
              rowsPerPage={20}
              onPageChange={() => {}}
              onRowsPerPageChange={() => {}}
            />
          </Card>
        </Grid>
      </Grid>
    </section>
  );
};
