import { FC, memo, useEffect } from 'react';

import { useMemoizedAutocompleteFunctions } from 'hooks/useMemoizedAutocompleteFunctions';
import { usePatientEnums } from 'hooks/usePatientEnums';

import { usePatientProfileState } from '../../../PatientProfile/usePatientProfile.state';
import { AllergiesCard } from './AllergiesCard';
import { BodyMeasurementsCard } from './BodyMeasurementsCard';
import { DermatologicalAssessmentCard } from './DermatologicalAssessmentCard';
import { GeneralCard } from './GeneralCard';
import { LifestyleCard } from './LifestyleCard';
import { MedicalConditionsCard } from './MedicalConditionsCard';
import { MedicinesCard } from './MedicinesCard';
import { EditPatientFormProps } from './types';

export const EditPatientForm: FC<EditPatientFormProps> = memo(({ refs, fetchPatient, patientId }) => {
  const { patientCard } = usePatientProfileState();

  const { isAllPatientEnumsLoading, fetchAllPatientEnums, patientEnums } = usePatientEnums();

  useEffect(() => {
    fetchAllPatientEnums();
  }, []);

  const {
    memoizedAddressGetOptionLabel,
    memoizedAddressIsOptionEqualToValue,
    memoizedCityGetOptionLabel,
    memoizedCityIsOptionEqualToValue,
    memoizedGetOptionLabel,
    memoizedIsOptionEqualToValue,
  } = useMemoizedAutocompleteFunctions();

  return (
    <>
      <GeneralCard
        cardRef={refs?.[0]}
        ethnicGroups={patientEnums?.ethnicGroups}
        genders={patientEnums?.bioGenders}
        bloodTypes={patientEnums?.bloodTypes}
        painTolerances={patientEnums?.painTolerances}
        personTitles={patientEnums?.personTitles}
        memoizedGetOptionLabel={memoizedGetOptionLabel}
        memoizedIsOptionEqualToValue={memoizedIsOptionEqualToValue}
        isAllPatientEnumsLoading={isAllPatientEnumsLoading}
      />

      <DermatologicalAssessmentCard
        cardRef={refs?.[1]}
        memoizedAddressGetOptionLabel={memoizedAddressGetOptionLabel}
        memoizedCityGetOptionLabel={memoizedCityGetOptionLabel}
        memoizedAddressIsOptionEqualToValue={memoizedAddressIsOptionEqualToValue}
        memoizedCityIsOptionEqualToValue={memoizedCityIsOptionEqualToValue}
      />

      {/*<BodyMeasurementsCard cardRef={refs?.[2]} />*/}

      <LifestyleCard
        cardRef={refs?.[3]}
        alcoholConsumption={patientEnums?.alcoholConsumption}
        smoking={patientEnums?.smoking}
        memoizedGetOptionLabel={memoizedGetOptionLabel}
        memoizedIsOptionEqualToValue={memoizedIsOptionEqualToValue}
        isAllPatientEnumsLoading={isAllPatientEnumsLoading}
      />
      <AllergiesCard
        fetchPatient={fetchPatient}
        patientId={patientId}
        allergies={patientCard?.allergies}
        cardRef={refs?.[4]}
      />
      <MedicalConditionsCard
        fetchPatient={fetchPatient}
        patientId={patientId}
        medicalConditions={patientCard?.medicalConditions}
        cardRef={refs?.[5]}
      />
      <MedicinesCard cardRef={refs?.[6]} />
    </>
  );
});
