import { useCallback } from 'react';

import { AIModelParameterPatchRequest } from 'apiServices/ml/types';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

type URLParams = {
  id?: string;
  model_name?: string;
  version_id?: string;
  albumId?: string;
};

export type RouterState = {
  email?: string;
  breadCrumbName?: string;
  prompt_id?: string;
  emailHidden?: string;
  text?: string;
  comment?: string;

  model?: Partial<{
    id: string;
    name: string;
    parameters: AIModelParameterPatchRequest[];
  }>;

  version?: Partial<{
    id: string;
    version: string;
    full_name: string;
    api_endpoint: string;
    configuration: { [x: string]: unknown };
    release_date: string;
    notes: string;
  }>;

  promptVersion?: Partial<{
    id: string;
    prompt_id: string;
    version: number;
    text: string;
    created_at: string;
  }>;
};

export const useRouter = () => {
  const navigate = useNavigate();
  const { pathname, state } = useLocation();
  const params = useParams<URLParams>();

  const typedState = state as RouterState;

  const back = useCallback(() => navigate(-1), []);

  const push = useCallback((to: string) => new Promise(resolve => resolve(navigate(to))), []);

  return {
    push,
    pathname,
    back,
    params,
    state: typedState,
    navigate,
  };
};
