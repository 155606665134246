import { FC, memo, useState } from 'react';

import { Box, ButtonGroup, Stack, Typography } from '@mui/material';
import { ContactPerson } from 'apiServices';

import { Icon } from 'components/Icon';
import MDButton from 'components/MDButton';

type ContactPersonCardProps = Pick<ContactPerson, 'firstName' | 'lastName' | 'middleName' | 'title' | 'id'> &
  Pick<ContactPerson['contact'], 'phone' | 'email' | 'website'> & {
    onClickRemoveButtonHandler: (event: React.MouseEvent<HTMLButtonElement>) => void;
    onClickEditButtonHandler: (event: React.MouseEvent<HTMLButtonElement>) => void;
  };

export const ContactPersonCard: FC<ContactPersonCardProps> = memo(
  ({
    email,
    firstName,
    id,
    lastName,
    phone,
    website,
    middleName,
    title,
    onClickRemoveButtonHandler,
    onClickEditButtonHandler,
  }) => {
    const headerTitle = `${title || ''} ${firstName} ${middleName || ''} ${lastName || ''}`;

    const [isHover, setIsHover] = useState(false);

    const handleMouseEnter = () => setIsHover(true);
    const handleMouseLeave = () => setIsHover(false);

    return (
      <Box
        sx={{
          position: 'relative',
          bgcolor: theme => theme.palette.grey[100],
          p: 2,
          border: ({ borders }) => `${borders.borderWidth[1]} solid ${borders.borderColor}`,
          borderRadius: theme => theme.borders.borderRadius.lg,
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Stack spacing={2}>
          <Typography fontSize={14} fontWeight={600}>
            {headerTitle}
          </Typography>
          {(email || phone || website) && (
            <Stack spacing={1}>
              {email && (
                <Stack direction="row" gap={1} alignItems="center">
                  <Icon type="email" />
                  <Typography fontWeight={400} fontSize={14}>
                    {email}
                  </Typography>
                </Stack>
              )}

              {phone && (
                <Stack direction="row" gap={1} alignItems="center">
                  <Icon type="phoneIcon" sx={{ svg: { width: 18, height: 18 } }} />
                  <Typography fontWeight={400} fontSize={14}>
                    {phone}
                  </Typography>
                </Stack>
              )}

              {website && (
                <Stack direction="row" gap={1} alignItems="center">
                  <Icon type="website" />
                  <Typography fontWeight={400} fontSize={14}>
                    {website}
                  </Typography>
                </Stack>
              )}
            </Stack>
          )}
        </Stack>

        <ButtonGroup
          size="small"
          sx={theme => ({
            transition: theme.transitions.create('all', {
              duration: theme.transitions.duration.short,
              easing: theme.transitions.easing.easeOut,
            }),
            '& button': {
              maxWidth: 30,
              minWidth: 'unset !important',
              maxHeight: 32,
              bgcolor: theme.palette.common.white,
            },
            opacity: isHover ? 1 : 0,
            position: 'absolute',
            top: 8,
            right: 8,
          })}
        >
          <MDButton size="small" variant="outlined" color="dark" onClick={onClickEditButtonHandler}>
            <Icon type="editPen" />
          </MDButton>
          <MDButton id={id} size="small" variant="outlined" color="dark" onClick={onClickRemoveButtonHandler}>
            <Icon type="trashBin" sx={{ svg: { width: 14, height: 14 } }} />
          </MDButton>
        </ButtonGroup>
      </Box>
    );
  }
);
