import { BASE_PRACTICE_API_URL } from 'apiServices/api.routes';
import { apiClient } from 'lib/api';

import {
  ContactPerson,
  CreatePracticeContactPersonPayload,
  GetPracticeContactPersonsParams,
  UpdatePracticeContactPersonPayload,
} from './types';

export const getPracticeContactPersons = async (practiceId: string, params: GetPracticeContactPersonsParams) =>
  await apiClient.get<ListOfItems<ContactPerson>>(`${BASE_PRACTICE_API_URL}/${practiceId}/contact_persons`, { params });

export const createPracticeContactPerson = async (practiceId: string, person: CreatePracticeContactPersonPayload) =>
  await apiClient.put<ContactPerson>(`${BASE_PRACTICE_API_URL}/${practiceId}/contact_persons`, person);

export const getPracticeContactPerson = async (practiceId: string, personId: string) =>
  await apiClient.get<ContactPerson>(`${BASE_PRACTICE_API_URL}/${practiceId}/contact_persons/${personId}`);

export const deletePracticeContactPerson = async (practiceId: string, personId: string): Promise<void> => {
  await apiClient.delete(`${BASE_PRACTICE_API_URL}/${practiceId}/contact_persons/${personId}`);
};

export const patchPracticeContactPerson = async (
  practiceId: string,
  personId: string,
  person: UpdatePracticeContactPersonPayload
) => await apiClient.patch<ContactPerson>(`${BASE_PRACTICE_API_URL}/${practiceId}/contact_persons/${personId}`, person);
