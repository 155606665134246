import { CSSObject, ChipProps, alpha } from '@mui/material';
import { grey } from '@mui/material/colors';

import colors from 'assets/theme/base/colors';

import borders from '../base/borders';

const COLORS: Extract<TypeColor, 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error'>[] = [
  'primary',
  'secondary',
  'info',
  'success',
  'warning',
  'error',
];

declare module '@mui/material/Chip' {
  interface ChipPropsVariantOverrides {
    soft: true;
    linkedContent: true;
  }
}

const rootStyle = (ownerState: ChipProps) => {
  const softVariant = ownerState.variant === 'soft';

  // TODO
  // const linkedContentVariant = ownerState.variant === 'linkedContent';

  const defaultStyles: CSSObject = {
    borderWidth: 2,
    fontSize: 14,
    fontWeight: 500,
    ...(softVariant && {
      '.MuiChip-icon': {
        marginLeft: 8,
      },
    }),
  };

  const softVariantStyles = {
    ...(ownerState.color === 'default' && {
      backgroundColor: alpha(colors.grey[500], 0.1),
      color: grey[800],
      border: `${borders.borderWidth[1]} solid ${alpha(grey[800], 0.25)}`,
      ...(ownerState.clickable && {
        ':hover': {
          backgroundColor: alpha(colors.grey[500], 0.2),
        },
      }),
    }),
  };

  const colorStyle = COLORS.map(color => {
    const colorValue = colors[color]?.main || colors.primary.main;
    return {
      ...(ownerState.color === color && {
        ...(softVariant && {
          backgroundColor: alpha(colorValue, 0.1),
          color: colorValue,
          border: `${borders.borderWidth[1]} solid ${alpha(colorValue, 0.25)}`,
          ...(ownerState.clickable && {
            ':hover': {
              backgroundColor: alpha(colorValue, 0.2),
            },
          }),
        }),
      }),
    };
  });

  return [...colorStyle, softVariantStyles, defaultStyles];
};

const chip = {
  styleOverrides: {
    root: ({ ownerState }: { ownerState: ChipProps }) => rootStyle(ownerState),
  },
};

export default chip;
