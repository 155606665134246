import { FC } from 'react';

import { CheckRounded } from '@mui/icons-material';
import { DialogActions, Stack } from '@mui/material';

import { Icon } from 'components/Icon';
import MDButton from 'components/MDButton';
import { UploadFileButton } from 'components/UploadFileButton';
import { ACCEPT_INPUT_IMAGE_FILES } from 'constants/fileExtensions';

type ActionsProps = {
  onClickUploadNewFileButton: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSaveFileHandler: () => void;
  isDeleteMode: boolean;
  openDeleteDialog: () => void;
  isLoading: boolean;
  isImageLoading: boolean;
  isFileError: boolean;
};

export const Actions: FC<ActionsProps> = ({
  onClickUploadNewFileButton,
  onSaveFileHandler,
  isDeleteMode,
  openDeleteDialog,
  isLoading,
  isImageLoading,
  isFileError,
}) => {
  return (
    <DialogActions sx={{ px: 0, py: { xs: 1.5, sm: 2 } }}>
      <Stack
        width={1}
        direction={{ xs: 'column', sm: 'row' }}
        alignItems="center"
        justifyContent="space-between"
        gap={1}
      >
        {isDeleteMode ? (
          <MDButton
            onClick={openDeleteDialog}
            variant="outlined"
            color="dark"
            disabled={isLoading || isImageLoading}
            startIcon={<Icon sx={{ svg: { height: 14, width: 14 } }} type="trashBin" />}
            sx={{ width: { xs: 1, sm: 'auto' } }}
          >
            Delete
          </MDButton>
        ) : (
          <UploadFileButton
            onChangeHandler={onClickUploadNewFileButton}
            startIcon={<Icon type="refreshIcon" sx={{ svg: { height: 14, width: 14 } }} />}
            color="dark"
            variant="outlined"
            disabled={isLoading || isImageLoading}
            inputProps={{ accept: ACCEPT_INPUT_IMAGE_FILES }}
            sx={{ width: { xs: 1, sm: 'auto' } }}
          >
            Change photo
          </UploadFileButton>
        )}

        {isDeleteMode ? (
          <UploadFileButton
            onChangeHandler={onClickUploadNewFileButton}
            startIcon={<Icon type="uploadIcon" />}
            color="primary"
            disabled={isLoading || isImageLoading}
            inputProps={{ accept: ACCEPT_INPUT_IMAGE_FILES }}
            sx={{ width: { xs: 1, sm: 'auto' } }}
          >
            Upload new
          </UploadFileButton>
        ) : (
          <MDButton
            isLoading={isLoading}
            disabled={isFileError || isLoading || isImageLoading}
            startIcon={<CheckRounded fontSize="medium" sx={{ width: 15, height: 15 }} />}
            onClick={onSaveFileHandler}
            sx={{ width: { xs: 1, sm: 'auto' } }}
          >
            Save
          </MDButton>
        )}
      </Stack>
    </DialogActions>
  );
};
