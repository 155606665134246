import { FC, useEffect, useState } from 'react';

import {
  BoltRounded,
  CalendarTodayOutlined,
  DeleteOutlineOutlined,
  DomainAddOutlined,
  PersonOutlineRounded,
} from '@mui/icons-material';
import { Card, CardContent, Chip, IconButton, Rating, Stack } from '@mui/material';
import { deleteFeedback } from 'apiServices/ml/prompt_feedback.api';
import { format } from 'date-fns';

import { Loader } from 'components/Loader';
import { MDAvatar } from 'components/MDAvatar';
import MDTypography from 'components/MDTypography';
import { Scrollbar } from 'components/ScrollBar';
import { usePromptFeedbacks } from 'hooks/usePromptFeedbackByPromptID';

export const EvaluationHistoryTabContent: FC<{
  promptId: string;
  generalComments?: string;
}> = ({ promptId, generalComments }) => {
  const { feedbacks: initialFeedbacks, loading, error } = usePromptFeedbacks(promptId);
  const [feedbacks, setFeedbacks] = useState(initialFeedbacks || []);

  useEffect(() => {
    if (initialFeedbacks && initialFeedbacks.length > 0) {
      setFeedbacks(initialFeedbacks);
    }
  }, [initialFeedbacks]);

  const handleDelete = async (feedbackId: string) => {
    try {
      await deleteFeedback(feedbackId);
      setFeedbacks(prevFeedbacks => prevFeedbacks.filter(fb => fb.id !== feedbackId));
    } catch (error) {
      console.error('Failed to delete feedback', error);
    }
  };

  const formatUsedParameters = (parameters: Record<string, any>) => {
    return Object.entries(parameters)
      .map(([key, value]) => `${key}: ${value}`)
      .join(' | ');
  };

  if (loading) return <Loader />;
  if (error) return <div>{error}</div>;

  return (
    <Stack flex={1}>
      <Scrollbar sx={{ height: '100%', overflowY: 'auto', paddingBottom: '16px' }}>
        {feedbacks.map(feedback => {
          const modelVersion = '7B';
          const model = 'Mistral';

          return (
            <Stack
              key={feedback.id}
              borderTop={theme => `1px solid ${theme.palette.grey[300]}`}
              p={2}
              spacing={2}
              sx={{
                ':hover': {
                  bgcolor: theme => theme.palette.grey[200],
                  cursor: 'pointer',
                },
                position: 'relative',
              }}
            >
              <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
                <Stack direction="row" spacing={1} alignItems="center">
                  <MDAvatar avatarSize="sm" />
                  <MDTypography variant="body2">Dr. {feedback?.cognito_username || 'Michael Lee'}</MDTypography>

                  <Chip
                    variant="soft"
                    color="primary"
                    label={
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <DomainAddOutlined sx={{ width: 20, height: 20 }} />
                        <MDTypography variant="caption" fontWeight={500}>
                          {feedback?.patient_name || 'Patient Name'}
                        </MDTypography>
                      </Stack>
                    }
                  />
                  <Chip
                    variant="soft"
                    color="primary"
                    label={
                      <Stack direction="row" alignItems="center" spacing={0.5}>
                        <PersonOutlineRounded sx={{ width: 20, height: 20 }} />
                        <MDTypography variant="caption" fontWeight={500}>
                          {feedback?.practice_name || 'Practice Name'}
                        </MDTypography>
                      </Stack>
                    }
                  />
                </Stack>

                <Stack direction="row" spacing={1} alignItems="center">
                  <CalendarTodayOutlined color="disabled" />
                  <MDTypography variant="body2">
                    {format(new Date(feedback?.created_at), 'd MMMM yyyy, h:mm a')}
                  </MDTypography>
                </Stack>
              </Stack>

              <Stack direction={{ xs: 'column', lg: 'row' }} justifyContent="space-between" spacing={2.5} width={1}>
                {['Output', 'General Comments', 'Expected Response'].map((label, index) => (
                  <Card
                    key={label}
                    sx={{
                      maxWidth: { xs: 1, lg: 580 },
                      width: 1,
                      height: '170px',
                      overflowY: 'auto',
                      position: 'relative',
                    }}
                  >
                    <CardContent>
                      <Stack direction="row" spacing={1} alignItems="center">
                        <BoltRounded />
                        <MDTypography variant="body2">{label}</MDTypography>
                      </Stack>
                      <MDTypography variant="body2">
                        {index === 0
                          ? feedback?.model_response || 'No output available'
                          : index === 1
                          ? feedback?.comment || 'No comments available'
                          : feedback?.preferred_output || 'No expected response available'}
                      </MDTypography>
                    </CardContent>
                  </Card>
                ))}

                <Stack spacing={2} alignItems="center">
                  <Stack alignItems="center">
                    <MDTypography variant="caption">Accuracy</MDTypography>
                    <Rating name="accuracy" value={feedback?.accuracy_stars || 0} precision={0.5} readOnly />
                  </Stack>
                  <Stack alignItems="center">
                    <MDTypography variant="caption">Relevance</MDTypography>
                    <Rating name="relevancy" value={feedback?.relevancy_stars || 0} precision={0.5} readOnly />
                  </Stack>
                  <Stack alignItems="center">
                    <MDTypography variant="caption">Coherence</MDTypography>
                    <Rating name="coherence" value={feedback?.coherence_stars || 0} precision={0.5} readOnly />
                  </Stack>
                </Stack>
              </Stack>
              <MDTypography color="text" variant="button" fontWeight={400}>
                AI Model: {model || feedback?.ai_model_name} #version: {modelVersion || '7B'} (
                {modelVersion || 'No full name'})
              </MDTypography>
              {feedback?.used_parameters && (
                <MDTypography color="text" variant="button" fontWeight={400}>
                  Parameters: {formatUsedParameters(feedback.used_parameters)}
                </MDTypography>
              )}

              <IconButton
                onClick={() => handleDelete(feedback.id)}
                sx={{
                  position: 'absolute',
                  bottom: 8,
                  right: 8,
                }}
              >
                <DeleteOutlineOutlined fontSize="medium" />
              </IconButton>
            </Stack>
          );
        })}
      </Scrollbar>
    </Stack>
  );
};
